import React from 'react';
import { checkNested } from 'MODULES/checkNested';
import getMotDateValue from 'MODULES/getMotDateValue';
import { FILTER_DISPLAY_TYPE } from '../../../modules/const';

const CategoryFilters = ({ filters }) => filters.map(
    f => (
        checkNested(f, 'filter.filter.cssClass') && (
            <div
                key={f.id}
                title={checkNested(f.filter, 'filter.name', '')}
            >
                <i className={`pp-landing ${f.filter.filter.cssClass}`} />

                {f.filter.filter.displayType === FILTER_DISPLAY_TYPE.mot ? getMotDateValue(f.val) : f.val}
            </div>
        )
    )
);

export default CategoryFilters;
