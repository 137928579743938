import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { SEND_LANG_TO_API } from 'ACTIONS';

export const sendLangToApi = (language, onSuccess) => apiAction(
    {
        url: API_URLS.USER,
        data: { language },
        method: 'PATCH',
        auth: true,
        onSuccess: () => (dispatch) => {
            dispatch({ type: SEND_LANG_TO_API });
            if (onSuccess) {
                onSuccess();
            }
        },
        label: SEND_LANG_TO_API
    }
);
