import { UI_MESSAGE_TYPES } from 'CONST';
import { showMsg } from 'ACTIONS/ui/showMsg';
import { ERROR_MESSAGE } from 'ACTIONS';

export const showErrorMsg = (id, content, interval = null) => (dispatch, getState) => {
    let message = content;
    // If content is false, remove message
    if (!content && content !== false) {
        message = getState().ui.messages[UI_MESSAGE_TYPES.generalerror];
    }
    showMsg(ERROR_MESSAGE, dispatch, id, message, interval);
};
