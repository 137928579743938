export function getParent(child, returnArray = false, ignoreLimit = false, forCreate = false) {
    // if returnArray is true - return an array containing all parents
    // if returnArray is false - return only deepest parent
    let parentsArray = [child];
    let prev = child;
    let next = child;
    while (next) {
        prev = next;
        next = next.parent;
        if (returnArray && next && forCreate) {
            parentsArray = [next, ...parentsArray];
        } else if (returnArray && next && (!ignoreLimit && (next.level < 3 || typeof next.level === 'undefined'))) {
            parentsArray = [next, ...parentsArray];
        }
    }
    return returnArray ? parentsArray : prev;
}
