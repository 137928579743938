import {
    DISCOUNT_FILTER_VALUE,
    FILTER_DISPLAY_TYPE,
    FILTER_VISIBILITY,
    LOT_END_DATE,
    PUBLISH_AS_TYPE,
    WINTER_BEATER_FILTER,
    WINTER_BEATER_FILTER_ID
} from 'CONST';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import _values from 'lodash/values';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import { stringExistsIn } from 'MODULES/validations';
import { checkNested } from 'MODULES/checkNested';

export const initialSavedFilters = (allFilters, savedValues) => {
    let filterValues = {};
    const keyByFilters = keyBy(allFilters, 'filter.id');
    filterValues = reduce(savedValues, (result, value) => {
        if (value.visibilityType === FILTER_VISIBILITY.hidden) {
            return result;
        }

        if (value.filter.displayType !== FILTER_DISPLAY_TYPE.number_gradual
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.car_body
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.measure
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.number_int
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.float
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.text
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.color
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.text
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.radioCheckBox
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.number
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.text_pre
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.mot
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.date
            && value.filter.displayType !== FILTER_DISPLAY_TYPE.select) {
            (result[value.filter.id] || (result[value.filter.id] = [])).push(value.textValue || value.value.id);
        } else if (value.filter.displayType === FILTER_DISPLAY_TYPE.mot
            || value.filter.displayType === FILTER_DISPLAY_TYPE.number_int
            || value.filter.displayType === FILTER_DISPLAY_TYPE.number_gradual
            || value.filter.displayType === FILTER_DISPLAY_TYPE.text
            || value.filter.displayType === FILTER_DISPLAY_TYPE.date
        ) {
            result[value.filter.id] = value.textValue;
        } else if (value.filter.displayType === FILTER_DISPLAY_TYPE.measure) {
            const filterData = keyByFilters[value.filter.id];
            result[value.filter.id] = { value: value.textValue, type: checkNested(filterData, 'filter.filterDefinedValues[0].id') };
        } else if (value.filter.displayType === FILTER_DISPLAY_TYPE.radioCheckBox) {
            if (value.filter.id === WINTER_BEATER_FILTER_ID) {
                result[value.filter.id] = [WINTER_BEATER_FILTER];
            } else {
                result[value.filter.id] = value.value.sequence === 0 ? [value.value.id] : [];
            }
        } else if (checkNested(value, 'value.id')) {
            result[value.filter.id] = value.value.id;
        }
        return result;
    }, {});
    return filterValues;
};

export const pickFilterValues = (values = {}, filtersObject, filtersById) => {
    let queryParams = {};

    const {
        fV,
        discountValue,
        multipleCategoryString,
        action,
        category,
        area,
        team,
        endDate,
        visibility,
        visibilities,
        priceType,
        categories,
        price,
        region,
        filters,
        query,
        sortBy,
        ownerType,
        currentPage,
        page,
        minPrice,
        maxPrice,
        orderColumn,
        orderDirection,
        xmlLinks,
        isDiscount,
        minDiscount,
        maxDiscount
    } = values;
    const street = checkNested(region, 'street', values.street);
    const selectedCategories = categories || values.selectedCategories;
    // const filters = values.filters || values.fV;

    if (checkNested(price, 'from')) {
        queryParams = {
            ...queryParams,
            minPrice: values.price.from
        };
    }
    if (checkNested(price, 'to')) {
        queryParams = {
            ...queryParams,
            maxPrice: values.price.to
        };
    }

    if (checkNested(discountValue, 'from')) {
        queryParams = {
            ...queryParams,
            minDiscount: values.discountValue.from
        };
    }
    if (checkNested(discountValue, 'to')) {
        queryParams = {
            ...queryParams,
            maxDiscount: values.discountValue.to
        };
    }

    if (minPrice) {
        queryParams = { ...queryParams, minPrice };
    }

    if (maxPrice) {
        queryParams = { ...queryParams, maxPrice };
    }

    if (maxDiscount) {
        queryParams = { ...queryParams, maxDiscount };
    }

    if (minDiscount) {
        queryParams = { ...queryParams, minDiscount };
    }


    if (checkNested(price, 'to')) {
        queryParams = {
            ...queryParams,
            maxPrice: values.price.to
        };
    }

    if (action) {
        queryParams = {
            ...queryParams,
            action
        };
    }


    if (orderColumn && orderDirection) {
        queryParams = { ...queryParams, orderDirection, orderColumn };
    }

    if (currentPage) {
        queryParams = {
            ...queryParams,
            currentPage
        };
    }

    if (page) {
        queryParams = {
            ...queryParams,
            currentPage: page
        };
    }

    if (street) {
        queryParams = {
            ...queryParams,
            street
        };
    }

    if (ownerType) {
        const ownerToCheck = {
            company: ownerType[PUBLISH_AS_TYPE.company] === 'true' || ownerType[PUBLISH_AS_TYPE.company] === true,
            personal: ownerType[PUBLISH_AS_TYPE.me] === 'true' || ownerType[PUBLISH_AS_TYPE.me] === true };
        let ownerSearch;
        if (ownerToCheck && !ownerToCheck[PUBLISH_AS_TYPE.me] && ownerToCheck[PUBLISH_AS_TYPE.company]) {
            ownerSearch = PUBLISH_AS_TYPE.company;
        }
        if (ownerToCheck && ownerToCheck[PUBLISH_AS_TYPE.me] && !ownerToCheck[PUBLISH_AS_TYPE.company]) {
            ownerSearch = PUBLISH_AS_TYPE.me;
        }
        if (ownerType && (ownerType === PUBLISH_AS_TYPE.me || ownerType === PUBLISH_AS_TYPE.company)) {
            ownerSearch = ownerType;
        }
        queryParams = {
            ...queryParams,
            ownerType: ownerSearch
        };
    }
    if (isDiscount) {
        queryParams = {
            ...queryParams,
            isDiscount: checkNested(isDiscount, 'value', isDiscount)
        };
    }

    if (query) {
        queryParams = {
            ...queryParams,
            query
        };
    }


    // add priceType
    if (priceType) {
        queryParams = {
            ...queryParams,
            priceType
        };
    }

    if (multipleCategoryString) {
        queryParams = {
            ...queryParams,
            multipleCategoryString
        };
    }

    // subregion/region
    if (region && checkNested(region, 'region', '') !== 'false' && checkNested(region, 'region', '') !== false) {
        const getRegion = region.subregion || region.region;
        if (getRegion !== '') {
            queryParams = {
                ...queryParams,
                region: getRegion || region
            };
        }
        queryParams = { ...queryParams };
    }

    // sorter
    if (sortBy !== '') {
        queryParams = { ...queryParams, ...sortBy };
    }

    // category
    if (category && category !== '') {
        queryParams = { ...queryParams, category };
    }

    // filter
    if (filters || fV) {
        let parsedFilters = filtersObject ? {} : filters;

        if (fV) {
            parsedFilters = fV;
        }

        if (filtersObject || filtersById) {
            const filterIds = keys(values.filters);
            if (filterIds.length) {
                filterIds.forEach((id) => {
                    let currentFilter = checkNested(filtersById, id);
                    if (!filtersById) {
                        currentFilter = find(filtersObject.categoryFilters, f => f.filter.id === parseFloat(id));
                    }
                    if (currentFilter && currentFilter.filter.displayType === FILTER_DISPLAY_TYPE.number) {
                        if (currentFilter) {
                            // const initialValues = [Math.min(...currentFilterValues), Math.max(...currentFilterValues)];
                            // if (parseFloat(values.filters[id].min) !== initialValues[0] && isNumber(parseFloat(values.filters[id].min))) {
                            //     parsedFilters = { ...parsedFilters, [id]: { ...checkNested(parsedFilters, id, {}), min: values.filters[id].min } };
                            // }
                            // if (parseFloat(values.filters[id].max) !== initialValues[1] && isNumber(parseFloat(values.filters[id].max))) {
                            //     parsedFilters = { ...parsedFilters, [id]: { ...checkNested(parsedFilters, id, {}), max: values.filters[id].max } };
                            // }
                            // commented out CLASF-2321 2. point
                            parsedFilters = { ...parsedFilters, [id]: { ...checkNested(parsedFilters, id, {}), min: values.filters[id].min } };
                            parsedFilters = { ...parsedFilters, [id]: { ...checkNested(parsedFilters, id, {}), max: values.filters[id].max } };
                        }
                    } else if (`${values.filters[id]}` !== '0') {
                        parsedFilters = { ...parsedFilters, [id]: values.filters[id] };
                    }
                });
            }
        }

        parsedFilters = omitBy(parsedFilters, v => (v === '0'));
        queryParams = { ...queryParams, fV: parsedFilters };
    }

    // selectedCategories
    if (selectedCategories) {
        queryParams = { ...queryParams, categories: [] };
        _values(selectedCategories).forEach((category) => {
            queryParams = { ...queryParams, categories: [...queryParams.categories, category] };
        });
    }

    // visibility
    if (visibility) {
        const ids = Object.keys(visibility);
        queryParams = { ...queryParams, visibilities: [] };
        for (const i in ids) {
            if (visibility[ids[i]]) {
                queryParams = { ...queryParams, visibilities: [...queryParams.visibilities, ids[i]] };
            }
        }
    }

    if (visibilities) {
        queryParams = { ...queryParams, visibilities };
    }

    if (xmlLinks) {
        queryParams = { ...queryParams, xmlLinks };
    }

    if (endDate) {
        if (endDate !== LOT_END_DATE.all) {
            queryParams = { ...queryParams, period: endDate };
        }
    }

    if (area) {
        queryParams = { ...queryParams, area };
    }


    if (team) {
        const feedList = [];
        const owners = [];
        _values(team)
            .forEach((f) => {
                _values(f)
                    .forEach((teamItem) => {
                        if (stringExistsIn('_feed', teamItem)) {
                            feedList.push(teamItem.replace('_feed', ''));
                        } else if (stringExistsIn('_hand', teamItem)) {
                            owners.push(teamItem.replace('_hand', ''));
                        }
                    });
            });
        if (feedList.length > 0) {
            queryParams = { ...queryParams, xmlLinks: feedList };
        }
        if (owners.length > 0) {
            queryParams = { ...queryParams, owners };
        }
    } else if (values.feedList) {
        queryParams = { ...queryParams, xmlLinks: values.feedList };
    }
    if (values.owners) {
        queryParams = { ...queryParams, owners: values.owners };
    }


    return queryParams;
};

export const mapQueryToFilters = (values) => {
    let filters = {};
    let action = {};
    let priceType = {};
    let region = {};
    let multipleCategoryString = {};
    let ownerType = { [PUBLISH_AS_TYPE.me]: true, [PUBLISH_AS_TYPE.company]: true };
    let isDiscount = DISCOUNT_FILTER_VALUE.no;
    if (values.action) {
        action = { action: parseFloat(values.action) };
    }
    if (values.priceType) {
        priceType = { priceType: parseFloat(values.priceType) };
    }
    if (values.region && values.region !== 'false') {
        region = { region: { region: values.region, street: values.street } };
    }

    if (values.multipleCategoryString) {
        multipleCategoryString = { multipleCategoryString: values.multipleCategoryString };
    }

    if (values.ownerType) {
        ownerType = {};
        if (values.ownerType === PUBLISH_AS_TYPE.me) {
            ownerType = { [PUBLISH_AS_TYPE.me]: true };
        }
        if (values.ownerType === PUBLISH_AS_TYPE.company) {
            ownerType = { [PUBLISH_AS_TYPE.company]: true };
        }
    }

    if (values.isDiscount) {
        isDiscount = {};
        if (values.isDiscount === String(DISCOUNT_FILTER_VALUE.yes)) {
            isDiscount = DISCOUNT_FILTER_VALUE.yes;
        }
        if (values.isDiscount === String(DISCOUNT_FILTER_VALUE.no)) {
            isDiscount = DISCOUNT_FILTER_VALUE.no;
        }
    }


    if (values.fV) {
        forEach(values.fV, (f, k) => {
            filters = { ...filters, [k]: isArray(f) ? f.map(v => parseFloat(v)) : f };
        });
        filters = { filters };
    }
    const price = (values.maxPrice || values.minPrice) ? { price: { from: values.minPrice, to: values.maxPrice } } : {};
    const parsedValues = { ...values, ...filters, ...price, ...action, ...priceType, ...region, ...multipleCategoryString, ownerType, isDiscount };
    const { fV, maxPrice, minPrice, ...returnValue } = parsedValues;
    return omit(returnValue, ['page']);
};
