import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import values from 'lodash/values';
import { HOST } from 'CONFIG/index';
import { isMessagingOn } from 'MODULES/reselect/selectors/general';
import { checkNested } from 'MODULES/checkNested';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { getAllMessages } from 'ACTIONS/messaging/getAllMessages';
import { getLotList } from 'ACTIONS/messaging/getLotList';
import { getThreadLot } from 'ACTIONS/messaging/getThreadLot';
import { sendMessage } from 'ACTIONS/messaging/sendMessage';
import { markMessageAsRead } from 'ACTIONS/messaging/markMessageAsRead';
import { getNewMessagesForThread } from 'ACTIONS/messaging/getNewMessagesForThread';
import { getAllThreadsForItem } from 'ACTIONS/messaging/getAllThreadsForItem';
import { getParticipantInfo } from 'ACTIONS/messaging/getParticipantInfo';
import { activateThread } from 'ACTIONS/messaging/activateThread';
import { flushAllMessages } from 'ACTIONS/messaging/flushAllMessages';
import { flushAllThreads } from 'ACTIONS/messaging/flushAllThreads';
import { flushAllLots } from 'ACTIONS/messaging/flushAllLots';
import { getUserCompany } from 'ACTIONS/user/getUserCompany';
import { getNotifications } from 'ACTIONS/messaging/getNotifications';

const getPersonalMessages = state => state.messaging.lots.personal;
const getCompanyMessages = state => state.messaging.lots.company;
const getNotificationsList = state => state.messaging.lots.notifications;
const getUserData = state => state.user.userData;
const getPrivateMessageCount = state => state.messaging.privateMessages;
const getCompanyMessageCount = state => state.messaging.companyMessages;
const getNotificationsCount = state => state.messaging.notifications;
const getTotalMessageCount = state => state.messaging.totalMessages;
const userData = user => user;
const i18nState = (state, props) => props.i18n;
const matchParams = (state, props) => props.match.params;
const langState = state => state.general.selectedLang;
const participantData = state => state.messaging.participants;
const messagingLotsAmount = state => state.messaging.lots.amount;

const messagingData = (personal, company, notifications, amount) => ({
    personal,
    company,
    notifications,
    amount
});

const messageCount = (personal, company, notifications, totalMessages) => ({
    personal,
    company,
    notifications,
    totalMessages
});

const getHelmetData = (lang, i18n, match, participant, user) => {
    let title = i18n.__('Messaging');
    const hash = checkNested(match, 'hash', false);
    const description = i18n.__('Messaging');
    const url = hash ? `${HOST}${PP_URLS.MESSAGES_BY_HASH(hash)}` : `${HOST}${PP_URLS.MESSAGES}`;
    if (hash) {
        const userId = user.id;
        values(participant).map(p => (p.id !== userId ? title = `${title} ${i18n.__('with')} ${p.name}` : ''));
    }
    return {
        title,
        description,
        url
    };
};

export const messagingReselect = createSelector(getPersonalMessages, getCompanyMessages, getNotificationsList, messagingLotsAmount, messagingData);
export const helmetReselect = createSelector(langState, i18nState, matchParams, participantData, getUserData, getHelmetData);
export const userDataReselect = createSelector(getUserData, userData);
export const messageCountReselect = createSelector(getPrivateMessageCount, getCompanyMessageCount, getNotificationsCount, getTotalMessageCount, messageCount);

export const mapStateToProps = (state, props) => ({
    isMessagingOn: isMessagingOn(state),
    messaging: messagingReselect(state),
    helmet: helmetReselect(state, props),
    userData: userDataReselect(state),
    companyLotsAmount: state.company.companyLotsAmount,
    newMessageCount: messageCountReselect(state),
    lang: state.general.selectedLang
});


export const mapDispatchToProps = dispatch => ({
    functions: bindActionCreators({
        flushAllThreads,
        flushAllLots,
        getLotList,
        getAllMessages,
        activateThread,
        getUserCompany,
        getNewMessagesForThread,
        getAllThreadsForItem,
        getParticipantInfo,
        flushAllMessages,
        sendMessage,
        markMessageAsRead,
        getThreadLot,
        getNotifications
    }, dispatch)
});
