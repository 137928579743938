import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import qs from 'qs';
import size from 'lodash/size';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import { getAllFilter } from 'MODULES/reselect/selectors/filters';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { checkNested } from 'MODULES/checkNested';
import { getSortedName } from 'MODULES/getSortedName';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { LOT_SORT_CONFIG } from 'CONFIG/LOT_SORT_CONFIG';
import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { sortBy } from 'ACTIONS/filters/sortBy';

const SortButtons = ({ i18n, ppType, history, isMobile, functions, location, sortBy, category, isRealEstate }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const allFilters = useSelector(state => getAllFilter(state));
    let paramsFromLink = getParamsFromSearch(location.search);

    const filterValues = checkNested(allFilters, 'filterValues', {});
    const categoryFilters = checkNested(allFilters, 'categoryFilters');
    const sortedBy = checkNested(allFilters, 'sort', '');
    const sortedName = getSortedName(paramsFromLink.sort || sortedBy, ppType);
    const filterSortOptions = filter(checkNested(categoryFilters, 'filterSortOptions', []), sortOption => sortOption.name !== sortedName);
    const sortTitle = sortedBy ? SORT_OPTIONS[paramsFromLink.sort || sortedBy].title(i18n) : SORT_OPTIONS[sortedName].title(i18n);
    const actualSortConfig = LOT_SORT_CONFIG[ppType];
    const allSortOptions = [...actualSortConfig, ...filterSortOptions];
    const lang = useSelector(getSelectedLanguage);
    const filters = useSelector(getAllFilter);

    useEffect(() => {
        setSelectedOption(sortedName);
    }, [sortedName]);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    const handleChange = (e) => {
        const { value } = e.target;
        setSelectedOption(value);
        if (value !== sortedName) {
            if (!value) {
                sortBy(value, omit(filterValues, ['orderColumn', 'orderDirection']), true, ppType);
            } else {
                sortBy(value, filterValues, true, ppType);
            }
        }
        if (history && value) {
            const urlParams = SORT_OPTIONS[value].urlParam;
            paramsFromLink = { ...paramsFromLink, sort: value, ...urlParams };
            history.push({ search: qs.stringify(paramsFromLink) });
        }
        if (isMobile && ppType !== PP_TYPE.search) {
            functions.filterOut(PP_TYPE.list, filterValues, null, history);
        }
    };
    return (
        <div className="d-flex text-end">
            {ppType !== PP_TYPE.filtersCategory && (
                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-inline-block btn-hover-style">
                    <DropdownToggle color="action" className="btn-sort ms-1">
                        <i className="pp-ico pp-sort-amount-asc pe-0-sm" />
                        <span className="d-none d-md-inline">{sortTitle}</span>
                    </DropdownToggle>

                    <select
                        className="sort-dropdown"
                        onChange={handleChange}
                        value={selectedOption}
                    >
                        {allSortOptions.map((sortItem) => sortItem.name !== sortedName && (
                            <option
                                id={sortItem.name}
                                key={sortItem.name}
                                value={!size(checkNested(SORT_OPTIONS[sortItem.name], 'urlParam')) ? '' : sortItem.name}
                            >
                                {SORT_OPTIONS[sortItem.name].title(i18n)}
                            </option>
                        ))}
                    </select>
                </Dropdown>
            )}
            {isMobile && isRealEstate
                && (
                    <button
                        className="btn btn-secondary btn-sm btn-action ms-1"
                        onClick={() => {
                            history.push({
                                pathname: PP_URLS.MODAL_MAP(category.slug[lang], lang, 'category'),
                                region: checkNested(paramsFromLink, 'region'),
                                search: checkNested(filters, 'filterToString', '')
                            });
                        }}
                        key="map"
                    >
                        <i className="pp-ico pp-map-marker pe-0" aria-hidden="true" />
                    </button>
                )}
        </div>
    );
};

export default connect(null, { sortBy })(SortButtons);
