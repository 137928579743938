import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { toggleCompare } from 'ACTIONS/compare/toggleCompare';
import { LIMIT_FOR_COMPARE_LIST } from 'CONST';
import { ppCompareIdsSelector } from 'MODULES/reselect/selectors/pp_list';
import { categoriesGetPpByIdSelector } from 'MODULES/reselect/selectors/categories';
import { useRouteMatch } from 'react-router';


const PpCompareButton = (props) => {
    const dispatch = useDispatch();

    const compareList = useSelector(ppCompareIdsSelector);
    const match = useRouteMatch();
    const ppId = match.params.ppid;
    const pp = useSelector((state) => categoriesGetPpByIdSelector(state, ppId));
    const isAddedInCompare = !!compareList[ppId];
    const handleButtonClick = () => dispatch(toggleCompare(pp, isAddedInCompare, props.i18n));
    return (
        props.ppCountInCompareList === LIMIT_FOR_COMPARE_LIST
            ? (
                <Link to={PP_URLS.COMPARE_LOTS(props.lang)}>
                    <button
                        className="btn-only-icon"
                        data-test="compare-pp"
                        id="compare-btn"
                    >
                        {props.i18n.__('Compare list full. Go compare and remove a lot.')}
                    </button>
                </Link>
            )
            : (
                <button
                    className={classNames('btn-only-icon', { 'is-on': isAddedInCompare })}
                    title={props.i18n.__('Add to compare')}
                    data-test="compare-pp"
                    id="compare-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        if (props.isFetchingCompare) {
                            return false;
                        }
                        handleButtonClick();
                    }}
                >
                    <i className="pp-ico pp-bar-chart" />
                </button>
            )
    );
};

export default PpCompareButton;
