import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { GET_USER_CATEGORIES } from 'ACTIONS';
import { prepareDataPayload } from 'MODULES/api';

export const getUserLotsCategories = () => dispatch => dispatch(apiAction(
    {
        url: API_URLS.LOTS_CATEGORIES,
        auth: true,
        onSuccess: data => (
            {
                type: GET_USER_CATEGORIES,
                payload: prepareDataPayload(data.content)
            }
        ),
        label: GET_USER_CATEGORIES
    }
));
