import { imgSizes } from 'CONST';

import { checkNested } from 'MODULES/checkNested';

export const ratioImg = (file, size) => {
    const imgWidth = checkNested(file.versions, [imgSizes[size], 'info', 'width']);
    const imgHeight = checkNested(file.versions, [imgSizes[size], 'info', 'height']);
    const ratio = imgWidth / imgHeight;
    return ratio;
};
