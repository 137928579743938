import Storage from 'HOC/storage';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { SEND_STAT_DATA } from 'ACTIONS';

export const postUserWatchPromise = (dispatch) => {
    let identifier = Storage.get('watch');
    const isLoggedIn = Storage.get('login') === '1';

    return new Promise((resolve) => {
        if (identifier) {
            resolve(identifier);
        } else {
            return dispatch(apiAction(
                {
                    url: API_URLS.WATCH_HISTORY_IDENTIFIER,
                    withCredentials: isLoggedIn,
                    method: 'POST',
                    auth: true,
                    onSuccess: data => () => {
                        identifier = data.content.identifier;
                        resolve(identifier);
                    },
                    label: SEND_STAT_DATA
                }
            ));
        }
    });
};
