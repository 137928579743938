import React, { memo } from 'react';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import { getMultipleCategoryTitleArray } from 'MODULES/reselect/selectors/filters';
import { categoriesCurrentCategorySelector } from 'MODULES/reselect/selectors/categories';
import { getUserCookie } from 'MODULES/reselect/selectors/user';
import { useRouteMatch } from 'react-router';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { MODALS } from 'MODULES/MODALS';
import { showModalWithData } from 'ACTIONS/ui/showModalWithData';
import { checkNested } from 'MODULES/checkNested';

const SubscribeBlock = ({ i18n, categoryId, multipleCategoryString, filterRegions }) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const lang = useSelector(getSelectedLanguage);
    const multipleCategories = useSelector(getMultipleCategoryTitleArray);
    const isLoggedIn = useSelector(getUserCookie);
    const form = `filtersubscription-${window.location.pathname}`;
    const currentCategory = useSelector(state => categoriesCurrentCategorySelector(state, { match }));

    const drawNameForForm = () => {
        let names = [];

        const nameParens = size(multipleCategories) > 0 ? multipleCategories : map(currentCategory.parents, (parent, index) => (index > 0 ? `${parent.name[lang]} ` : ''));
        names = [...names, ...nameParens];

        const searchParams = new URLSearchParams(location.search);
        const region = searchParams.get('region');
        const street = searchParams.get('street');
        if ((region || street) && checkNested(filterRegions, 'regions')) {
            if (street) {
                const selectedStreet = find(filterRegions.street, { id: Number(street) });
                names = [...names, checkNested(selectedStreet, 'name')];
            }
            if (!street) {
                const parent = find(filterRegions.regions.parent, { id: Number(region) });
                const child = find(filterRegions.regions.child, { id: Number(region) });
                names = parent ? [...names, checkNested(parent, 'name')] : [...names, checkNested(child, 'name')];
            }
        }

        return names;
    };

    return (
        <div key="subscribe_block">
            <div className="alert alert-info d-flex align-items-center mb-1">
                <i className="pp-ico pp-info-circle me-2" />
                {i18n.__('Save this search and get notified when new offers are posted.')}
                <button
                    type="button"
                    id="subscribe-filter"
                    className="btn btn-primary btn-sm ms-auto flex-shrink-0"
                    aria-label={i18n.__('Save search')}
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(showModalWithData({ categoryId, isLoggedIn, multipleCategoryString, form }, MODALS.filtersCreate, true));
                        dispatch(change(form, 'name', drawNameForForm().join(' ')));
                    }}
                >
                    <i className="pp-landing pp-filter" />
                    {i18n.__('Save search')}
                </button>
            </div>
        </div>
    );
};

export default memo(SubscribeBlock);
