import React from 'react';
import find from 'lodash/find';
import { checkNested } from 'MODULES/checkNested';
import { filterId } from 'CONST';


const RequiredFilter = ({ requiredFilters }) => {
    const floorValue = checkNested(find(requiredFilters, filterId.floor), [filterId.floor], '');
    const numberOfFloorsValue = checkNested(find(requiredFilters, filterId.numberOfFloors), [filterId.numberOfFloors], '');
    const separator = (floorValue && numberOfFloorsValue) ? '/' : '';
    const combinedFloorValue = `${floorValue}${separator}${numberOfFloorsValue}`;
    return requiredFilters.map(
        reqFilter => (
            checkNested(reqFilter, 'filter') && (
                <div
                    key={reqFilter.id}
                    title={checkNested(reqFilter, 'filter.name', '')}
                >
                    <i className={`pp-landing ${reqFilter.filter.cssClass}`} />
                    {reqFilter.filter.id === filterId.floor
                        ? `${combinedFloorValue}`
                        : reqFilter.textValue}
                </div>
            )
        )
    );
};

export default RequiredFilter;
