import { PP_TYPE } from 'MODULES/PP_TYPE';
import { API } from 'CONFIG/API';
import { checkNested } from 'MODULES/checkNested';
import { getCategoryIdFromPath } from 'MODULES/getCategoryIdFromPath';
import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import { CLASSIFIEDS_PER_PAGE } from 'CONFIG';
import qs from 'qs';
import pick from 'lodash/pick';
import { FILTER_WHITELIST_PARAMS } from 'CONFIG/FILTER_WHITELIST_PARAMS';
import { FILTER_OUT, GET_FILTER_LOT_COUNT, GET_PPS_BY_PAGE, GET_PPS_CATEGORY, GET_PPS_LIST } from 'ACTIONS';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { getPpsList } from 'ACTIONS/categories/getPpsList';
import { prepareDataPayload } from 'MODULES/api';

import { setPpsActivePage } from 'ACTIONS/categories/setPpsActivePage';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';

export const getPps = (path, url, type = PP_TYPE.list, flush = false, additionalParams = {}, page, dispatcherCount, skipParams = false, apiPrefix = null, host = API, onError, withCredentials = false, activePath = false) => (dispatch, getState) => {
    const state = getState();
    let activePage = checkNested(additionalParams, 'page', page) || 1;
    if (page > 0) {
        activePage = page;
    }

    if (url) {
        const pathName = decodeURIComponent(window.location.pathname).replace('.', '_'); // This decodes whitespace and other characters to human readable form
        let filters = checkNested(state.filters, `${pathName}.filterToString`, 'default', true) || 'default';

        if (type === PP_TYPE.mapListPp) {
            filters = 'default';
        }
        const dispatchID = `${pathName}_${filters}`;
        const categoryId = getCategoryIdFromPath(url);
        const params = checkNested(state.filters, `${pathName}.filterValues`, {});
        const filterString = checkNested(additionalParams, 'sort', checkNested(state.filters, `${pathName}.sort`));
        const sortBy = (filterString) && type !== PP_TYPE.search ? SORT_OPTIONS[filterString].urlParam : '';
        const itemsPerPage = checkNested(additionalParams, 'itemsPerPage', CLASSIFIEDS_PER_PAGE);
        let paramsFromLink = {};

        if (type === PP_TYPE.search) {
            // Search is switched to window  params
            paramsFromLink = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        }

        const newParams = {
            ...params,
            ...additionalParams,
            ...sortBy,
            ...paramsFromLink,
            itemsPerPage,
            currentPage: activePage > 0 && !isNaN(activePage) ? activePage : 1
        };

        const getUrl = `${url}${skipParams ? '' : qs.stringify(pick(newParams, FILTER_WHITELIST_PARAMS), {
            addQueryPrefix: true,
            arrayFormat: 'brackets'
        })}`;

        dispatch(setIsFetching(GET_PPS_CATEGORY, categoryId));

        if (checkNested(state, ['categories', 'ppsList', type, dispatchID, page])) {
            dispatch(setPpsActivePage(dispatchID, activePage));
        }
        // if (type !== PP_TYPE.filtersCategory && state.router.action === 'POP' && state.routeCache.success[getUrl] && state.routeCache.success[getUrl].ttl > new Date().getTime()) {
        //     dispatch(setPpsActivePage(dispatchID, activePage));
        //     return false;
        // }
        if (flush || !activePage) {
            dispatch(setPpsActivePage(dispatchID));
        }

        dispatch(setIsFetching(`${GET_PPS_LIST}.${type}.${activePage || 1}`, true));
        dispatch(setIsFetching(GET_PPS_BY_PAGE, true));

        return dispatch(apiAction(
            {
                url: getUrl,
                host: host || API,
                withCredentials,
                apiPrefix,
                // TODO: CHECK FOR BETTER SOLUTION
                auth: [API_URLS.RECENLTY_ADDED_ALL, API_URLS.COMPANY_LOTS, API_URLS.LOT_SUBSCRIPTIONS, API_URLS.LOTS_EXPIRED_PLAN, API_URLS.LOTS].includes(url),
                returnParams: {
                    activePage
                },
                onSuccess: data => () => {
                    dispatch(getPpsList(data.content, type, dispatchID, null, activePage || 1, categoryId, activePath));
                    dispatch(setPpsActivePage(activePath || dispatchID, activePage || 1));
                    dispatch(setIsFetching(`${GET_PPS_LIST}.${type}.${activePage || 1}`, false));
                    dispatch(setIsFetching(GET_PPS_BY_PAGE, false));

                    if (dispatcherCount) {
                        dispatch({
                            type: dispatcherCount,
                            payload: data.content ? prepareDataPayload(data.content.count) : 0
                        });
                    }
                    dispatch({ type: GET_FILTER_LOT_COUNT, payload: checkNested(data, 'content.count', 0) });
                },
                onFailure: () => () => {
                    if (onError) {
                        dispatch(onError(dispatch));
                    }

                    dispatch({
                        type: dispatcherCount,
                        payload: 0
                    });
                    dispatch(getPpsList(null, type, dispatchID, null, activePage || 1));
                    dispatch(setIsFetching(`${GET_PPS_LIST}.${type}.${activePage}`, false));
                    dispatch(setIsFetching(GET_PPS_BY_PAGE, false));
                },
                label: FILTER_OUT
            }
        ));
    }
    return null;
};
