import { createSelector } from 'reselect';
import get from 'lodash/get';
import { filtersStringState, countState } from 'MODULES/reselect/state';
import size from 'lodash/size';
import { checkNested } from 'MODULES/checkNested';
import { getTransportStopCount } from 'MODULES/getTransportStopCount';
import { PP_TYPE } from 'MODULES/PP_TYPE';


const emptyObject = {};
export const classifiedsPagePath = createSelector(
    filtersStringState,
    (state, props) => checkNested(props, 'location.pathname', window.location.pathname),
    (string, location) => (`${decodeURIComponent(location).replace('.', '_')}_${string}`)
);

export const classifiedsPath = createSelector(
    (state, props) => props.ppType || PP_TYPE.list,
    classifiedsPagePath,
    (ppType, pagePath) => [ppType, pagePath]
);

export const classifiedsPageState = createSelector(
    classifiedsPagePath,
    pagePath => pagePath
);

export const classifiedsPathPpType = createSelector(
    (state, props) => props.ppType || PP_TYPE.list,
    ppType => ppType
);

export const classifiedsPathByUrl = createSelector(
    [(state, props) => {
        if (props.location.pathname === '/my/company') {
            return PP_TYPE.myCompany;
        }
        return PP_TYPE.my;
    }, classifiedsPagePath],
    (ppType, pagePath) => [ppType, pagePath]
);

export const getClassifiedCount = createSelector(
    [
        classifiedsPath,
        countState
    ],
    (path, count) => get(count, path)
);

export const getClassifiedListItems = createSelector(
    classifiedsPath,
    state => state.categories.ppsList,
    (path, list) => get(list, path) || emptyObject
);

export const getLotReviewObject = createSelector(
    state => state.categories.reviews,
    reviews => reviews
);

export const getLotMyReviewObject = createSelector(
    state => state.categories.myReview,
    myReview => myReview
);

export const getOpenedPpIdState = createSelector(
    (state, props) => props.match.params.ppid,
    pp => pp

);

export const getClassifiedByIdState = createSelector(
    state => state.categories.pp,
    pps => pps
);

export const getClassifiedDataState = createSelector(
    getOpenedPpIdState,
    getClassifiedByIdState,
    (pp, pps) => (
        pps[pp]
    )
);

export const getClassifiedByIdDataState = createSelector(
    (state, id) => id,
    getClassifiedByIdState,
    (pp, pps) => (
        pps[pp]
    )
);

export const lotAmenitiesState = createSelector(
    getOpenedPpIdState,
    state => state.categories.lotAmenities,
    (pp, amenities) => (
        {
            amenities: amenities[pp],
            showLotAmenities: size(checkNested(amenities[pp], 'stops')) > 0 || size(checkNested(amenities[pp], 'institutions')) > 0 || size(checkNested(amenities[pp], 'shops')) > 0,
            stopsCount: checkNested(amenities[pp], 'stops') ? getTransportStopCount(amenities[pp].stops) : 0
        }
    )
);

export const similarItemsState = createSelector(
    getOpenedPpIdState,
    state => state.categories.similarItems,
    (pp, similarItems) => checkNested(similarItems, pp, [])
);
export const getClassifiedMemberState = createSelector(
    state => (state.categories.lotMembers),
    state => state.categories.pp,
    getOpenedPpIdState,
    (members, pp, ppId) => {
        const owner = checkNested(pp, [ppId, 'owner', 'id']);
        if (!members || !owner) {
            return { };
        }

        return members[owner] || {};
    }
);
