import Storage from 'HOC/storage';
import { API_URLS } from 'CONFIG/API_URLS';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { getPps } from 'ACTIONS/categories/getPps';
import { postUserWatchPromise } from 'ACTIONS/user/postUserWatchPromise';

export const getBookmarkedLots = (flush = false, activePage = 1) => (dispatch) => {
    const isLoggedIn = Storage.get('login') === '1';
    const location = window.location.pathname;

    if (!isLoggedIn) {
        postUserWatchPromise(dispatch).then((watchId) => {
            dispatch(getPps(location, API_URLS.FAVORITES_DETAILED, PP_TYPE.bookmarks, flush, { identifier: watchId }, activePage, undefined, undefined, undefined, undefined, undefined, isLoggedIn));
        });
    } else {
        dispatch(getPps(location, API_URLS.FAVORITES_DETAILED, PP_TYPE.bookmarks, flush, null, activePage, undefined, undefined, undefined, undefined, undefined, isLoggedIn));
    }
};
