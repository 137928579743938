const STATIC_TRANSLATIONS = (string, i18n) => {
    const translateObject = {
        too_many_requests: i18n.__('Too many requests'),
        not_thread_participant: i18n.__('Not thread participant'),
        invalid_message: i18n.__('Invalid message'),
        thread_already_exists: i18n.__('Thread already exists'),
        invalid_parameters: i18n.__('Invalid parameters')
    };
    return translateObject[string] || string;
};

export default STATIC_TRANSLATIONS;
