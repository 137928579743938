import React from 'react';
import { GTM_EVENTS, KEYBOARD_CODES } from 'CONST';
import { useSelector } from 'react-redux';
import { searchRecentSuggestions } from 'MODULES/reselect/selectors/search';
import size from 'lodash/size';
import SuggestionAnimations from 'COMPONENTS/header/search_input/suggestion_animations';

const SearchInput = ({ i18n, handleSearch, initialInputValue, handleClick, toggleShowAutocomplete, handleSeeAll, debounceSearch, parsedString, setSearchSuggestion }) => {
    const searchSuggestions = useSelector(searchRecentSuggestions);
    return (
        <>
            <input
                id="header-search"
                key="header-search"
                type="text"
                data-test="toolbar-classified-search-input"
                placeholder={`${i18n.__('Search')}`}
                onChange={(event) => handleSearch(event.target.value, true)}
                className="form-control header-middle-input"
                onClick={(e) => handleClick(e)}
                value={initialInputValue}
                aria-label={i18n.__('Search')}
                onKeyDown={(event) => {
                    if (event.keyCode === KEYBOARD_CODES.escape) {
                        toggleShowAutocomplete(false);
                    }
                    if (event.keyCode === KEYBOARD_CODES.enter && parsedString) {
                        event.preventDefault();
                        handleSeeAll(GTM_EVENTS.headerSearchEnter, true);
                        setSearchSuggestion(parsedString);
                        clearTimeout(debounceSearch.timeout);
                    }
                }}
            />
            {!initialInputValue && size(searchSuggestions) > 0 && (
                <div className="automatic-values">
                    <SuggestionAnimations suggestions={searchSuggestions} />
                </div>
            )}
        </>
    );
};


export default SearchInput;
