import { createSelector } from 'reselect';

export const getUnseenFilterCountState = createSelector(
    state => state.filterSubscriptions.unseenFilters,
    count => count
);

export const geFilterSubscriptionLots = createSelector(
    state => state.filterSubscriptions.unseenFilterLots,
    unseenFilterLots => unseenFilterLots
);

