import { checkNested } from 'MODULES/checkNested';
import { SORT_LOTS } from 'ACTIONS';
import { filter } from 'ACTIONS/filters/filter';
import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import omit from 'lodash/omit';

export const sortBy = (sortedBy, filterValues, sortMenu) => (dispatch) => {
    let sort = sortedBy;
    if (checkNested(filterValues, 'orderColumn') && !sortMenu) {
        sort = `${filterValues.orderColumn.replace('filter', '')}${filterValues.orderDirection}`;
    }
    dispatch({ type: SORT_LOTS, payload: sort });
    if (sort) {
        dispatch(filter({ ...omit(filterValues, 'sort'), ...SORT_OPTIONS[sort].urlParam }));
    } else {
        dispatch(filter({ ...filterValues, ...SORT_OPTIONS[sort] }));
    }
};
