import { SUBSCRIPTION_TEMPLATES, USER_INVOICES, USER_ACTIVE_PACKAGES } from 'ACTIONS';
import { SUBSCRIPTION_PROPERTIES } from 'CONST';

const serializeSubscriptionTemplates = (data) => {
    const obj = {};
    obj.bySubscription = data.filter(item => (item.isSubscription === true));
    obj.byItems = data.filter(item => (item.isSubscription === false));
    return obj;
};

const serializeUserInvoices = (content) => {
    const obj = {};
    obj.company = content.data.filter(item => (item.company));
    obj.private = content.data.filter(item => (!item.company));
    return obj;
};

const serializeUserPackages = (data) => {
    let userCredits = { availableCompany: 0, availablePersonal: 0 };
    let userSubscriptionArr = [];
    let userPackagesArr = [];
    let credits;
    let maxCompanyMembers = 0;
    if (data) {
        data.forEach((item) => {
            if (item.package.template.id === 34 || item.package.template.id === 33) { // / PPC
                return false;
            }
            if (item.package.template.isSubscription) {
                credits = item.properties[SUBSCRIPTION_PROPERTIES.simultaneously_active_items];
                userSubscriptionArr = [...userSubscriptionArr, item];
                maxCompanyMembers = item.properties[SUBSCRIPTION_PROPERTIES.company_max_members] > maxCompanyMembers ? item.properties[SUBSCRIPTION_PROPERTIES.company_max_members] : maxCompanyMembers;
            } else {
                credits = item.properties[SUBSCRIPTION_PROPERTIES.item_credits];
                userPackagesArr = [...userPackagesArr, item];
            }

            if (item.package.company && item.package.company.id) {
                credits = (userCredits.availableCompany + parseInt(credits, 10)) - item.activeItems;
                userCredits = { ...userCredits, availableCompany: credits };
            } else {
                credits = (userCredits.availablePersonal + parseInt(credits, 10)) - item.activeItems;
                userCredits = { ...userCredits, availablePersonal: credits };
            }
        });
    }

    return { maxCompanyMembers: Number(maxCompanyMembers), userSubscription: userSubscriptionArr, userPackages: userPackagesArr, availableCredits: userCredits };
};

// EXPORT FUNCTION
export default (data, type) => {
    switch (type) {
        case SUBSCRIPTION_TEMPLATES:
            return serializeSubscriptionTemplates(data);
        case USER_INVOICES:
            return serializeUserInvoices(data);
        case USER_ACTIVE_PACKAGES:
            return serializeUserPackages(data);
        default:
            return data;
    }
};
