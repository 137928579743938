import React from 'react';
import { isMobile } from 'react-device-detect';

const FormattedTitle = ({ title, maxLength }) => {
    if (!title) {
        return null;
    }
    const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    const classNameList = ['masonry-title'];
    if (title.length > maxLength) {
        classNameList.push(' single-line');
        if (isMobile) {
            classNameList.push(' mt-0');
        }
    } else {
        classNameList.push(' line-clamp-2');
    }
    const classNamesString = classNameList.join('');

    return (
        <div
            className={classNamesString}
            title={title.title}
            style={{ WebkitBoxOrient: 'vertical' }}
            data-nosnippet="true"
        >
            {formattedTitle}
        </div>
    );
};

FormattedTitle.defaultProps = {
    maxLength: 30
};

export default FormattedTitle;
