import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import { PP_TYPE } from 'MODULES/PP_TYPE';

export const LOT_DEFAULT_SORT = {
    [PP_TYPE.list]: SORT_OPTIONS.publishDateDESC.name,
    [PP_TYPE.my]: SORT_OPTIONS.createDateDESC.name,
    [PP_TYPE.myCompany]: SORT_OPTIONS.createDateDESC.name,
    [PP_TYPE.publicCompany]: SORT_OPTIONS.publishDateDESC.name,
    [PP_TYPE.search]: SORT_OPTIONS.bestMatch.name,
    [PP_TYPE.bookmarks]: SORT_OPTIONS.createDateDESCBookmarks.name,
    [PP_TYPE.filters]: SORT_OPTIONS.publishDateDESC.name,
    [PP_TYPE.user]: SORT_OPTIONS.publishDateDESC.name,
    [PP_TYPE.filtersCategory]: SORT_OPTIONS.publishDateDESC.name,
    default: SORT_OPTIONS.createDateDESC.name
};
