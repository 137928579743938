import { MESSAGING_API, MESSAGING_CONF } from 'CONFIG';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { getUnixTimeStamp } from 'MODULES';
import { checkNested } from 'MODULES/checkNested';
import { GET_MESSAGES } from 'ACTIONS';
import { apiPrefix } from 'ACTIONS/messaging/index';
import { getAllThreadsForItem } from 'ACTIONS/messaging/getAllThreadsForItem';
import findLast from 'lodash/findLast';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';


export const getNewMessagesForThread = (thread, owner = 'personal', id, order = MESSAGING_CONF.messaging_sort, pointer = {}, lotId, status = 'active') => (dispatch, getState) => {
    if (!thread) {
        return null;
    }
    dispatch(
        apiAction(
            {
                url: API_URLS.MESSAGES_BY_HASH(thread),
                auth: true,
                host: MESSAGING_API,
                apiPrefix,
                data: {
                    pointer_direction: order,
                    limit: 10,
                    order,
                    ...pointer
                },
                onSuccess: payload => () => {
                    const updateAd = id || getUnixTimeStamp();
                    if (checkNested(payload, ['content'])) {
                        const { user: { userData } } = getState();
                        const lastNotOwnedMessage = findLast(
                            sortBy(payload.content, 'createdAt'),
                            message => message.participant.participantId !== userData.id
                        );
                        dispatch({
                            type: GET_MESSAGES,
                            hash: thread,
                            payload: keyBy(checkNested(payload, ['content']), 'id'),
                            owner,
                            id: updateAd,
                            lastNotOwnedMessage,
                            status
                        });

                        if (!id) {
                            dispatch(getAllThreadsForItem(lotId, owner, 'DESC', updateAd, updateAd));
                        }
                    }
                },
                label: GET_MESSAGES
            }
        )
    );
};
