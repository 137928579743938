import React from 'react';
import size from 'lodash/size';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { KEYBOARD_CODES, LOT_STATUS } from 'MODULES/const';
import { CHANGE_LOT_STATUS } from 'ACTIONS';
import { connect } from 'react-redux';
import { getMSelect } from 'MODULES/reselect/header';


class HeaderMSelect extends React.PureComponent {
    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        this.setState({
            tooltipOpen: false
        });
    }

    componentDidUpdate(prevProps) {
        const { location, emptyMSelect } = this.props;
        if (location.pathname !== prevProps.location.pathname) {
            emptyMSelect();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === KEYBOARD_CODES.escape) {
            this.props.emptyMSelect();
        }
    };

    closeLot = () => {
        const { changeLotStatus, emptyMSelect, mSelect: { path, itemsSelected, activePage } } = this.props;
        const modalData = {};
        const { showModalWithData, i18n } = this.props;
        modalData.title = i18n.__('Are you sure your want to close these classifieds?');
        modalData.icon = 'pp-power-off';
        modalData.text = i18n.__('Attention, when closing an classified, it will be no longer visible in your classified view');
        modalData.okText = i18n.p__('Permanent', 'Close');
        modalData.isFetching = CHANGE_LOT_STATUS;
        modalData.okAction = onSuccess => changeLotStatus(keys(itemsSelected), LOT_STATUS.close, null, () => {
            emptyMSelect();
            onSuccess();
        }, null, null, null, [...path, activePage]);
        showModalWithData(modalData);
    };

    deleteLot = () => {
        const { deletePp, emptyMSelect, mSelect: { itemsSelected } } = this.props;
        const modalData = {};
        const { showModalWithData, i18n } = this.props;
        modalData.title = i18n.__('Are you sure your want to delete these classifieds?');
        modalData.icon = 'pp-trash';
        modalData.text = i18n.__('Attention, deleting this classified data will not be restored.');
        modalData.okText = i18n.p__('Permanent', 'Delete');
        modalData.isFetching = CHANGE_LOT_STATUS;
        const firstLot = values(itemsSelected)[0];

        modalData.okAction = onSuccess => deletePp(keys(itemsSelected), firstLot.ppType, () => {
            emptyMSelect();
            onSuccess();
        }, values(itemsSelected)[0], null, true);
        showModalWithData(modalData);
    };

    seenLot = () => {
        const { mSelect: { itemsSelected, hash }, patchFilterSubscriptionLotAsSeen, emptyMSelect } = this.props;
        forEach(itemsSelected, item => patchFilterSubscriptionLotAsSeen(hash, item));
        emptyMSelect();
    };

    selectAll = () => {
        const { addMSelectItem, mSelect: { unseenFilterLots, itemsSelected } } = this.props;
        forEach(unseenFilterLots.lots, (lot) => {
            if (!itemsSelected || !itemsSelected[lot.id]) {
                addMSelectItem(lot);
            }
        });
    };

    render() {
        const { emptyMSelect, changeLotStatus, i18n, mSelect: { path, itemsSelected, showDelete, showClose, showPublish, activePage, unseenFilterLots } } = this.props;

        return (
            <div id="action-bar" className="row header-selected-lot">
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3 selected-lot-count d-none d-lg-flex">
                    <label className="d-none d-sm-block">
                        {i18n.__('Selected')}
                        :&nbsp;
                        {size(itemsSelected)}
                    </label>
                </div>
                {!unseenFilterLots && (
                    <div className="col-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9 d-flex align-items-center">
                        <button
                            disabled={!showPublish}
                            id="action-publish-button"
                            className="btn btn-secondary me-1"
                            onClick={() => changeLotStatus(keys(itemsSelected), LOT_STATUS.publish, null, emptyMSelect, null, null, null, [...path, activePage])}
                            title={showPublish ? i18n.__('Publish') : i18n.__('Publish - not available because, cant be done with all selected items')}
                        >
                            <i className="pp-ico pp-arrow-circle-o-up" />
                            {i18n.__('Publish')}
                        </button>
                        <button
                            disabled={!showClose}
                            id="action-close-button"
                            className="btn btn-secondary me-1"
                            onClick={this.closeLot}
                            title={showClose ? i18n.__('Close') : i18n.__('Close - not available because, cant be done with all selected items')}
                        >
                            <i className="pp-landing pp-power-off" />
                            {i18n.__('Close')}
                        </button>
                        <button
                            disabled={!showDelete}
                            id="action-delete-button"
                            className="btn btn-secondary"
                            onClick={this.deleteLot}
                            title={showDelete ? i18n.__('Delete') : i18n.__('Delete - not available because, cant be done with all selected items')}
                        >
                            <i className="pp-ico pp-trash-o" />
                            {i18n.__('Delete')}
                        </button>
                        <div className="ms-auto w-auto">
                            <button
                                id="action-unselect-button"
                                className="pp-landing pp-close-thin icon"
                                onClick={this.props.emptyMSelect}
                                title={i18n.__('Uncheck all selected checkboxes')}
                            />
                        </div>
                    </div>
                )}
                {unseenFilterLots && (
                    <div className="col-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9 d-flex align-items-center">
                        <button
                            id="action-seen-button"
                            className="btn btn-secondary me-1"
                            onClick={this.seenLot}
                            title={i18n.__('Mark as seen')}
                        >
                            <i className="pp-ico pp-eye" />
                            {i18n.__('Mark as seen')}
                        </button>
                        {size(unseenFilterLots.lots) > 1 && (
                            <button
                                id="action-seen-button"
                                className="btn btn-secondary me-1"
                                onClick={this.selectAll}
                                title={i18n.__('Select all')}
                                disabled={size(itemsSelected) === unseenFilterLots.count}
                            >
                                <i className="pp-ico pp-check-circle" />
                                {i18n.__('Select all')}
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default connect((state, props) => ({
    mSelect: getMSelect(state, props)
}))(HeaderMSelect);
