
export const loadBanner = (bannerId) => {
    if (window.location.hostname.indexOf('pp.lv') !== -1) {
        if (!window.inxBX) {
            const cookie = {};
            let lang = 'lv';
            document.cookie.split(';').forEach((el) => {
                const [k, v] = el.split('=');
                cookie[k.trim()] = v;
            });
            if (cookie.language) {
                lang = cookie.language;
            }
            const config = { emiter: 'b.adbox.lv', id: '5140643e8729a9b80', lang };
            const queue = [];

            const inxBX = {
                config,
                queue,
                placement(q) {
                    queue.push(q);
                },
                bxEvent(id, status) {
                    return {
                        id: config.id,
                        status
                    };
                }
            };

            window.inxBX = inxBX;

            (function () {
                if (!document.getElementById('inxBXloader')) {
                    const bx = document.createElement('script');
                    bx.type = 'text/javascript';
                    bx.id = 'inxBXloader';
                    bx.async = !0;
                    bx.onerror = function () {
                        inxBX.bxEvent(config.id, 'ad_blocked');
                        window.inxBXAdBlocked = true;
                    };
                    bx.onload = function () {
                        inxBX.bxEvent(config.id, 'no_adblock');
                        window.inxBXAdBlocked = false;
                        window.inxBX.placement({ id: bannerId }, 1);
                    };
                    bx.src = `https://${
                        config.emiter
                    }/bxlib/js/loader.js?${
                        new Date().getTime()}`;
                    (document.getElementsByTagName('head')[0]
                        || document.getElementsByTagName('body')[0])
                        .appendChild(bx);
                }
            }());
        } else {
            window.inxBX.placement({ id: bannerId }, 1);
        }
    }
};
