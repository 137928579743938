import React from 'react';
import { useSelector } from 'react-redux';
import last from 'lodash/last';
import first from 'lodash/first';
import findIndex from 'lodash/findIndex';
import getMoreOfData from 'COMPONENTS/hoc/getMoreOfData';
import { useHistory, useLocation } from 'react-router';
import { ppListListSelector } from 'MODULES/reselect/selectors/pp_list';
import PrevNextBtn from 'COMPONENTS/lot/prevNext/prev_next_btn';
import { checkNested } from 'MODULES/checkNested';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { isMobile } from 'react-device-detect';

const PrevNext = ({ i18n, match }) => {
    const location = useLocation();
    const history = useHistory();
    //  const match = useRouteMatch();
    const ppid = Number(match.params.ppid);
    if (!location.state) {
        return null;
    }
    const { queryParam, search, pathname, ppType, regionId, goBackLink, goBackSearch, lastPageItem, pageCount, listApiUrl } = location.state;
    const lotList = checkNested(listApiUrl, 'lots')
        ? listApiUrl.lots
        : useSelector(state => ppListListSelector(state, ppType, pathname, search, queryParam, regionId));

    const filterParams = getParamsFromSearch(search);
    if (!lotList) {
        return null;
    }
    const lastLot = last(lotList);
    const firstLot = first(lotList);
    const lotIndex = findIndex(lotList, i => checkNested(i, 'id', i) === ppid);
    const nextLot = Number(checkNested(lastLot, 'id', lastLot)) === Number(ppid) ? undefined : lotList[lotIndex + 1];
    const prevLot = Number(checkNested(firstLot, 'id', firstLot)) === Number(ppid) ? undefined : lotList[lotIndex - 1];

    return (
        <div className="d-flex text-nowrap ms-auto justify-content-end">
            {goBackLink && !isMobile && (
                <button
                    className="btn btn-action btn-back d-print-none w-auto ms-1"
                    key="button-back"
                    id="button-back"
                    aria-label={i18n.__('Back')}
                    onClick={() => {
                        history.push({
                            pathname: goBackLink,
                            search:  goBackSearch
                        });
                    }}
                >
                    <i className="pp-ico pp-arrow-left pe-0 pe-md-1" />
                    <span className="d-none d-md-inline">{i18n.__('Back')}</span>
                </button>
            )}
            <div className="d-flex text-end d-print-none" id="classifieds-navigation" key="other-buttons">
                <PrevNextBtn
                    disabled={checkNested(firstLot, 'id', firstLot) === ppid && (!search || search.indexOf('page') === -1 || search.indexOf('page=1') !== -1)}
                    prev
                    i18n={i18n}
                    ppId={checkNested(prevLot, 'id', prevLot)}
                    navigateLot={prevLot}
                />
                <PrevNextBtn
                    disabled={(lastPageItem && !nextLot) || (!nextLot && (!pageCount || Number(pageCount) === Number(filterParams.currentPage)))}
                    i18n={i18n}
                    ppId={checkNested(nextLot, 'id', nextLot)}
                    navigateLot={nextLot}
                />
            </div>
        </div>
    );
};

export default getMoreOfData(PrevNext);

