import {
    DELETE_HINTS,
    GET_HINTS
} from 'ACTIONS';

const INITIAL_STATE = {
    hints: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_HINTS:
            return { ...state, hints: { ...state.hints, [action.listType]: action.payload } };
        case DELETE_HINTS:
            return {
                ...state,
                hints: {
                    ...state.hints,
                    [action.listType]: state.hints[action.listType].filter(d => d.id !== action.payload)
                }
            };
        default:
            return state;
    }
}
