import { CONTACT_TYPES } from '../modules/const';
import { COUNTRY_PHONE_PREFIX } from './index';



export const FORMS = {
    filtersubscription: {
        name: 'filtersubscription',
        config: {
            useWrapper: true,
            wrapperClass: 'filter-block',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        },
        paramsSchema: 'is_new.true',
        queryParam: {
            is_new: true
        }
    },
    filtersubscriptionedit: {
        name: 'filtersubscription',
        config: {
            useWrapper: true,
            wrapperClass: 'filter-block',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper', ss: 'ss' }
            },
            showMore: true
        },
        paramsSchema: 'is_new.true',
        queryParam: {
            is_new: true
        }
    },
    team: {
        name: 'invitecompanymember',
        config: {
            fieldsLayout: 'flexProfile',
            hideLabels: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        }
    },
    login: {
        name: 'login',
        config: {
            asyncValidation: true,
            showHidePassword: true,
            fieldsLayout: 'fullRow',
            useWrapper: true,
            wrapperClass: 'signin'
        }
    },
    userpasswordrecoveryemail: {
        name: 'userpasswordrecoveryemail',
        config: {
            fieldsLayout: 'fullRow',
            useWrapper: true,
            wrapperClass: 'signup-block',
            fieldWrapper: {
                element: 'div', props: { className: 'signup-confirm', key: 'wrapper' }
            },
            formWrapper: 'signin'
        }
    },
    userpasswordrecovery: {
        name: 'userpasswordrecovery',
        config: {
            fieldsLayout: 'fullRow',
            repeatH: true,
            useWrapper: true,
            wrapperClass: 'signup-block',
            fieldWrapper: {
                element: 'div', props: { className: 'signup-confirm', key: 'wrapper' }
            },
            fieldClass: 'col-sm-12 col-md-12',
            formWrapper: 'signin'
        }
    },
    signup: {
        name: 'usersignup',
        config: {
            showHidePassword: true,
            fieldsLayout: 'fullRow',
            useWrapper: true,
            wrapperClass: 'signup'
        }
    },
    signupinvite: {
        name: 'usersignupinvite',
        config: {
            fieldsLayout: 'fullRow',
            useWrapper: true,
            wrapperClass: 'signup',
            disabledFields: ['email']
        }
    },
    changePassword: {
        name: 'userpasswordchange',
        formConfig: {
            fieldsLayout: 'flexProfile',
            fieldClass: 'col-xs-12 col-md-8 col-lg-7 col-xl-6',
            useWrapper: false,
            repeatH: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        }
    },
    userprofile: {
        name: 'userprofile',
        formConfig: {
            fieldsLayout: 'flexProfile',
            useWrapper: false,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        }
    },
    xmllink: {
        name: 'xmllink',
        formConfig: {
            fieldsLayout: 'flexRow',
            inputBox: { colonClass: 'col-12' },
            useWrapper: false,
            messagesClassname: 'mt-1',
            fieldWrapper: {
                element: 'div', props: { className: 'row mb-1', key: 'wrapper' }
            },
            checkbox: {
                wrapper: { className: 'row' },
                subWrapper: { className: 'col-12' }
            }
        }
    },
    usercontact: {
        name: 'usercontact',
        formConfig: {
            fieldsLayout: 'flexProfile',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        },
        formConfigSocial: {
            fieldsLayout: 'col-12',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            hideDescription: true
        },
        formConfigModal: {
            fieldsLayout: 'flexProfile',
            inputBox: {
                colonClass: 'col-12'
            },
            hideDescription: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        },
        paramsSchema: `contact_type.${CONTACT_TYPES ? CONTACT_TYPES.EMAIL : ''}`,
        queryParam: {
            contact_type: CONTACT_TYPES ? CONTACT_TYPES.EMAIL : ''
        },
        initialValues: {
            contactType: CONTACT_TYPES ? CONTACT_TYPES.EMAIL : ''
        }
    },
    userphone: {
        name: 'usercontact',
        formConfig: {
            fieldsLayout: 'flexProfile',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        },
        formConfigModal: {
            fieldsLayout: 'flexProfile',
            hideDescription: true,
            inputBox: {
                colonClass: 'col-12'
            },
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            colonClassConfig: 'col-12'
        },
        paramsSchema: `contact_type.${CONTACT_TYPES ? CONTACT_TYPES.PHONE : ''}`,
        queryParam: {
            contact_type: CONTACT_TYPES ? CONTACT_TYPES.PHONE : ''
        },
        initialValues: {
            contactType: CONTACT_TYPES ? CONTACT_TYPES.PHONE : '',
            data: `${COUNTRY_PHONE_PREFIX} `
        }
    },
    contactverification: {
        name: 'contactverification',
        formConfig: {
            asyncValidation: true,
            fieldsLayout: 'flexProfile',
            hideLabels: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            useWrapper: true,
            wrapperClass: 'form-group mb-0'
        },
        formConfigModal: {
            asyncValidation: true,
            fieldsLayout: 'flexProfile',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            hideDescription: true,
            hideLabels: true,
            inputBox: {
                colonClass: 'col-12'
            },
            useWrapper: true,
            wrapperClass: 'form-group mb-0'
        }
    },
    message: {
        name: 'message',
        config: {
            asyncValidation: true,
            showControlTop: true,
            useWrapper: true,
            wrapperClass: 'form-group mb-0'
        }
    },
    messageSmall: {
        name: 'message',
        config: {
            asyncValidation: true,
            useWrapper: true,
            wrapperClass: 'form-group mb-0',
            textareaConfig: {
                type: 'standart',
                textareaClassName: 'form-control mb-2 message-text'
            }
        }
    },
    offer: {
        name: 'offer',
        config: {
            useWrapper: true,
            wrapperClass: 'form-group mb-0',
            textareaConfig: {
                type: 'standart',
                textareaClassName: 'form-control mb-2'
            }
        }
    },
    contact: {
        name: 'contact',
        config: {
            useWrapper: true,
            wrapperClass: 'form-group mb-0',
            textareaConfig: {
                type: 'standart',
                textareaClassName: 'form-control mb-2'
            }
        }
    },
    report: {
        name: 'report',
        config: {
            useWrapper: true,
            wrapperClass: 'form-group mb-0',
            textareaConfig: {
                type: 'standart',
                textareaClassName: 'form-control mb-2'
            }
        }
    },
    company: {
        name: 'company',
        config: {
            fieldsLayout: 'flexProfile',
            useWrapper: false,
            repeatH: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            fieldClasses: {
                addressGroup: {
                    address: 'col-xs-12 col-md-12 col-lg-7 col-xl-8',
                    zip: 'col-xs-12 col-md-12 col-lg-5 col-xl-4'
                },
                officeAddress: {
                    officeShopAddress: 'col-xs-12 col-md-12 col-lg-7 col-xl-8',
                    officeShopZip: 'col-xs-12 col-md-12 col-lg-5 col-xl-4'
                },
                contacts: {
                    phone: 'col-xs-12 col-md-12 col-lg-5 col-xl-5',
                    email: 'col-xs-12 col-md-12 col-lg-7 col-xl-7'
                }
            },
            groupConfig: {
                wrapperClass: 'col-xs-12 col-md-12 col-lg-7 col-xl-6',
                useSubWrapper: true,
                subWrapperClass: 'row',
                fieldClass: 'col-sm-6',
                seperateRow: true
            },
            textareaConfig: {
                type: 'standart',
                rows: 10
            },
            inputLanguages: {
                useWrapper: true
            },
            blockClassName: ' col-xs-12 col-md-12 col-lg-7 col-xl-6',
            textwrapper: true
        }
    },
    companysmall: {
        name: 'companysmall',
        config: {
            fieldsLayout: 'flexProfile',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            useWrapper: false,
            repeatH: true,
            groupConfig: {
                wrapperClass: 'col-xs-12 col-md-8 col-lg-7 col-xl-6',
                useSubWrapper: true,
                subWrapperClass: 'row',
                fieldClass: 'col-sm-6',
                seperateRow: true
            }
        }
    },
    companysmallmodal: {
        name: 'companysmall',
        config: {
            fieldsLayout: 'flexProfile',
            hideDescription: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            inputBox: {
                colonClass: 'col-12'
            },
            useWrapper: false,
            repeatH: true,
            groupConfig: {
                wrapperClass: 'col-12',
                useSubWrapper: true,
                subWrapperClass: 'row',
                fieldClass: 'col-sm-6',
                seperateRow: true
            }
        }
    },
    package: {
        name: 'package',
        config: {
            fieldsLayout: 'flexRow',
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            },
            groupConfig: {
                wrapperClass: 'col-xs-12 col-md-8 col-lg-7 col-xl-6',
                useSubWrapper: true,
                subWrapperClass: 'row',
                fieldClass: 'col-sm-12',
                seperateRow: true
            }
        }
    },
    createpp: {
        name: 'createpp',
        config: {
            fieldsLayout: 'fullRow',
            useWrapper: true,
            fieldWrapper: {
                element: 'div', props: { className: 'row', key: 'wrapper' }
            }
        }
    },
    create_classified: {
        name: 'create_classified'

    }
};
