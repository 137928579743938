import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { getPps } from 'ACTIONS/categories/getPps';
import { API_URLS } from 'CONFIG/API_URLS';
import { PP_TYPE } from 'MODULES/PP_TYPE';

export const getLotsByOwner = (user, activePage, search, skipParams = false) => (dispatch) => {
    let paramsFromLink = {};
    if (search) {
        paramsFromLink = getParamsFromSearch(search);
    }
    return (
        dispatch(getPps(decodeURIComponent(window.location.pathname), API_URLS.USER_LOTS(user), PP_TYPE.user, false, paramsFromLink, activePage, false, skipParams))
    );
};
