import qs from 'qs';
import { MESSAGING_API, MESSAGING_API_PARAMS } from 'CONFIG';

export function buildMessagingApiUrl(param, params = '') {
    let queryParams = '';
    if (params) {
        queryParams = `?${qs.stringify(params)}`;
    }
    return MESSAGING_API + MESSAGING_API_PARAMS + param + queryParams;
}
