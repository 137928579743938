import { API_URLS } from 'CONFIG/API_URLS';
import { getPps } from 'ACTIONS/categories/getPps';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { GET_MY_COMPANY_LOTS_AMOUNT } from 'ACTIONS';

export const getCompanyLots = (id, flush = false, activePage, category = null, skipParams = false) => (dispatch) => {
    let url = API_URLS.COMPANY_LOTS;
    if (id && !category) {
        url = API_URLS.COMPANY_LOTS_BY_ID(id);
    }
    if (id && category) {
        url = API_URLS.COMPANY_LOTS_BY_ID_BY_CATEGORY(id, category);
    }
    return dispatch(getPps(window.location.pathname, url, id ? PP_TYPE.publicCompany : PP_TYPE.myCompany, flush, false, activePage, !id && GET_MY_COMPANY_LOTS_AMOUNT, skipParams, undefined, undefined, undefined, !id));
};
