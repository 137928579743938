import React from 'react';
import { CURRENCY } from 'CONFIG';

const OldPrice = ({ price, discountedValue }) => discountedValue && price && Number(price.value) !== Number(discountedValue) && (
    <span
        className="old-price"
    >
        {price.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.00', '')}
        {CURRENCY}
    </span>
);

export default OldPrice;
