import React from 'react';
import { PUBLISH_AS_TYPE } from 'MODULES/const';
import classNames from 'classnames';
import { checkNested } from 'MODULES/checkNested';

const FilterBy = ({ input, i18n, hasSection, initialValues }) => (
    <div className={classNames('col-12', { 'filter-section': !hasSection })}>
        <div className="form-group">
            <h6>{i18n.__('Seller type')}</h6>
            <div className="custom-controls-stacked mb-3">
                <div
                    className="form-check custom-checkbox form-check-inline"
                >
                    <input
                        onChange={
                            () => {
                                input.onChange({ ...checkNested(input, 'value', {}),
                                    [PUBLISH_AS_TYPE.me]: !checkNested(input, ['value', PUBLISH_AS_TYPE.me])
                                });
                            }
                        }
                        type="checkbox"
                        id={`filter_by_${PUBLISH_AS_TYPE.me}`}
                        defaultChecked={checkNested(input, ['value', PUBLISH_AS_TYPE.me]) || (initialValues && initialValues.ownerType === PUBLISH_AS_TYPE.me)}
                        name={checkNested(input, ['name'])}
                        className="form-check-input"
                    />
                    <label className="form-check-label radio-sm" htmlFor={`filter_by_${PUBLISH_AS_TYPE.me}`}>
                        {i18n.__('Private')}
                    </label>
                </div>
                <div
                    className="form-check custom-checkbox form-check-inline"
                >
                    <input
                        type="checkbox"
                        onChange={() => {
                            input.onChange({ ...checkNested(input, 'value', {}),
                                [PUBLISH_AS_TYPE.company]: !checkNested(input, ['value', PUBLISH_AS_TYPE.company]) });
                        }}
                        id={`filter_by_${PUBLISH_AS_TYPE.company}`}
                        name={checkNested(input, ['name'])}
                        defaultChecked={checkNested(input, ['value', PUBLISH_AS_TYPE.company]) || (initialValues && initialValues.ownerType === PUBLISH_AS_TYPE.company)}
                        className="form-check-input"
                    />
                    <label className="form-check-label radio-sm" htmlFor={`filter_by_${PUBLISH_AS_TYPE.company}`}>
                        {i18n.__('Company')}
                    </label>
                </div>
            </div>
        </div>
    </div>
);

export default FilterBy;
