import { getCategoryFilters } from 'ACTIONS/filters/getCategoryFilters';
import { API_URLS } from 'CONFIG/API_URLS';
import { apiAction } from 'ACTIONS/api';
import { checkNested } from 'MODULES/checkNested';
import { FILTER_NEW_ID, FILTER_USED_ID, filterId, WINTER_BEATER_FILTER_ID } from 'CONST';
import { GET_CATEGORY_FILTERS } from 'ACTIONS';
import { FILTERS_AVAILABLE_FOR_SORT } from 'CONFIG';
import { getCategoryFiltersById } from 'ACTIONS/filters/getCategoryFiltersById';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import get from 'lodash/get';
import includes from 'lodash/includes';
import set from 'lodash/set';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import size from 'lodash/size';
import keyBy from 'lodash/keyBy';
import { defineYearMonth } from 'MODULES/defineYearMonth';
import { API } from 'CONFIG/API';

export const getFilters = (id = null, company, isCreate, onSuccess, query = '') => (dispatch, getState) => {
    if (!id) {
        return dispatch(getCategoryFilters());
    }
    const url = API_URLS.CATEGORY_FILTERS(id);
    return dispatch(apiAction(
        {
            url,
            auth: false,
            data: isCreate ? { create: true } : undefined,
            onSuccess: data => () => {
                new Promise((resolve) => {
                    if (API.indexOf('pp.lv') === -1 || isCreate) { // use prod endpoint for development, dev elastic does not contains data for used filter value
                        resolve();
                    } else {
                        const urlParams = new URLSearchParams(query);
                        const multipleCategoryString = urlParams.get('multipleCategoryString');
                        let path = `?categories[]=${id}`;
                        if (multipleCategoryString) {
                            const categoryIds = multipleCategoryString.split(',');
                            const queryParams = categoryIds.map((categoryId) => `categories[]=${encodeURIComponent(categoryId)}`);
                            const resultQueryString = queryParams.join('&');
                            urlParams.delete('multipleCategoryString');
                            const fV = urlParams.toString();
                            path = `?${resultQueryString}&${fV}`;
                        } else {
                            path = `${path}&${query}`;
                        }
                        dispatch(apiAction({
                            url: `${API_URLS.SEARCH_LOTS_FILTERS_USED}${path}`,
                            auth: false,
                            onSuccess: data => () => resolve(data)
                        }));
                    }
                }).then((response) => {
                    if (checkNested(response, 'content')) {
                        forEach(data.content.data.categoryFilters, filterObj => {
                            const catFilterId = get(filterObj, 'filter.id');
                            const excludedFilter = [filterId.numberOfFloors, filterId.floor, filterId.numberOfRooms, filterId.areaM2, filterId.mot, filterId.distance, filterId.liftingPower, filterId.volumeM3, filterId.speedIndex]; // list of filters that not returned from used request
                            // Check if filterId is in the excluded filters list
                            if (includes(response.content.filters, catFilterId) || includes(excludedFilter, catFilterId)) {
                                forEach(filterObj.filter.filterDefinedValues, value => {
                                    if (includes(response.content.filterValues, value.id) || includes(excludedFilter, catFilterId)) {
                                        set(value, 'disabled', false);
                                        const checkedFilter = [FILTER_NEW_ID, FILTER_USED_ID];
                                        if (checkedFilter.includes(value.id)) {
                                            set(value, 'checked', true);
                                        }
                                    } else {
                                        set(value, 'disabled', true);
                                    }
                                });
                            } else {
                                forEach(filterObj.filter.filterDefinedValues, value => {
                                    set(value, 'disabled', true);
                                });
                            }
                        });
                    }

                    const { req } = getState();
                    let filtersBySequence = {};
                    const filtersResponse = checkNested(data.content, 'data', {}, req);

                    dispatch(setIsFetching(GET_CATEGORY_FILTERS, false));
                    let filtersSort = [];

                    forEach(checkNested(filtersResponse, 'categoryFilters'), (f) => {
                        //  filtersBySequence = { ...filtersBySequence, [f.sequence]: f };
                        if (FILTERS_AVAILABLE_FOR_SORT.indexOf(f.filter.id) !== -1) {
                            filtersSort = [...filtersSort,
                                {
                                    name: `${f.filter.id}ASC`,
                                    title: f.filter.name,
                                    urlParam: { orderColumn: `filter${f.filter.id}`, orderDirection: 'ASC' }

                                },
                                {
                                    name: `${f.filter.id}DESC`,
                                    title: f.filter.name,
                                    urlParam: { orderColumn: `filter${f.filter.id}`, orderDirection: 'DESC' }

                                }
                            ];
                        }
                        defineYearMonth(f);
                    });
                    const filtersBySections = groupBy(checkNested(filtersResponse, 'categoryFilters'), 'filterSection.id');
                    if (checkNested(filtersResponse, 'customBlockSorting') && !isCreate) {
                        const customBlockDiscount = { filterSection: null, block: 5, sequence: 5, hideInList: false };
                        set(filtersResponse, 'customBlockSorting', [...filtersResponse.customBlockSorting, customBlockDiscount]);
                    }
                    forEach(checkNested(filtersResponse, 'customBlockSorting'), (f) => {
                        filtersBySequence = { ...filtersBySequence, [f.sequence]: [f] };
                    });

                    forEach(filtersBySections, (section) => {
                        filtersBySequence = {
                            ...filtersBySequence,
                            [section[0].sequence]: [...checkNested(filtersBySequence, section[0].sequence, []), section]
                        };
                    });
                    const otherBlock = _filter(filtersBySections.undefined, o => o.filter.displayType > 0 && o.filter.id !== WINTER_BEATER_FILTER_ID);
                    filtersBySequence = { ...filtersBySequence, other: size(otherBlock) > 0 ? otherBlock : undefined };


                    dispatch({
                        type: GET_CATEGORY_FILTERS,
                        payload: {
                            ...checkNested(data, 'content', {}),
                            filterToUseInList: filtersBySequence,
                            filtersById: keyBy(checkNested(filtersResponse, 'categoryFilters', {}), 'filter.id'),
                            filterSortOptions: filtersSort
                        },
                        req
                    });

                    dispatch(
                        getCategoryFiltersById(
                            id,
                            {
                                ...filtersResponse,
                                filterToUseInList: filtersBySequence,
                                filtersById: keyBy(checkNested(filtersResponse, 'categoryFilters', {}), 'filter.id'),
                                filterSortOptions: filtersSort
                            }
                        )
                    );
                    if (onSuccess) {
                        onSuccess();
                    }
                });
            },
            label: GET_CATEGORY_FILTERS
        }
    ));
};
