import { GET_XML_DOCS } from 'ACTIONS';
import omit from 'lodash/omit';

const INITIAL_STATE = {
    docs: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_XML_DOCS:
            const docs = omit(action.payload.data.lot_list[0], 'type');
            return { ...state,
                docs: {
                    category: action.payload.category,
                    data: {
                        lot_list: [
                            { ...docs, id: '1' },
                            { ...docs, id: '2' }
                        ]
                    }
                }
            };
        default:
            return state;
    }
}
