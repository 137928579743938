import { getFilterValue } from 'MODULES/getFilterValue';
import { FILTER_DISPLAY_TYPE } from 'CONST';

export const drawFilterPreview = (filters, i18n, drawOnlyRequired) => filters.reduce((accumulator, filter, id) => {
    let val = getFilterValue(filter);
    if (drawOnlyRequired && (filter.visibilityType !== 1 || !filter.isRequired)) {
        return accumulator;
    }
    if (filter.displayType !== FILTER_DISPLAY_TYPE.mot && !val) {
        val = i18n.__('No mot');
    }
    if ((id === 0 || id > 0 || filter.value) && val) {
        return [...accumulator, { val, id: filter.filter.id, filter }];
    }
    return accumulator;
}, []);
