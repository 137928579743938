import React from 'react';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { checkNested } from 'MODULES/checkNested';

const TyreIcon = ({ filters, category }) => {
    const labelDisplay = [];
    // 68 filter Width
    // 69 Height
    // 72 Load Index
    const filtersById = keyBy(filters, 'filter.id');
    const manufacturer = checkNested(filtersById, '53.value.displayValue');
    const model = checkNested(filtersById, '30.textValue');
    const lang = useSelector(getSelectedLanguage);
    labelDisplay.push(`${checkNested(filtersById, '68.value.displayValue', '')}/${checkNested(filtersById, '69.value.displayValue', '')} ${category[lang]} ${checkNested(filtersById, '72.value.displayValue', '')}${checkNested(filtersById, '71.value.displayValue', '')}`);
    return [
        <div className="w-100" key="filters-string">
            <i className="pp-category pp-zcat_transport_tire me-0" />
            {labelDisplay}
        </div>,
        <div className="w-100" key="manufacturer">
            {(manufacturer || model) && <i className="pp-landing pp-filter_brand me-0" />}
            <span className="text-uppercase">{manufacturer}</span>
            {' '}
            {model}
        </div>
    ];
};

export default TyreIcon;
