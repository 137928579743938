import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const PagingItem = ({ page, activePage, href }) => {
    const selectedPage = Number(page) === Number(activePage);
    return (
        <li className={
            classNames(
                'page-item',
                {
                    active: Number(page) === Number(activePage)
                }
            )
        }
        >
            {selectedPage ? <span className="page-link cursor-default">{page}</span> : <Link to={href} className="page-link">{page}</Link>}
        </li>
    );
};

export default PagingItem;
