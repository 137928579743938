import { GET_PPS_LIST_BY_ID } from 'ACTIONS';

export const setPpsListById = payload => (dispatch, getState) => {
    const state = getState();
    return dispatch({
        type: GET_PPS_LIST_BY_ID,
        payload,
        bookmarkIds: state.bookmarks.bookmarkIds
    });
};
