import React from 'react';
import { AmountLoader } from 'COMMON/loader';
import omit from 'lodash/omit';
import { pickFilterValues } from 'MODULES/filters';
import qs from 'qs';
import { useSelector } from 'react-redux';
import {
    ppListCountSelector
} from 'MODULES/reselect/selectors/pp_list';
import { useLocation } from 'react-router';


const BreadcrumbLotCount = ({ initialFilter, ppType }) => {
    const location = useLocation();


    let initialSearch;

    if (initialFilter && !location.search) {
        const mergedValues = omit(initialFilter, ['sort']);
        const queryParams = pickFilterValues(mergedValues);
        initialSearch = qs.stringify(queryParams, { addQueryPrefix: true });
    }

    const ppCount = useSelector(state => ppListCountSelector(state, ppType, location.pathname, initialSearch || location.search));

    return (
        <li className="amount">
            <span
                id="breadcrumb-pp-amount"
                className="title-pp-amount"
                data-test="breadcrumb-count"
            >
                (
                {' '}
                {(ppCount || ppCount === 0) ? ppCount : AmountLoader}
                {' '}
                )
            </span>
        </li>
    );
};

export default BreadcrumbLotCount;
