import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { USER_ACTIVE_PACKAGES } from 'ACTIONS';
import apiSerialize from 'MODULES/apiSerializer';
import { prepareSimplePayload } from 'MODULES/api';

export const getUserPackages = expired => apiAction(
    {
        url: expired ? API_URLS.USER_PACKAGES_EXPIRED : API_URLS.USER_PACKAGES,
        auth: true,
        onSuccess: data => (
            {
                type: USER_ACTIVE_PACKAGES,
                payload: apiSerialize(prepareSimplePayload(data.content), USER_ACTIVE_PACKAGES)
            }
        ),
        label: USER_ACTIVE_PACKAGES
    }
);
