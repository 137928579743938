import {
    GET_COMPARE_COUNT,
    ADD_LOT_COMPARE,
    DELETE_LOT_COMPARE,
    SET_COMPARE_LIST,
    REMOVE_LOT_FROM_COMPARE_LIST
} from 'ACTIONS';

const INITIAL_STATE = {
    compareCount: 0,
    compareIds: [],
    compareList: [],
    isFetching: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COMPARE_COUNT:
            return {
                ...state,
                compareCount: action.payload.count,
                compareIds: action.payload.data
            };
        case ADD_LOT_COMPARE:
            return {
                ...state,
                compareIds: [
                    ...state.compareIds,
                    { id: action.payload.id }
                ],
                compareCount: state.compareCount + 1

            };
        case SET_COMPARE_LIST:
            return {
                ...state,
                compareList: [
                    ...action.payload
                ]
            };
        case REMOVE_LOT_FROM_COMPARE_LIST:
            return {
                ...state,
                compareList: {
                    ...state.compareList,
                    [action.listIndex]: {
                        ...state.compareList[action.listIndex],
                        comparison: {
                            ...state.compareList[action.listIndex].comparison,
                            lots: state.compareList[action.listIndex].comparison.lots.filter(lot => lot.id !== action.id)
                        }
                    }
                }
            };
        case DELETE_LOT_COMPARE:
            return {
                ...state,
                compareCount: state.compareCount - 1,
                compareIds: state.compareIds.filter(compare => compare.id !== action.payload.id)
            };
        default:
            return state;
    }
}
