import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const NextPage = ({ i18n, href }) => (
    <li className="page-item">
        <Link className="page-link btn-sm btn btn-primary" style={{ minWidth: isMobile ? '2.5rem' : '', padding: isMobile ? '0rem' : '' }} to={href}>
            {!isMobile && (
                i18n.__('Next page')
            )}
            {' '}
            <i className="pp-landing pp-angle-right" style={{ transform: isMobile ? 'translateX(-3px)' : '' }} />
        </Link>
    </li>
);

export default NextPage;
