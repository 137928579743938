import { getPps } from 'ACTIONS/categories/getPps';
import { API_URLS } from 'CONFIG/API_URLS';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';

export const getRecentlyAddedClassifieds = (path, category, regionSelected) => dispatch => dispatch(getPps(
    path,
    category ? API_URLS.RECENLTY_ADDED(category, regionSelected) : API_URLS.RECENLTY_ADDED_ALL,
    PP_TYPE.recently_added,
    null,
    { sort: SORT_OPTIONS.publishDateDESC.name },
    1
));
