import { checkNested } from 'MODULES/checkNested';

export const getAddressTextFromGeo = (data) => {
    let searchResult = '';

    let next = data.region && data.region.parent;
    const parents = [];
    while (next) {
        parents.push(next.name);
        next = next.parent;
    }

    if (parents.length > 0) {
        searchResult = `${parents.reverse().join(', ')}, `;
    }


    if (data.region) {
        searchResult += data.region.name;
    }

    if (data.street) {
        searchResult += `, ${data.street.name}`;
    }

    if (data.number) {
        searchResult += ` ${data.number}`;
    }

    if (checkNested(data.building, 'number')) {
        searchResult += ` ${data.building.number}`;
    }


    return searchResult;
};
