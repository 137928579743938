import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MARK_LOT_AS_SEEN, REMOVE_FILTER_COUNT, REMOVE_LOT_UNSEEN_LIST } from 'ACTIONS';
import { UNSEEN_LOT_COUNT } from 'CONST';
import isObject from 'lodash/isObject';

export const patchFilterSubscriptionLotAsSeen = (hash, lot) => dispatch => dispatch(apiAction({
    url: API_URLS.FILTER_SUBSCRIPTIONS_LOTS_SEEN(hash, isObject(lot) ? lot.id : lot),
    method: 'PATCH',
    auth: true,
    onSuccess: () => () => {
        dispatch({
            type: MARK_LOT_AS_SEEN,
            hash,
            lot: isObject(lot) ? lot.id : lot
        });
        dispatch({
            type: REMOVE_FILTER_COUNT,
            payload: UNSEEN_LOT_COUNT
        });
        if (!isObject(lot)) {
            dispatch({
                type: REMOVE_LOT_UNSEEN_LIST,
                lotId: lot
            });
        }
    }
}));
