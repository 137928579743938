import { catIcons } from 'MODULES/catIcons';

export function categoryClass(category, level) {
    const findId = id => (
        Object.keys(catIcons).find(elm => elm === id.toString())
    );
    const categoryId = findId.bind(this)(category.rootCategoryId ? category.rootCategoryId : category.id ? category.id : category, catIcons);
    const fontSet = level === 0 ? 'pp-landing' : 'pp-category';
    return categoryId ? `${fontSet} ${catIcons[categoryId]}` : null;
}
