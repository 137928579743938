import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import FilterBy from 'COMPONENTS/filters/filter_by';
import DiscountRange from 'COMPONENTS/dynamic-form/widgets/discount_range';


const filtersParams = { actionSelected: 5 };

const DiscountProductsFilters = reduxForm({
    form: 'discount_filters'
})(({ i18n }) => (
    <Form>
        <Field
            name="discountValue"
            filters={filtersParams}
            component={DiscountRange}
            hideSelect
            i18n={i18n}
        />
        <Field
            name="ownerType"
            component={FilterBy}
            i18n={i18n}
        />
    </Form>
));


export default DiscountProductsFilters;


