import React, { useCallback, useState } from 'react';
import NextPage from 'COMPONENTS/paging/next';
import PrevPage from 'COMPONENTS/paging/prev';
import PagingItem from 'COMPONENTS/paging/pagingItem';
import Paginator from 'paginator';
import qs from 'qs';
import { CLASSIFIEDS_PER_PAGE } from 'CONFIG/index';
import { isMobile } from 'react-device-detect';
import { KEYBOARD_CODES } from 'CONST';
import { checkNested } from 'MODULES/checkNested';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { useHistory } from 'react-router';

const Paging = ({ pageCount, activePage, i18n, perPage, totalCount, onPageChange, location }) => {
    const history = useHistory();
    const [inputPage, setInputPage] = useState('');
    const [openPageCount] = useState(4);
    const [openPageCountMobile] = useState(3);
    const [exceptionPageCount] = useState(5);
    const [maxPageCountInScreen] = useState(8);

    const handleInputChange = (e) => {
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        setInputPage(numericValue);
    };
    const goToInputPage = useCallback(() => {
        // eslint-disable-next-line camelcase
        const { total_pages } = buildPages();
        if (inputPage && !isNaN(inputPage)) {
            let page = parseInt(inputPage, 10);
            page = Math.max(1, page);
            page = Math.min(page, total_pages);
            switchPage(page);
            setInputPage('');
        }
    }, [inputPage]);

    const handleKeyPress = (event) => {
        if (event.keyCode === KEYBOARD_CODES.enter) {
            goToInputPage();
        }
    };

    const getHref = (page) => {
        if (location) {
            const search = getParamsFromSearch(checkNested(location, 'search', {}));
            search.page = page;
            return `?${qs.stringify(search, {
                arrayLimit: 0,
                arrayFormat: 'brackets',
                ignoreQueryPrefix: true
            })}`;
        }
        return '#';
    };

    const getPages = useCallback((from, to) => {
        const pages = [];
        const pagingInfo = buildPages();
        if ((!isMobile && (pagingInfo.total_pages === exceptionPageCount ? activePage > 2 : activePage > openPageCountMobile) && pagingInfo.total_pages !== openPageCount) || (isMobile && activePage > 2 && pagingInfo.total_pages !== openPageCountMobile)) {
            pages.push(<PagingItem key={1} page={1} activePage={activePage} href={getHref(1)} />);

            const pagingItemEllipsis = !isMobile && pagingInfo.total_pages - activePage > 2 && (pagingInfo.current_page !== openPageCount || pagingInfo.total_pages < maxPageCountInScreen);
            const pagingInputField = (isMobile && activePage > 2 && pagingInfo.total_pages !== openPageCount && pagingInfo.current_page !== openPageCountMobile) || (!isMobile && pagingInfo.total_pages - activePage <= 2 && pagingInfo.total_pages !== (openPageCount && exceptionPageCount));
            if (pagingItemEllipsis) {
                pages.push(<PagingItem key="..." page="..." href={getHref(activePage)} />);
            } else if (pagingInputField) {
                pages.push(
                    <li className="input-paging" key="input">
                        <input
                            type="text"
                            className="input-field btn-sm btn btn-secondary"
                            value={inputPage}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyPress}
                            placeholder="..."
                        />
                    </li>
                );
            }
        }
        for (let i = from; i <= to; i++) {
            pages.push(<PagingItem
                key={i}
                page={i}
                activePage={activePage}
                href={getHref(i)}
            />);
        }
        if ((pagingInfo.total_pages - activePage > 3 && pagingInfo.total_pages !== (openPageCount && exceptionPageCount)) && !isMobile) {
            pages.push(
                <li className="input-paging" key="input-end">
                    <input
                        type="text"
                        className="input-field btn-sm btn btn-secondary"
                        value={inputPage}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        placeholder="..."
                    />
                </li>
            );
        }

        if ((pagingInfo.total_pages - activePage > 2 && pagingInfo.total_pages !== openPageCount) && !isMobile) {
            pages.push(<PagingItem key={pagingInfo.total_pages} page={pagingInfo.total_pages} activePage={activePage} href={getHref(pagingInfo.total_pages)} />);
        }
        return pages;
    }, [activePage, exceptionPageCount, openPageCount, openPageCountMobile, maxPageCountInScreen, inputPage, getHref]);

    const buildPages = useCallback(() => {
        const pageCount = isMobile ? openPageCountMobile : openPageCount;
        return new Paginator(perPage, pageCount).build(totalCount, activePage);
    }, [perPage, activePage, openPageCount, openPageCountMobile, totalCount]);

    const switchPage = useCallback((page) => {
        if (history && location) {
            const search = getParamsFromSearch(checkNested(location, 'search', {}));
            search.page = page;
            history.push({ search: qs.stringify(search) });
            onPageChange(page);
        } else {
            onPageChange(page);
        }
    }, [history, location, onPageChange]);

    if (pageCount <= 1) {
        return false;
    }
    const pagingInfo = buildPages();
    const pages = getPages(pagingInfo.first_page, pagingInfo.last_page);

    return (
        <div className="paging">
            <ul className="pagination">
                {activePage > 1 && <PrevPage href={getHref(Number(activePage) - 1)} />}
                {pages}
                {activePage < pagingInfo.total_pages && <NextPage i18n={i18n} href={getHref(Number(activePage) + 1)} />}
            </ul>
        </div>
    );
};

Paging.defaultProps = {
    activePage: 1,
    perPage: CLASSIFIEDS_PER_PAGE
};

export default Paging;
