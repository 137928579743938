import { checkNested } from 'MODULES/checkNested';
import union from 'lodash/union';

export const storeCategoryRegionBreadcrumb = (currentCategory, regionData) => {
    const breadcrumb = [...checkNested(currentCategory, 'parents', {}), {
        level: 10,
        name: checkNested(regionData, 'parent.name', regionData.name),
        id: checkNested(regionData, 'parent.id', regionData.id),
        search: `region=${checkNested(regionData, 'parent.id', regionData.id)}`,
        navigateToCategory: true,
        slug: checkNested(regionData, 'parent.slug', currentCategory.slug),
        regionSlug: currentCategory.slug
    }];

    if (checkNested(regionData, 'parent')) {
        const childBreadcrumb = [{
            level: 11,
            name: regionData.name,
            id: regionData.id,
            search: `region=${regionData.id}`,
            navigateToCategory: true,
            slug: currentCategory.slug
        }];

        return union([...breadcrumb], [...childBreadcrumb]);
    }

    return breadcrumb;
};
