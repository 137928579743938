import { createSelector } from 'reselect';
import omit from 'lodash/omit';
import qs from 'qs';
import { pickFilterValues } from 'MODULES/filters';
import Storage from 'HOC/storage';
import { countState } from 'MODULES/reselect/state';
import get from 'lodash/get';
import { classifiedsPath } from 'MODULES/reselect/selectors/classified';
import { getCategorySlugsByLink } from 'MODULES/reselect/selectors/categories';
import { checkNested } from 'MODULES/checkNested';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';

const emptyObject = {};

export const ppListQueryStringSelector = createSelector(
    (state, ppType, pathName, search, queryParam, regionId, page) => {
        let searchParams = pickFilterValues(getParamsFromSearch(search));
        if (ppType === PP_TYPE.list && search && !search.includes('sort')) {
            searchParams = { ...searchParams, ...SORT_OPTIONS.latestPersonalDESC.urlParam };
        }
        let identifier;

        if (ppType === PP_TYPE.bookmarks) {
            identifier = Storage.get('watch');
        }
        if (page) {
            return qs.stringify({ ...searchParams, query: queryParam, identifier, currentPage: page, region: regionId || searchParams.region }, { arrayFormat: 'brackets' }) || '';
        }
        return qs.stringify({ ...searchParams, query: queryParam, identifier, region: regionId || searchParams.region }, { arrayFormat: 'brackets' }) || '';
    },
    queryStrings => queryStrings
);

export const ppListGetActivePageSelector = createSelector(
    search => search,
    search => getParamsFromSearch(search).currentPage || getParamsFromSearch(search).page || 1
);

export const ppListQueryStringSelectorWithoutCoordinates = createSelector(
    (state, ppType, pathName, search, queryParam, regionId, page) => {
        const searchParams = omit(pickFilterValues(getParamsFromSearch(search)), 'area');
        let identifier;

        if (ppType === PP_TYPE.bookmarks) {
            identifier = Storage.get('watch');
        }
        if (page) {
            return qs.stringify({ ...searchParams, query: queryParam, identifier, currentPage: page, region: regionId || searchParams.region }, { arrayFormat: 'brackets' }) || '';
        }
        return qs.stringify({ ...searchParams, query: queryParam, identifier, region: regionId || searchParams.region }, { arrayFormat: 'brackets' }) || '';
    },
    queryStrings => queryStrings
);

export const ppListQueryStringSelectorWithoutCoordinatesWithoutPaging = createSelector(
    (state, ppType, pathName, search, queryParam, regionId) => {
        const searchParams = omit(pickFilterValues(getParamsFromSearch(search)), ['area', 'page']);
        let identifier;

        if (ppType === PP_TYPE.bookmarks) {
            identifier = Storage.get('watch');
        }
        // if (page) {
        //     return qs.stringify({ ...searchParams, query: queryParam, identifier, currentPage: page, region: regionId || searchParams.region }, { arrayFormat: 'brackets' }) || '';
        // }
        return qs.stringify({ ...searchParams, query: queryParam, identifier, region: regionId || searchParams.region }, { arrayFormat: 'brackets' }) || '';
    },
    queryStrings => queryStrings
);


export const ppListFilterValuesSelector = createSelector(
    state => state.ppList.filterValues,
    (state, ppType) => ppType,
    (state, ppType, pathname) => pathname,

    (state, ppType, pathname, omitValues) => omitValues,
    (filterValues, ppType, pathName, omitValues) => (omitValues ? omit(checkNested(filterValues, [ppType, pathName], {}), omitValues) : checkNested(filterValues, [ppType, pathName], {}))

);
// pList.ppList.mapList["/lv/modal-map/category/nekustamais-ipasums/dzivokli"]["action=1&area%5BleftBottom%5D%5BcoordinateX%5D=54.04&area%5BleftBottom%5D%5BcoordinateY%5D=20.895&area%5BrightTop%5D%5BcoordinateX%5D=59.635&area%5BrightTop%5D%5BcoordinateY%5D=28.016"]

export const ppListListSelector = createSelector(
    state => state.ppList.ppList,
    (state, ppType) => ppType,
    (state, ppType, pathname) => pathname,
    ppListQueryStringSelector,
    ppListQueryStringSelectorWithoutCoordinatesWithoutPaging,
    (ppList, ppType, pathName, queryString, queryStringWithoutCoordinates) => {
        if (ppType === PP_TYPE.mapList) {
            return checkNested(ppList, [ppType, pathName, queryStringWithoutCoordinates], false);
        }

        return checkNested(ppList, [ppType, pathName, queryString], false);
    }
);

export const ppListCountSelector = createSelector(
    state => state.ppList.ppCount,
    (state, ppType) => ppType,
    (state, ppType, pathname) => pathname,
    ppListQueryStringSelector,
    (ppCount, ppType, pathName, queryString) => (
        checkNested(ppCount, [ppType, pathName, queryString], false)
    )
);


export const ppListCountSelector2 = createSelector(
    state => state.ppList.ppCount,
    (state, ppType) => ppType,
    (state, ppType, pathname) => pathname,
    ppListQueryStringSelector,
    (ppCount, ppType, pathName) => (
        checkNested(ppCount, [ppType, pathName], false)
    )
);


export const getClassifiedCountPpList = createSelector(
    [
        classifiedsPath,
        countState
    ],
    (path, count) => get(count, path)
);


export const ppListPpDataSelector = createSelector(
    state => state.categories.pp,
    (state, pp) => pp,
    (list, pp) => (
        checkNested(list, [pp], emptyObject)
    )
);

export const ppListRegionByPath = createSelector(
    state => state.ppList.regions,
    getCategorySlugsByLink,
    state => state.general.selectedLang,
    (regions, slug, lang) => checkNested(regions, [slug[lang]])
);

export const ppListRegionByIdSelector = createSelector(
    state => state.ppList.regionsById,
    regions => regions
);

export const ppListRegionIdBySlugSelector = createSelector(
    [
        state => state.ppList.regionsBySlug,
        (state, region) => region
    ],
    (regions, region) => checkNested(regions, [region, 'id'])
);


export const ppListPpsByCoordinatesSelector = createSelector(
    state => state.ppList.ppsByCoordinates,
    ppsByCoordinates => ppsByCoordinates
);


export const ppCompareListCountSelector = createSelector(
    state => state.compare.compareCount,
    count => count
);

export const ppCompareListIdsSelector = createSelector(
    state => state.compare.compareIds,
    idList => map(idList, i => i.id)
);

export const ppCompareListSelector = createSelector(
    state => state.compare.compareList,
    ppCompareList => ppCompareList
);


export const ppCompareIdsSelector = createSelector(
    state => state.compare.compareIds,
    compareIds => keyBy(compareIds, 'id')
);

