import React, { useEffect } from 'react';
import { BIG_BANNER_ID, BIG_BANNER_MOBILE_ID } from 'COMPONENTS/banners/bannerConfig';
import { loadBanner } from 'COMPONENTS/banners/bannerLoad';
import { isMobile } from 'react-device-detect';

const BannerTop = () => {
    useEffect(() => {
        loadBanner(isMobile ? BIG_BANNER_MOBILE_ID : BIG_BANNER_ID);
    }, [0]);
    return (
        <div id={isMobile ? BIG_BANNER_MOBILE_ID : BIG_BANNER_ID} className="container mb-2" style={{ display: 'none' }} />
    );
};

export default BannerTop;
