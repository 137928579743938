import React, { Fragment, useEffect } from 'react';
import size from 'lodash/size';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import HeaderRight from 'COMPONENTS/header/header_right';
import HeaderMiddle from 'COMPONENTS/header/header_middle';
import HeaderMSelect from 'COMPONENTS/header/header_m_select';
import QrHeader from 'COMPONENTS/header/header_qr';
import Logo from 'COMPONENTS/header/logo';
import { lazyRetry, loadComponent } from 'MODULES/lazyRetry';

import { stringExistsIn } from 'MODULES/validations';
import { useHistory, useLocation } from 'react-router';
import { SET_PP_DATA } from 'ACTIONS';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { mSelectState } from 'MODULES/reselect/state';
import { mapDispatchToProps } from 'MODULES/reselect/header';
import { isUserLoggedIn } from 'MODULES/reselect/selectors/user';
import { checkNested } from 'MODULES/checkNested';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import BannerTop from 'COMPONENTS/banners/banner_top';


const HeaderCreate = loadComponent(() => lazyRetry(() => import('COMPONENTS/header/header_create')));

const MainHeader = ({ functions, i18n }) => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useSelector(state => state.ui.isMobile);
    const isLoggedIn = useSelector(isUserLoggedIn);
    const lang = useSelector(getSelectedLanguage);
    const isFetchingLotEdit = useSelector(state => state.ui.isFetching[SET_PP_DATA]);
    // const mSelect = useSelector(state => getMSelect(state, { location }));
    const mSelectItemsSelected = useSelector(mSelectState);
    const showAutocomplete = useSelector(state => state.search.showAutocomplete);


    useEffect(() => () => { functions.emptyMSelect(); }, []);
    if (stringExistsIn('/qr/', location.pathname)) {
        return <QrHeader />;
    }


    const pp = checkNested(location.state, 'pp', {});
    const lotVisibility = checkNested(pp, 'adState.visibility');


    const editDraft = !isFetchingLotEdit && stringExistsIn('my/edit', location.pathname) && (lotVisibility === LOT_VISIBILITY.closed.id || lotVisibility === LOT_VISIBILITY.draft.id);
    const isCreate = isLoggedIn && (location.pathname === PP_URLS.CREATE || editDraft);

    const className = classNames(
        'd-print-none container-fluid top-header-container',
        {
            'ac-open': showAutocomplete,
            'lot-create': isCreate || size(mSelectItemsSelected)
        }
    );

    const linkClassName = classNames('', {
        'title-main': !isCreate && !size(mSelectItemsSelected)
    });

    return (
        <>
            <div className={className}>
                <div className="container" data-test="toolbar-container">
                    <div className="row">
                        <div className="col-12 d-flex ps-0">
                            {!size(mSelectItemsSelected) && (
                                <Logo
                                    linkClassName={linkClassName}
                                    isCreate={isCreate}
                                />
                            )}
                            <div className="header-middle-wrapper" data-test="toolbar-classified-container">
                                {!isCreate && !size(mSelectItemsSelected) && (
                                    <HeaderMiddle
                                        i18n={i18n}
                                        showAutocomplete={showAutocomplete}
                                        history={history}
                                        lang={lang}
                                        {...functions}
                                    />
                                )}
                                {isCreate
                            && <HeaderCreate showActiveTitle={isMobile} i18n={i18n} />}
                                {size(mSelectItemsSelected) > 0 && (
                                    <HeaderMSelect
                                        location={location}
                                        i18n={i18n}
                                        {...functions}
                                    />
                                )}
                                {!size(mSelectItemsSelected)
                            && (
                                <HeaderRight
                                    mSelect={mSelectItemsSelected}
                                    isCreate={isCreate}
                                    i18n={i18n}
                                    lang={lang}
                                    {...functions}
                                />
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile && isCreate
                && <HeaderCreate isMobile i18n={i18n} />}
            </div>
            <BannerTop />
        </>
    );
};

export default connect(null, mapDispatchToProps)(MainHeader);
