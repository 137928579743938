import {
    API_START, API_END
} from 'ACTIONS';

import { setIn } from 'MODULES/setIn';

const INITIAL_STATE = {
    isFetching: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case API_START:
            return { ...state, isFetching: setIn(action.dispatcher, true, state.isFetching) };
        case API_END:
            return { ...state, isFetching: setIn(action.dispatcher, false, state.isFetching) };
        default:
            return state;
    }
}
