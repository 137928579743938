import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import { PP_TYPE } from 'MODULES/PP_TYPE';

export const getSortedName = (sorted, type) => {
    if (sorted) {
        return sorted;
    }
    if (type === PP_TYPE.list || type === PP_TYPE.publicCompany) {
        return SORT_OPTIONS.publishDateDESC.name;
    }
    if (type === PP_TYPE.bookmarks) {
        return SORT_OPTIONS.createDateDESCBookmarks.name;
    }
    if (type === PP_TYPE.search) {
        return SORT_OPTIONS.bestMatch.name;
    }
    return SORT_OPTIONS.createDateDESC.name;
};
