import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AmountLoader } from 'COMMON/loader';

const defaultProps = {
    icon: 'pp-ico pp-floppy-o',
    primary: true,
    additionalClass: '',
    type: 'submit'
};

class Button extends PureComponent {
    render() {
        const { small, target, hideTextOnMobile, to, href, type, icon, pullright, title, primary, secondary, submiting, onClick, fullWidth, additionalClass, disabled, enabledOnDirty, disableOnDirty, dirtyForm, showLoader, id, mb1, mx1, mt4, fontSet } = this.props;
        const className = classNames(`btn ${additionalClass}`, {
            'btn-primary': primary && !secondary,
            'btn-secondary': secondary,
            'w-100': fullWidth,
            'float-end': pullright,
            'btn-sm': small,
            'mb-1': mb1,
            'mx-1': mx1,
            'mt-4': mt4
        });
        const spanClassName = classNames('', {
            'd-none': hideTextOnMobile,
            'd-md-inline': hideTextOnMobile
        });

        if (to) {
            return (
                <Link
                    to={to}
                    className={className}
                >
                    <i className={`${fontSet || 'pp-landing'} ${icon} pe-0-sm`} aria-hidden="true" />
                    {' '}
                    {title}
                </Link>
            );
        }

        if (href) {
            return (
                <a
                    className={className}
                    href={href}
                    onClick={onClick}
                    target={target}
                >
                    {title}
                </a>
            );
        }

        if (showLoader) {
            return (
                <div className={className}>
                    {AmountLoader}
                </div>
            );
        }

        return (
            <button
                type={type}
                className={className}
                disabled={submiting || disabled || (disableOnDirty && dirtyForm) || (enabledOnDirty && !dirtyForm)}
                onClick={onClick}
                id={id}
                data-test={id ? `button_${id}` : undefined}
                aria-label={title}
            >
                <i className={`${fontSet || 'pp-landing'} ${icon}`} aria-hidden="true" />
                {' '}
                <span className={spanClassName}>{title}</span>
            </button>
        );
    }
}

Button.defaultProps = defaultProps;

export default Button;

