export const showMsg = (type, dispatch, id, content, interval) => {
    const handleAlert = status => (
        dispatch({
            type,
            id,
            content,
            status
        })
    );

    if (!content) {
        handleAlert(false);
    } else {
        handleAlert(true);
        if (interval) {
            setTimeout(() => {
                handleAlert(false);
            }, interval);
        }
    }
};
