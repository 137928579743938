import { FILTER_DISPLAY_TYPE } from 'CONST';

export const getFilterValue = (filter) => {
    let val = filter.textValue;
    if (val === null || val === undefined) {
        val = filter.value.displayValue;
    }
    if (filter.filter.displayType === FILTER_DISPLAY_TYPE.mot && val) {
        // val = Moment(filter.textValue, 'YYYY-MM-DD').format(DATE_DISPLAY_FORMAT);
    }
    return val;
};
