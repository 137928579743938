import {
    CLEAR_FEED_MAPPED_CATEGORY,
    CLEAR_FEED_SEARCH_SUGGEST,
    DELETE_FEED_BY_ID,
    DISABLE_FEED_BY_ID,
    ENABLE_FEED_BY_ID,
    GET_FEED_BY_ID,
    GET_FEED_LIST,
    GET_FEED_LOG,
    GET_FEED_MAPPED_CATEGORIES, GET_FEED_SEARCH_CATEGORIES,
    GET_FEED_SUGGESTED_CATEGORIES,
    GET_FEED_USER
} from 'ACTIONS';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';

import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = {
    list: {},
    members: {},
    logs: {},
    feedCategory: {},
    feedSearchedCategories: {},
    feedSuggestedCategory: {},
    feedMappingCount: { mappedCount: 0, unMappedCount: 0 },
    isParsingFeed: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_FEED_LIST:
            let isParsingFeed = false;
            const feedValues = values(action.payload);
            if (!isEmpty(feedValues)) {
                forEach(feedValues[0], (feed) => {
                    if (feed.newestXmlFileProgress.total > feed.newestXmlFileProgress.processed) {
                        isParsingFeed = true;
                    }
                });
            }
            return {
                ...state,
                list: action.payload,
                isParsingFeed
            };
        case GET_FEED_BY_ID:
            let mappedCategoryCount = 0;
            let unMappedCategoryCount = 0;
            if (checkNested(action.payload, 'mapping')) {
                forEach(action.payload.mapping, mapping => (checkNested(mapping.category, 'id') ? mappedCategoryCount += 1 : unMappedCategoryCount += 1));
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.owner.id]: {
                        ...checkNested(state.list, action.payload.owner.id, {}),
                        [action.id]: action.payload
                    }
                },
                feedMappingCount: {
                    ...state.feedMappingCount,
                    [action.id]: {
                        ...state.feedMappingCount.mappedCount,
                        mappedCount: mappedCategoryCount,
                        ...state.feedMappingCount.unMappedCount,
                        unMappedCount: unMappedCategoryCount
                    }
                }
            };
        case DELETE_FEED_BY_ID:
            return {
                ...state,
                list: { ...state.list, [action.owner]: omit(checkNested(state.list, action.owner, {}), action.id) }
            };
        case DISABLE_FEED_BY_ID:
            return {
                ...state,
                list: { ...state.list,
                    [action.owner]: { ...checkNested(state.list, action.owner, {}),
                        [action.id]:
                            { ...checkNested(state.list, [action.owner, action.id], {}), autoParse: false } }
                }
            };
        case ENABLE_FEED_BY_ID:
            return {
                ...state,
                list: { ...state.list,
                    [action.owner]: { ...checkNested(state.list, action.owner, {}),
                        [action.id]:
                            { ...checkNested(state.list, [action.owner, action.id], {}), autoParse: true } }
                }
            };
        case GET_FEED_USER:
            return {
                ...state,
                members: { ...state.members, [action.payload.id]: action.payload }
            };
        case GET_FEED_LOG:
            return {
                ...state,
                logs: { ...state.logs, [action.feed]: action.payload }
            };
        case GET_FEED_MAPPED_CATEGORIES:
            return {
                ...state,
                feedCategory: { ...state.feedCategory, [action.id]: action.payload.content }
            };
        case GET_FEED_SUGGESTED_CATEGORIES:
            return {
                ...state,
                feedSuggestedCategory: {
                    ...state.feedSuggestedCategory,
                    [action.feed]: {
                        ...checkNested(state.feedSuggestedCategory, [action.feed], {}),
                        [action.mapOrder]: {
                            ...checkNested(state.feedSuggestedCategory, [action.feed, action.mapOrder], {}),
                            [action.catOrder]: action.payload.content
                        }
                    }
                }
            };
        case GET_FEED_SEARCH_CATEGORIES:
            return {
                ...state,
                feedSearchedCategories: action.payload
            };
        case CLEAR_FEED_MAPPED_CATEGORY:
            return {
                ...state,
                feedCategory: omit(state.feedCategory, action.payload)
            };
        case CLEAR_FEED_SEARCH_SUGGEST:
            return {
                ...state,
                feedSearchedCategories: INITIAL_STATE.feedSearchedCategories
            };
        default:
            return state;
    }
}
