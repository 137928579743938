import { setListFilterValues } from 'ACTIONS/pp_list/setListFilterValues';
import { setListQueryString } from 'ACTIONS/pp_list/setListQueryString';
import qs from 'qs';
import { FILTER_WHITELIST_PARAMS } from 'CONFIG/FILTER_WHITELIST_PARAMS';
import { setListQueryStringWithoutCoordinates } from 'ACTIONS/pp_list/setListQueryStringWithoutCoordinates';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import pick from 'lodash/pick';

export const setListFilters = (filters, location, ppType) => (dispatch) => {
    dispatch(setListFilterValues(filters, location, ppType));
    dispatch(setListQueryString(qs.stringify(pick(filters, FILTER_WHITELIST_PARAMS), {
        addQueryPrefix: true,
        arrayFormat: 'brackets'
    }), location, ppType));
    dispatch(setListQueryStringWithoutCoordinates(qs.stringify(pick(filters, FILTER_WHITELIST_PARAMS.filter(c => c !== 'area')), {
        addQueryPrefix: true,
        arrayFormat: 'brackets'
    }), location, PP_TYPE.mapList));
};
