import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const PrevPage = ({ href }) => (
    <li className="page-item" key="first">
        <Link className="page-link btn-sm btn btn-secondary" style={{ minWidth: isMobile ? '2.5rem' : '' }} to={href}>
            <i className="pp-landing pp-angle-left pe-2" style={{ transform: isMobile ? 'none' : 'translate(5px)' }} />
        </Link>
    </li>
);

export default PrevPage;
