import React, { Suspense } from 'react';
import { lazyRetry, loadComponent } from 'MODULES/lazyRetry';
import { Loader } from 'COMMON/loader';


const HomeMobile = loadComponent(() => lazyRetry(() => import('CONTAINERS/home_mobile')));

const HomeMobileWrapper = (props) => (
    <div>
        <Suspense fallback={<div style={{ minHeight: '1156px' }}>{Loader}</div>}>
            <div style={{ minHeight: '1156px' }}>
                <HomeMobile {...props} />
            </div>
        </Suspense>
    </div>
);

export default HomeMobileWrapper;
