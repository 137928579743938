import size from 'lodash/size';

export const sortCategoriesByHighlight = (categoryLevelList, returnOnlyHighlight) => {
    const highlighted = categoryLevelList
        .filter(category => category.highlighted)
        .map(category => ({
            ...category,
            onTop: true
        }));
    // const noHighlighted = categoryLevelList.filter(category => !category.highlighted);
    const additional = [];
    if (size(highlighted) > 0) {
        additional.push({ id: 'disabled', disabled: true });
    }


    if (returnOnlyHighlight) {
        return [...highlighted];
    }
    return [...highlighted, ...additional, ...categoryLevelList];
};
