import React from 'react';

import { CLASSIFIEDS_VIEW } from 'MODULES/CLASSIFIEDS_VIEW';

export const ListButtons = ({ switchView, viewType }) => (
    <button
        onClick={() => {
            switchView((viewType === CLASSIFIEDS_VIEW.grid || viewType === CLASSIFIEDS_VIEW.clothes) ? CLASSIFIEDS_VIEW.list : CLASSIFIEDS_VIEW.grid);
        }}
        className="btn btn-action btn-sort ms-1"
        aria-label={(viewType === CLASSIFIEDS_VIEW.grid || viewType === CLASSIFIEDS_VIEW.clothes) ? 'Switch to list view' : 'Switch to grid view'}
    >
        <i
            className={`pp-ico p-0 ${(viewType === CLASSIFIEDS_VIEW.grid || viewType === CLASSIFIEDS_VIEW.clothes) ? 'pp-list' : 'pp-th'}`}
            aria-hidden="true"
        />
    </button>
);

export default ListButtons;
