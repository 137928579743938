import React, { Component } from 'react';
import qs from 'qs';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import SortButtons from 'COMMON/sort_buttons';
import ListButtons from 'COMMON/list_buttons';
import { AmountLoader } from 'COMMON/loader';
import PrevNext from 'COMPONENTS/lot/prevNext';
import ParentCategoryIcon from 'COMPONENTS/breadcrumb/category-select';
import { connect } from 'react-redux';
import { getAllFilter } from 'MODULES/reselect/selectors/filters';
import { showFullCategoryPicke } from 'MODULES/create_pp';
import { CATEGORIES_WITH_DISABLED_SORT } from 'CONFIG';
import { getClassifiedViewType } from 'MODULES/reselect/selectors/ui';
import BreadcrumbLotCount from 'COMPONENTS/breadcrumb/breadcrumb-lot-count';
import { getParent } from 'MODULES/getParent';
import { checkNested } from 'MODULES/checkNested';
import { storeCategoryRegionBreadcrumb } from 'MODULES/storeCategoryRegionBreadcrumb';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { switchView } from 'ACTIONS/ui/switchView';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { setIsToggled } from 'ACTIONS/ui/setIsToggled';
import { UI } from 'CONST';

class Breadcrumb extends Component {
    componentDidMount() {
        if (this.props.categoryData) {
            if (this.props.categoryData.categories.bySlug[this.props.match.params[0]]) {
                if (!this.props.categoryData.categories.byId[this.props.categoryData.categories.bySlug[this.props.match.params[0]]].parents) {
                    this.props.functions.buildCategoryParent(this.props.match.params[0], this.props.categoryData.categories, this.props.lang);
                }
            }
        }
    }

    componentDidUpdate() {
        if (this.props.categoryData) {
            if (this.props.categoryData.categories.bySlug[this.props.match.params[0]]) {
                if (!this.props.categoryData.categories.byId[this.props.categoryData.categories.bySlug[this.props.match.params[0]]]) {
                    this.props.functions.buildCategoryParent(this.props.match.params[0], this.props.categoryData.categories, this.props.lang);
                }
            }
        }
    }


    drawIcon = icon => this.titleIcon(icon);

    drawTitle = (title, icon) => {
        if (Array.isArray(title)) {
            const arr = map(
                title,
                t => (
                    t.link
                        ? (
                            <span className="pp-breadcrumb">
                                <Link key={t.id} id="breadcrumb-title" to={t.link}>{t.title}</Link>
                            </span>
                        )
                        : (
                            <span className="pp-breadcrumb">
                                <span className="pipe pipefix">{t.title}</span>
                            </span>
                        )
                )
            );
            return [
                <span key={icon} id="breadcrumb-title-icon" className={`category-icon ${icon}`} />,
                ...arr
            ];
        }
        return this.titleText(title);
    };

    titleIcon = icon => (
        <div className="category-icon icon">
            <i id="breadcrumb-title-icon" className={icon} />
        </div>
    );

    titleText = title => (
        <li className="d-flex align-items-center pe-0 no-hover">
            <h1 id="breadcrumb-title-text">{title}</h1>
        </li>
    );


    render() {
        const {
            titleClick,
            lotsPath,
            icon,
            title,
            link,
            amount,
            titleCustom,
            customBreadcrumb,
            hasSortButtons,
            ppType,
            categoryData,
            isFetchingAmount,
            functions,
            sortedBy,
            i18n,
            history,
            multipleCategoryString,
            locationState,
            disablePadding,
            filters,
            location,
            lang,
            pp,
            match,
            isMobile,
            viewType,
            switchView,
            toggleFilterBlock,
            showLotCount,
            initialFilter,
            myItemPage
        } = this.props;
        // This data comes from categoryData reselect and represents "parent categories" array length
        let currentCategory;
        let isGeoCategory;

        if (categoryData) {
            currentCategory = categoryData.currentCategory || checkNested(pp, 'category');
            const ppParent = getParent(checkNested(pp, 'category'), false);
            isGeoCategory = showFullCategoryPicke(checkNested(currentCategory, 'parents.0.id')) || showFullCategoryPicke(checkNested(ppParent, 'id'));
        }
        const regionData = checkNested(pp, 'publicLocation.region', {});
        if ((checkNested(regionData, 'parent') || checkNested(regionData, 'name')) && isGeoCategory) {
            const ppCategory = checkNested(pp, 'category') ? { ...pp.category, parents: getParent(pp.category, true) } : false;
            currentCategory = { ...currentCategory, parents: storeCategoryRegionBreadcrumb(ppCategory || currentCategory, regionData) };
        }
        if (currentCategory && location.search && isGeoCategory) {
            const searchObject = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (searchObject.region) {
                const { regions } = filters;
                const regionsById = keyBy([...checkNested(regions, 'parent', []), ...checkNested(regions, 'child', [])], 'id');
                let objeectRegion = [];
                const haveParent = checkNested(regionsById, [searchObject.region, 'parent']);

                const regionDataSubRegion = regionsById[searchObject.region];
                if (haveParent && regionsById[haveParent]) {
                    objeectRegion = [
                        ...objeectRegion, {
                            level: 11,
                            isRegion: true,
                            name: regionsById[haveParent].name,
                            id: regionsById[haveParent].id,
                            slug: { [lang]: currentCategory.slug[lang] },
                            search: `?region=${regionsById[haveParent].id}`
                        }
                    ];
                }
                if (regionDataSubRegion) {
                    objeectRegion = [...objeectRegion, {
                        level: 10,
                        isRegion: true,
                        navigateToCategory: !regionDataSubRegion.hasChildRegions,
                        name: regionDataSubRegion.name,
                        id: regionDataSubRegion.id,
                        slug:  { [lang]: (!regionDataSubRegion.hasChildRegions || !haveParent) ? currentCategory.slug[lang] : `${currentCategory.slug[lang]}/${regionDataSubRegion.slug}` },
                        search: !regionDataSubRegion.hasChildRegions || !haveParent ? `region=${searchObject.region}` : ''
                    }];
                }
                currentCategory = {
                    ...currentCategory,
                    name: currentCategory.name[lang],
                    parents: [...checkNested(currentCategory, 'parents'), ...objeectRegion]
                };
            }
        }
        const titleClassName = classNames('title-wrapper', {
            'p-0': disablePadding
        });
        const parentCategoryId = checkNested(currentCategory, ['parents', 0, 'id']);
        return (
            <div
                id="category-breadcrumb"
                data-test="breadcrumb"
                data-nosnippet="true"
                className={classNames('mb-1 mt-1 mt-md-0', {
                    'sticky-top sticky-sm-bottom z-top-xs box-shadow-xs bg-body': isMobile && ppType
                })}
            >
                <div className="breadcrumb-header">
                    {!customBreadcrumb ? (!!title || currentCategory)
                    && (
                        <div className={titleClassName} data-test="breadcrumb-title">
                            {currentCategory && (
                                <ParentCategoryIcon
                                    currentCategory={currentCategory}
                                    history={history}
                                    i18n={i18n}
                                    amount={amount}
                                    isFetchingAmount={isFetchingAmount}
                                    pp={pp}
                                />
                            )}
                            {!!title
                            && (
                                <div className="breadcrumb-category">
                                    {link ? <Link id="breadcrumb-icon" className="text-decoration-none" aria-label="Home link" to={PP_URLS.HOME_LANG(lang)}>{this.titleIcon(icon)}</Link> : this.drawIcon(icon)}
                                    <ul className="w-100 breadcrumb-category__select">
                                        {!isMobile && (
                                            <li>
                                                <Link to={PP_URLS.HOME_LANG(lang)}>
                                                    {i18n.__('To home')}
                                                </Link>
                                            </li>
                                        )}
                                        {link ? <li><Link id="breadcrumb-title" onClick={titleClick} to={link} className="text-decoration-none">{this.titleText(title)}</Link></li> : this.drawTitle(title)}
                                        {!!(amount || isFetchingAmount)
                                        && (
                                            <li className="amount">
                                                <span
                                                    id="breadcrumb-pp-amount"
                                                    className="title-pp-amount"
                                                    data-test="breadcrumb-count"
                                                >
                                                    (
                                                    {' '}
                                                    {!isFetchingAmount ? amount : AmountLoader}
                                                    {' '}
                                                    )
                                                </span>
                                            </li>
                                        )}
                                        {showLotCount && <BreadcrumbLotCount ppType={ppType} initialFilter={initialFilter} />}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : multipleCategoryString ? (
                        <h3>
                            {i18n.__('Search results')}
                            {' '}
                            {!isFetchingAmount ? amount : AmountLoader}
                        </h3>
                    ) : customBreadcrumb}
                    {(titleCustom || ppType) && (
                        <div className="d-flex text-nowrap ms-auto justify-content-end">
                            <div className="d-flex">
                                {titleCustom}
                                {isMobile && (
                                    <button
                                        className="btn btn-primary ms-1 py-md-0"
                                        onClick={() => {
                                            this.props.setIsToggled(UI.FILTER_BLOCK_MOBILE, true);
                                        }}
                                        key="mobileFilters"
                                    >
                                        <i className="pp-landing pp-filter" aria-hidden="true" />
                                        <span>{i18n.__('Filters')}</span>
                                    </button>
                                )}
                                {ppType && !myItemPage && <ListButtons viewType={viewType} switchView={switchView} />}
                                {hasSortButtons && CATEGORIES_WITH_DISABLED_SORT.indexOf(currentCategory && currentCategory.id) === -1 && <SortButtons history={history} match={match} location={location} i18n={i18n} functions={functions} ppType={ppType} sortedBy={sortedBy} isMobile={isMobile} toggleFilterBlock={toggleFilterBlock} category={currentCategory} isRealEstate={parentCategoryId === CATEGORIES.realEstate} />}
                            </div>
                        </div>
                    )}
                    {/* {(hasTitle || title) && activePackages && <div className={packageClassName}> */}
                    {/*    {activePackages && (ppType === PP_TYPE.my) && <BuyBtn */}
                    {/*        i18n={i18n} */}
                    {/*        ppType={ppType} */}
                    {/*        activePackages={activePackages} */}
                    {/*    />} */}
                    {/* </div>} */}
                    {checkNested(location.state, 'listApiUrl') && (
                        <PrevNext
                            lang={this.props.lang}
                            disableInitialGet
                            ppType={checkNested(locationState, 'ppType', PP_TYPE.list)}
                            locationState={locationState}
                            i18n={i18n}
                            history={history}
                            lotsPath={lotsPath}
                            location={location}
                            match={match}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    filters: getAllFilter(state),
    viewType: getClassifiedViewType(state)
});


export default withRouter(connect(mapStateToProps, { switchView, setIsToggled })(Breadcrumb));
