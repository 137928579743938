import Storage from 'HOC/storage';
import { LANG_LIST, STORAGE } from 'CONFIG';
import { stringExistsIn } from 'MODULES/validations';

export const language = (getState, disableLanugage) => {
    if (disableLanugage || disableLanugage === '') {
        return '';
    }
    const languageCookie = Storage.get(STORAGE.language);
    if (stringExistsIn(languageCookie, LANG_LIST)) {
        return languageCookie;
    }
    let lang;
    if (getState) {
        lang = getState().general.selectedLang;
    } else {
        lang = __DEFAULT_LANG__;
    }

    if (!languageCookie && window.location.href.indexOf(`${window.location.host}/ru`) !== -1) {
        lang = 'ru';
    }
    Storage.set(STORAGE.language, lang);
    return lang;
};
