import { SEARCH_ROUTES, SEARCH_TYPES, searchPattern } from 'CONST';
import { getPps } from 'ACTIONS/categories/getPps';
import { API_URLS } from 'CONFIG/API_URLS';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { apiAction } from 'ACTIONS/api';
import { checkNested } from 'MODULES/checkNested';
import { GET_CATEGORY_FILTERS, SEARCH_QUERY } from 'ACTIONS';
import { prepareDataPayload } from 'MODULES/api';
import keyBy from 'lodash/keyBy';

import { toggleShowAutocomplete } from 'ACTIONS/search/toggleShowAutocomplete';

export const searchByQuery = (query = '', type, loadMore = false, ppsFirstPage = false, activePage, categories, allowCallback, search = '', initialGet = false) => (dispatch) => {
    const searchRoute = SEARCH_ROUTES[type];
    // path, url, type, flush = false, additionalParams = {}, activePage

    if (type === SEARCH_TYPES.lots) {
        return dispatch(getPps(decodeURIComponent(window.location.pathname), `${API_URLS.SEARCH}${searchRoute}`, PP_TYPE.search, ppsFirstPage, {
            query,
            categories,
            activePage
        }, activePage));
    }
    if (query !== '' && query.match(searchPattern) && query.match(searchPattern).join('').trim() !== '') {
        return dispatch(apiAction(
            {
                url: `${API_URLS.SEARCH}${searchRoute}?query=${encodeURIComponent(query.match(searchPattern).join('').trim())}${search.replace('?', '&')}`,
                auth: false,
                onSuccess: (data) => {
                    if (!loadMore && type !== SEARCH_TYPES.lots) {
                        if (type === SEARCH_TYPES.filters) {
                            const filtersResponse = checkNested(data.content, 'filters', {});


                            dispatch({
                                type: GET_CATEGORY_FILTERS,
                                payload: {
                                    filterToUseInList: { search: filtersResponse },
                                    filtersById: keyBy(filtersResponse, 'filter.id')
                                }
                            });


                            dispatch({
                                type: SEARCH_QUERY,
                                searchQuery: query,
                                searchType: type,
                                payload: {
                                    data: {
                                        filters: { ...keyBy(checkNested(data.content, 'filters', {}), 'id') },
                                        priceRange: { ...data.content.priceRange }
                                    }
                                }
                            });
                            (!search || initialGet) && dispatch({
                                type: SEARCH_QUERY,
                                searchQuery: query,
                                searchType: SEARCH_TYPES.categories,
                                payload: { data: data.content.categories }
                            });
                        } else if (SEARCH_TYPES.suggestion === type) {
                            dispatch({
                                type: SEARCH_QUERY,
                                suggestion: true,
                                searchQuery: query,
                                searchType: type,
                                payload: prepareDataPayload(data.content)
                            });
                        } else {
                            dispatch({
                                type: SEARCH_QUERY,
                                searchQuery: query,
                                searchType: type,
                                payload: prepareDataPayload(data.content),
                                filters: checkNested(data.content, 'filters')
                            });
                        }
                        // FYI. this fetch is needed for column.
                        if (allowCallback && type === SEARCH_TYPES.combined) {
                            dispatch(toggleShowAutocomplete(true));
                        }
                    }
                },
                label: `${SEARCH_QUERY}.${type}`
            }
        ));
    }
    dispatch({
        type: SEARCH_QUERY,
        searchType: type,
        searchQuery: query,
        payload: prepareDataPayload()
    });
};
