const productionConfig = {
    API_URL_PUB: 'https://apipub.pp.lv',
    API_URL_INT: 'https://api.pp.lv',
    OAUTH_URL: 'https://oauth.pp.lv',
    OAUTH_ID: '1_66t2lbtaacw8c8c4ksk08ccogs4okgwk0g0w4o0wwwwccc4c0s',
    OAUTH_SECRET: '2nxurp9y6dus8k0wsk00c8cgg4gooskg40g04ckcoo0sow44ss',
    OAUTH_SCOPE: 'api msg',
    MESSAGING_URL_PUB: 'https://msg.pp.lv',
    MESSAGING_URL_INT: 'https://msg.pp.lv',
    FILES_STORAGE_HOST: 'https://img.pp.lv',
    FILES_UPLOAD_HOST: 'https://img.pp.lv',
    GOOGLE_ANALYTICS: 'UA-114573316-2',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCLRN8tTz833yIWlwQd7_dQ3pzfGXE9Ftk',
    SENTRY_KEY: 'https://f4fce2c952884457b3c58702ad594649@sentry.io/290927',
    GTM_KEY: 'GTM-TDMN4Q5',
    FRONTEND_URL: 'https://pp.lv',
    USE_MAP_PP: 1,
    GOOGLE_CLIENT_ID: '851375659489-utkq7sp7ouu9pohnehb4rsf5ra687i17.apps.googleusercontent.com'
};


export default productionConfig;
