import { API_PARAMS } from 'CONFIG';

import { language } from 'MODULES/language';
import { API } from 'CONFIG/API';

export function buildApiUrl(route, queryParams = '', getState, host = API, apiPrefix) {
    if (!route) {
        return host;
    }
    return `${host}/${language(getState, apiPrefix)}${apiPrefix || apiPrefix === '' ? apiPrefix : API_PARAMS}${route}${queryParams}`;
}
