import Cookies from 'js-cookie';
import { DEFAULT_COOKIE_EXPIRE } from 'CONFIG';
import { checkNested } from 'MODULES/checkNested';


class Storage {
    static set(name, param, isLocalStorage, expires = DEFAULT_COOKIE_EXPIRE) {
        try {
            const localStorage = checkNested(window, 'localStorage');
            if (isLocalStorage && localStorage) {
                return localStorage.setItem(name, param);
            }
            return Cookies.set(name, param, { expires, path: '/' });
        } catch (e) {
            return Cookies.set(name, param, { expires, path: '/' });
        }
    }

    static get(name, parse = false, isLocalStorage) {
        try {
            const localStorage = checkNested(window, 'localStorage');
            if (isLocalStorage && localStorage) {
                const item = localStorage.getItem(name);
                return parse ? JSON.parse(item) : item;
            }
        } catch (e) {
            return e;
        }

        if (parse) {
            try {
                return JSON.parse(Cookies.get(name, { path: '/' }));
            } catch (e) {
                return {};
            }
        }
        return Cookies.get(name, { path: '/' });
    }

    static remove(name, isLocalStorage) {
        try {
            const localStorage = checkNested(window, 'localStorage');
            if (isLocalStorage && localStorage) {
                try {
                    return localStorage.removeItem(name);
                } catch (e) {
                    return {};
                }
            }
        } catch (e) {
            return Cookies.remove(name, { path: '/' });
        }
        return Cookies.remove(name, { path: '/' });
    }
}


export default Storage;
