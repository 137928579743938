import { HANDLED_ERRORS, STORAGE, TOKEN_CHECK_DEBOUNCE } from 'CONFIG';
import { TIME_ONE_MINUTE, TOKENS_REGEX } from 'CONST';
import { getUnixTimeStamp } from 'MODULES';
import Storage from 'COMPONENTS/hoc/storage';
import { checkNested } from 'MODULES/checkNested';

export const checkStatus = (response) => {
    if ((response.status >= 200 && response.status < 300) || HANDLED_ERRORS.indexOf(response.status) >= 0) {
        return response;
    }
    const error = new Error(response);
    error.response = response;

    throw error;
};

export const checkAccessTokenAge = (signInByRefreshToken) => {
    setInterval(() => {
        const timeStamp = checkNested(Storage.get('tokensLong', true), ['timeStamp']);
        const expires = checkNested(Storage.get('tokensShort', true), ['expires']);
        if (timeStamp && expires) {
            // FYI. If access token will expire after 5 minutes, get new.
            const ageInSeconds = ((getUnixTimeStamp() - timeStamp) + (5 * TIME_ONE_MINUTE)) / 1000;
            if (ageInSeconds > expires) {
                signInByRefreshToken();
            }
        }
    }, TOKEN_CHECK_DEBOUNCE);
};

export const setStorageTokens = (forShort, forLong, timeStamp, loopCounter) => {
    if (!forShort || !forLong) {
        Storage.remove('login');
        Storage.remove('tokensShort');
        Storage.remove('tokensLong');
        window.accessToken = '';
    } else {
        Storage.set('tokensLong', { ...forLong, timeStamp, loopCounter });
        Storage.set('tokensShort', forShort);
        setTimeout(() => {
            window.accessToken = '';
        }, 1000);
        return { tokensLong: { ...forLong, timeStamp, loopCounter }, tokensShort: forShort };
    }
};

export const setAccessToken = (response) => {
    if (response && !response.error) {
        const common = { type: response.token_type, scope: 'api msg' };
        const forShort = { access: response.access_token, expires: response.expires_in, ...common };
        const forLong = { refresh: response.refresh_token, ...common };

        let timeStamp = checkNested(Storage.get('tokensLong', true), ['timeStamp']);
        let loopCounter = checkNested(Storage.get('tokensLong', true), ['loopCounter']);

        if (!timeStamp || (getUnixTimeStamp() - timeStamp) > 10000) {
            timeStamp = getUnixTimeStamp();
            loopCounter = 1;
        } else {
            loopCounter++;
        }

        Storage.set(STORAGE.login, 1);
        // close onetap window
        if (window.name === 'OAUTH') {
            location.reload();
            window.close();
        }
        return setStorageTokens(forShort, forLong, timeStamp, loopCounter);
    }
    // close onetap window when response is empty or contains error
    if (window.name === 'OAUTH') {
        window.close();
    }
    setStorageTokens();
};

export const checkTokensLenght = (tokens) => {
    const regexTest = new RegExp(TOKENS_REGEX);
    return regexTest.test(checkNested(tokens, 'access_token')) && regexTest.test(checkNested(tokens, 'refresh_token'));
};

export const getAccessToken = () => {
    const accessToken = window.accessToken || checkNested(Storage.get('tokensShort', true), ['access']);
    return accessToken || false;
};


export const getPermissions = headers => checkNested(headers, 'access-control-allow-methods', '').split(', ');

export const checkPermissions = (headers, method) => {
    const allowedMethods = getPermissions(headers);

    if (typeof method === 'string') {
        return allowedMethods.includes(method);
    }

    return false;
};

export const prepareSimplePayload = (data) => {
    if (!data) {
        return null;
    }
    return data;
};

export const prepareDataPayload = (data) => {
    if (!data) {
        return { data: [], count: 0 };
    }
    return data;
};

export const removeLastSlash = slug => (slug && slug.replace(/\/$/, ''));

export const fetchFilterSubscriptionLots = getUserFilters => setInterval(() => {
    getUserFilters();
}, TIME_ONE_MINUTE);

