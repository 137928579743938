import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { PATCHING_MESSAGE } from 'ACTIONS';
import { PARTICIPANT_TYPE } from 'CONST';
import { apiPrefix } from 'ACTIONS/messaging/index';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { getNewMessages } from 'ACTIONS/messaging/getNewMessages';

export const markMessageAsRead = (hash, pp, ownerType, message, thread) => (dispatch) => {
    if (!message) {
        return null;
    }
    dispatch(setIsFetching(PATCHING_MESSAGE, true));
    return (dispatch(apiAction(
        {
            url: API_URLS.MESSAGES_BY_HASH(message),
            method: 'PATCH',
            auth: true,
            host: MESSAGING_API,
            apiPrefix,
            onSuccess: data => () => {
                dispatch({
                    type: PATCHING_MESSAGE,
                    payload: data.content,
                    thread,
                    id: thread.updatedAt,
                    hash,
                    owner: ownerType === PARTICIPANT_TYPE.company ? 'company' : 'personal',
                    status: 'unread'
                });
                dispatch(getNewMessages(i18n));
                dispatch(setIsFetching(PATCHING_MESSAGE, false));
            },
            label: PATCHING_MESSAGE
        }
    )));
};
