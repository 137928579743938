import { checkNested } from 'MODULES/checkNested';
import { FILTER_DISPLAY_TYPE } from 'CONST';
import set from 'lodash/set';

export const defineYearMonth = (data) => {
    if (checkNested(data, ['filter', 'displayType']) === FILTER_DISPLAY_TYPE.date) {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 11 }, (_, i) => String(currentYear + i));
        const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
        const filterDefinedValues = [years, months];

        set(data.filter, 'filterDefinedValues', filterDefinedValues);
    }
};
