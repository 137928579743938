import keyBy from 'lodash/keyBy';
import { filterId, MAX_META_DESCRIPTION_LENGTH, SHORT_META_DESCRIPTION_LENGTH } from 'CONST';
import { checkNested } from 'MODULES/checkNested';
import { isTransportCategory } from 'MODULES/isTransportCategory';
import { isSlugInRealEstateCategory } from 'MODULES/isSlugInRealEstateCategory';

export const ATTRIBUTE = {
    alt: 'alt',
    imgTitle: 'imgTitle',
    metaTitle: 'metaTitle',
    metaDescription: 'metaDescription'
};

const SeoConfig = (pp, attribute, sequence) => {
    const action = checkNested(pp, ['action', 'name'], '');
    const categoryAddress = checkNested(pp.publicLocation, 'address');
    const title = categoryAddress ? `${pp.categoryTitle}, ${categoryAddress}` : pp.categoryTitle;
    const filters = pp.adFilterValues;
    const filtersById = keyBy(filters, 'filter.id');
    // PRICE
    // const shortPriceType = checkNested(pp, ['prices', 'length']) > 0 ? pp.prices[0].priceType.short : '';
    if (isTransportCategory(pp.category.slug)) {
        const carType = checkNested(filtersById, [filterId.carType, 'value', 'displayValue'], '');

        const engineVolume = checkNested(filtersById, [filterId.engineVolume, 'textValue'], '');
        const altEngineVolume = !engineVolume ? '' : engineVolume % 1 === 0 ? `${engineVolume}.0` : `${engineVolume}`;

        const fuel = checkNested(filtersById, [filterId.fuel, 'value', 'displayValue'], '');

        const calendar = checkNested(filtersById, [filterId.calendar, 'value', 'displayValue'], '');

        const transmission = checkNested(filtersById, [filterId.transmission, 'value', 'displayValue'], '');
        const transmissionName = checkNested(filtersById, [filterId.transmission, 'filter', 'name'], '');
        const altTransmission = transmission ? `${transmission} ${transmissionName}` : '';

        const distance = checkNested(filtersById, [filterId.distance, 'textValue'], '');
        const distanceName = checkNested(filtersById, [filterId.distance, 'filter', 'name'], '');
        const altDistance = distance ? `${distanceName} ${distance}` : '';

        if (attribute === ATTRIBUTE.alt) {
            if (sequence) {
                return `${action} - ${title} ${carType} ${altEngineVolume} ${fuel} ${calendar} ${altTransmission} ${altDistance}`;
                //     // TODO: sequence random filter generation
            //     const defect = checkNested(filtersById, [filterId.defect, 'value', 'displayValue'], '');
            //     const color = checkNested(filtersById, [filterId.color, 'value', 'displayValue'], '');
            //     const interior = checkNested(filtersById, [filterId.interior, 'value', 'displayValue'], '');
            //     const safety = checkNested(filtersById, [filterId.safety, 'value', 'displayValue'], '');
            //     // debugger
            //     return `${action} - ${title} ${carType} ${altEngineVolume} ${fuel} ${calendar} ${altTransmission} ${altDistance}`;
            }
            return `${action} - ${title} ${carType} ${altEngineVolume} ${fuel} ${calendar} ${altTransmission} ${altDistance}`;
        }
        if (attribute === ATTRIBUTE.imgTitle) {
            return `${action} - ${title} ${altEngineVolume} ${calendar}`;
        }
        if (attribute === ATTRIBUTE.metaTitle) {
            return `${action} - ${title} ${altEngineVolume} ${fuel} ${calendar}`;
        }
        if (attribute === ATTRIBUTE.metaDescription) {
            return `${action} - ${title} ${carType} ${altEngineVolume} ${fuel} ${calendar} ${altTransmission} ${altDistance}`;
        }
    }
    if (isSlugInRealEstateCategory(pp.category.slug)) {
        const houseSeries = checkNested(filtersById, [filterId.houseSeries, 'value', 'displayValue'], '');
        const houseSeriesName = checkNested(filtersById, [filterId.houseSeries, 'filter', 'name'], '');
        const altHouseSeries = houseSeries ? `, ${houseSeriesName} - ${houseSeries}` : '';
        const houseType = checkNested(filtersById, [filterId.houseType, 'value', 'displayValue'], '');

        const houseTypeName = checkNested(filtersById, [filterId.houseType, 'filter', 'name'], '');
        const altHouseType = houseType && houseTypeName ? `, ${houseTypeName} - ${houseType}` : '';
        const floor = checkNested(filtersById, [filterId.floor, 'textValue'], '');

        const floorsCount = checkNested(filtersById, [filterId.numberOfFloors, 'textValue'], '');
        const floorName = checkNested(filtersById, [filterId.floor, 'filter', 'name'], '');
        const altFloors = floor && floorsCount && floorName ? `, ${floor}/${floorsCount} ${floorName}` : floor && floorName ? `, ${floor} ${floorName}` : '';
        const area = checkNested(filtersById, [filterId.areaM2, 'textValue'], '');

        const areaName = checkNested(filtersById, [filterId.areaM2, 'filter', 'name'], '');
        const altArea = area ? `, ${areaName} - ${area} m²` : '';
        const room = checkNested(filtersById, [filterId.numberOfRooms, 'textValue'], '');

        const roomName = checkNested(filtersById, [filterId.numberOfRooms, 'filter', 'name'], '');
        const altRoom = room ? `, ${roomName} ${room}` : '';
        const land = checkNested(filtersById, [filterId.landArea, 'textValue'], '');

        const landName = checkNested(filtersById, [filterId.landArea, 'filter', 'name'], '');
        const altLand = land ? `, ${landName} - ${land} m²` : '';
        if (attribute === ATTRIBUTE.alt) {
            return `${action} - ${title}${altHouseSeries} ${altHouseType}${altFloors}${altArea}${altRoom}${altLand}`;
        }
        if (attribute === ATTRIBUTE.imgTitle) {
            return `${action} - ${title}`;
        }
        if (attribute === ATTRIBUTE.metaTitle) {
            return `${action} - ${title}`;
        }
        if (attribute === ATTRIBUTE.metaDescription) {
            return `${action} - ${title}${altHouseSeries} ${altHouseType}${altFloors}${altArea}${altRoom}${altLand}`;
        }
    }
    const ppTitle = checkNested(pp, ['title'], '', true);
    const MinMaxPpTitle = ppTitle.length < SHORT_META_DESCRIPTION_LENGTH ? `${pp.categoryTitle} ${ppTitle}` : `${ppTitle.substring(0, MAX_META_DESCRIPTION_LENGTH)}...`;
    if (attribute === ATTRIBUTE.alt) {
        return MinMaxPpTitle;
    }
    if (attribute === ATTRIBUTE.imgTitle) {
        return `${action} - ${title}`;
    }
    if (attribute === ATTRIBUTE.metaTitle) {
        return `${action} - ${title}`;
    }
    if (attribute === ATTRIBUTE.metaDescription) {
        return `${action} ${title} - ${pp.preview}`;
    }
    return MinMaxPpTitle;
};

export default SeoConfig;
