import React from 'react';
import debounce from 'lodash/debounce';
import InputMask from 'react-input-mask';

const DiscountRange = ({ input, i18n }) => {
    const value = input.value || { };
    const changeInput = debounce((field, e) => {
        input.onChange({ ...value, [field]: e.target.value.replaceAll('_', '') });
    }, 700);
    return (
        <div className="pb-3 col-12 filter-section">
            <h6>{i18n.__('Discount')}</h6>
            <div className="w-100 two-columns">
                <div className="form-group fieldset-label custom-actions me-md-1">
                    <label htmlFor="numeric-gradual-select" className="form-control-label">{i18n.__('from')}</label>
                    <InputMask
                        id="discount-from-input"
                        mask="99"
                        value={value.from || ''}
                        onChange={(e) => {
                            changeInput('from', e);
                        }}
                        placeholder="15%"
                        className="form-control"
                    />
                </div>
                <div className="form-group fieldset-label">
                    <label htmlFor="numeric-gradual-select" className="form-control-label">{i18n.__('To')}</label>
                    <InputMask
                        id="discount-to-input"
                        mask="99"
                        value={value.to || ''}
                        onChange={(e) => {
                            changeInput('to', e);
                        }}
                        placeholder="55%"
                        className="form-control"
                    />
                </div>
            </div>
        </div>
    );
};


export default DiscountRange;
