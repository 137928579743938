import Storage from 'HOC/storage';
import { STORAGE } from 'CONFIG';
import { CHECK_USER_COOKIE } from 'ACTIONS';

export const checkUserCookie = () => (dispatch) => {
    const cookieValue = Storage.get(STORAGE.login) === '1';

    dispatch({
        type: CHECK_USER_COOKIE,
        payload: cookieValue
    });
};
