export const mapPPToLocation = (state, data) => {
    const newLoc = state;
    data.map((pp) => {
        const adLoc = pp.flatLocation;
        if (adLoc && adLoc.region && adLoc.coordinateX) {
            const buildingId = `${adLoc.region}_${adLoc.streetName}_${adLoc.buildingNumber}`;
            if (!newLoc[buildingId]) {
                newLoc[buildingId] = {
                    coordinateX: adLoc.coordinateX,
                    coordinateY: adLoc.coordinateY,
                    streetNum: adLoc.buildingNumber,
                    streetName: adLoc.streetName,
                    lotsCount: 0,
                    ppById: [],
                    ppsID: [],
                    id: buildingId
                };
            }
            // TO DO If we have  state ppsList with keys how lots id, we can cancel store double data of lots and take it with pp[pp.id]
            newLoc[buildingId] = {
                ...newLoc[buildingId],
                ppById: { ...newLoc[buildingId].ppById, [pp.id]: pp },
                ppsID: [...newLoc[buildingId].ppsID, pp.id],
                lotsCount: newLoc[buildingId].lotsCount + 1
            };
        }
    });
    return newLoc;
};
