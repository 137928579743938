import _values from 'lodash/values';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import { getFilterValue } from 'MODULES/pp';
import { checkNested } from 'MODULES/checkNested';
import { setIn } from 'MODULES/setIn';

export const required = (value, allValues, props) => (
    value ? undefined : props.i18n.__('Field is required'));


export const email = (value, allValues, props) => {
    if (checkNested(value, 'phone')) {
        return undefined;
    }
    return (
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? props.i18n.__('Invalid email address')
            : undefined);
};


export const requiredFields = (schema) => {
    let req = [];
    if (schema.required) {
        schema.required.forEach((prop) => {
            const schemaField = schema.properties[prop];
            if (schemaField.type === 'object') {
                req = [...req, ...requiredFields(schema.properties[prop])];
            } else {
                req = [...req, prop];
            }
        });
    }
    return req;
};

const checkEmptyValues = (schema, values, props) => {
    const { i18n } = props;
    const errors = {};
    const data = requiredFields(schema, props);
    data.forEach((field) => {
        if (field.includes(field)) {
            if (!values[field] || (typeof values[field] === 'string' && !values[field].trim())) {
                errors[field] = i18n.__('Field is required');
            }
        }
    });
    return errors;
};

const checkRequiredValues = (requiredFields, values, props) => {
    const { i18n } = props;
    const errors = {};
    requiredFields.forEach((field) => {
        if (field.includes(field)) {
            if (!values[field] || (typeof values[field] === 'string' && !values[field].trim())) {
                errors[field] = field !== 'policy' ? i18n.__('Field is required') : i18n.__('You must agree to privacy policy');
            }
        }
    });
    return errors;
};

const checkFieldPattern = (values, patterns, props) => {
    const { i18n } = props;
    let errors = {};
    map(patterns, (pattern, field) => {
        if (values[field]) {
            errors = { ...errors, ...validateByPattern(values, field, pattern, i18n) };
        }
    });
    return errors;
};

const validateRepeatable = (values = {}, field, i18n) => {
    const errors = {};
    const value = values[Object.keys(values)[0]];
    Object.keys(values).forEach((val) => {
        if (value !== '' && values[val] !== '' && values[val] !== value) {
            errors[field] = i18n.__('These values are not equal');
        }
    });
    return errors;
};

const validatePattern = (item, values, key, pattern, i18n, customKey = null) => {
    const errors = {};

    if (values[key] && pattern) {
        const checkablePattern = new RegExp(pattern);
        if (!checkablePattern.test(values[key])) {
            errors[customKey || key] = (checkNested(item, ['attr', 'invalid_message'])) ? i18n.dynamicTranslation(item.attr.invalid_message) : i18n.__('This field contains invalid data!');
        }
    }

    return errors;
};


const validateByPattern = (values, field, pattern, i18n) => {
    const errors = {};

    if (values[field] && pattern) {
        const checkablePattern = new RegExp(pattern);
        if (!checkablePattern.test(values[field])) {
            errors[field] = i18n.__('This field contains invalid data!');
        }
    }

    return errors;
};

const getDataFromSchema = (schema, field) => {
    let patterns = {};
    if (schema.properties) {
        Object.keys(schema.properties).forEach((f) => {
            const p = schema.properties[f];
            if (p[field]) {
                patterns = { ...patterns, [f]: p[field] };
            }
            if (p.properties) {
                patterns = { ...patterns, ...getDataFromSchema(p, field) };
            }
        });
    }
    return patterns;
};

const checkValidationPatterns = (schema, values, props) => {
    let errors = {};
    const pattern = schema ? { ...getDataFromSchema(schema, 'pattern') } : {};
    const widgets = schema ? { ...getDataFromSchema(schema, 'widget') } : {};
    if (Object.keys(values)) {
        Object.keys(values).forEach((v) => {
            if (pattern[v]) {
                errors = { ...errors, ...validatePattern(v, values, v, pattern[v], props.i18n) };
            }
            if (widgets[v] && widgets[v] === 'repeatable') {
                errors = { ...errors, ...validateRepeatable(values[v], v, props.i18n) };
            }
        });
    }
    return errors;
};

const checkFilters = (props, values) => {
    let errors = {};
    const { i18n } = props;
    if (props.validateFilters) {
        const availableFilters = checkNested(props.filters, 'categoryFilters', []);
        _values(availableFilters).forEach((f) => {
            if (f.isRequired && !getFilterValue(values.adFilterValues, f.filter.displayType, f.filter.id)) {
                errors = setIn(`adFilterValues.${f.filter.id}`, i18n.__('Field is required!'), errors);
            }
        });
    }

    return errors;
};

export const validate = (values, props) => {
    const requiredErrors = checkEmptyValues(props.formSchema, values, props);
    const patternErrors = checkValidationPatterns(props.formSchema, values, props);
    const filterErrors = checkFilters(props, values);


    return { ...requiredErrors, ...patternErrors, ...filterErrors };
};

export const validateStaticForm = (values, requiredFields, props, patterns, vr) => {
    const requiredErrors = checkRequiredValues(requiredFields, values, props);
    const patternError = checkFieldPattern(values, patterns, props);
    const repeatableError = vr ? validateRepeatable(values[vr], vr, props.i18n) : {};

    return { ...requiredErrors, ...patternError, ...repeatableError };
};

export const stringExistsIn = (needle, string) => {
    if (isArray(string)) return string.indexOf(needle) !== -1;

    return string && String(string).indexOf(needle) !== -1;
};
