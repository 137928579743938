import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { GET_NOTIFICATIONS } from 'ACTIONS';
import { API_PREFIXES } from 'CONST';

export const getNotifications = () => (dispatch) => {
    dispatch(apiAction(
        {
            url: API_URLS.NOTIFICATIONS,
            auth: true,
            host: MESSAGING_API,
            apiPrefix: API_PREFIXES.msg,
            onSuccess: payload => () => {
                dispatch({
                    type: GET_NOTIFICATIONS,
                    payload: payload.content
                });
            },
            label: GET_NOTIFICATIONS
        }
    ));
};
