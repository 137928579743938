import { UI_MESSAGE_TYPES } from 'CONST';
import { showMsg } from 'ACTIONS/ui/showMsg';
import { NOTIFY_MESSAGE } from 'ACTIONS';

export const showNotifyMsg = (id, content, interval = 10000) => (dispatch, getState) => {
    let message = content;
    // If content is false, remove message
    if (!content && content !== false) {
        message = getState().ui.messages[UI_MESSAGE_TYPES.generalsuccess];
    }
    showMsg(NOTIFY_MESSAGE, dispatch, id, message, interval);
};
