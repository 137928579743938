import { CATEGORIES } from 'MODULES/CATEGORIES';

export const catIcons = {
    [CATEGORIES.cars]: 'pp-zcat_transport_cars',
    [CATEGORIES.moto]: 'pp-zcat_transport_motorcycle',
    [CATEGORIES.heavy]: 'pp-zcat_transport_heavy',
    [CATEGORIES.tyres]: 'pp-zcat_transport_tire',
    [CATEGORIES.rims]: 'pp-zcat_transport_rims',
    [CATEGORIES.water]: 'pp-zcat_transport_water',
    [CATEGORIES.bicycle]: 'pp-zcat_transport_bicycle',
    [CATEGORIES.spareParts]: 'pp-zcat_transport_spares',
    [CATEGORIES.trailer]: 'pp-zcat_transport_trailer',
    [CATEGORIES.air]: 'pp-zcat_transport_air',
    [CATEGORIES.tools]: 'pp-zcat_services_craftsman',
    [CATEGORIES.disabledTransport]: 'pp-zcat_transport_disabled-person',
    [CATEGORIES.transportElectric]: 'pp-zcat_transport_electric',
    [CATEGORIES.transportRental]: 'pp-zcat_transport_rental',
    [CATEGORIES.transportation]: 'pp-zcat_transport_transportation',
    [CATEGORIES.transport]: 'pp-zcat_transport',
    [CATEGORIES.navigation]: 'pp-zcat_transport_audio-navigation-systems',
    [CATEGORIES.accessories]: 'pp-zcat_transport_accessories',
    [CATEGORIES.transportOther]: 'pp-zcat_transport_other',
    [CATEGORIES.fruits]: 'pp-zcat_food_fruits',
    [CATEGORIES.food]: 'pp-zcat_food',
    [CATEGORIES.foodOther]: 'pp-zcat_other',
    [CATEGORIES.foodVeggie]: 'pp-zcat_food_veggie',
    [CATEGORIES.foodDrinks]: 'pp-zcat_food_drinks',
    [CATEGORIES.foodMeat]: 'pp-zcat_food_meat',
    [CATEGORIES.foodSpices]: 'pp-zcat_food_spices',
    [CATEGORIES.foodGrain]: 'pp-zcat_food_grain',
    [CATEGORIES.foodBread]: 'pp-zcat_food_bread',
    [CATEGORIES.foodHoney]: 'pp-zcat_food_honey',
    [CATEGORIES.foodMilk]: 'pp-zcat_food_milk',
    [CATEGORIES.foodFrozen]: 'pp-zcat_food_frozen',
    [CATEGORIES.foodAlcohol]: 'pp-zcat_food_alcohol',
    [CATEGORIES.foodFruits]: 'pp-zcat_food_fruits',
    [CATEGORIES.foodRuralGoods]: 'pp-zcat_food_rural-goods',
    [CATEGORIES.services]: 'pp-zcat_services',
    [CATEGORIES.servicesBeauty]: 'pp-zcat_services_beauty',
    [CATEGORIES.carRepair]: 'pp-zcat_services_car-repair',
    [CATEGORIES.servicesConstruction]: 'pp-zcat_services_construction',
    [CATEGORIES.servicesCraftsman]: 'pp-zcat_services_craftsman',
    [CATEGORIES.servicesDelivery]: 'pp-zcat_services_delivery',
    [CATEGORIES.electronicsRepair]: 'pp-zcat_services_electronics-repair',
    [CATEGORIES.events]: 'pp-zcat_services_events',
    [CATEGORIES.finishingWorks]: 'pp-zcat_services_finishing-works',
    [CATEGORIES.servicesHousehold]: 'pp-zcat_services_household',
    [CATEGORIES.servicesDesign]: 'pp-zcat_services_interior-design',
    [CATEGORIES.servicesElectronicsRepair]: 'pp-zcat_services_electronics-repair',
    [CATEGORIES.servicesIT]: 'pp-zcat_services_internet-it',
    [CATEGORIES.servicesOther]: 'pp-zcat_other',
    [CATEGORIES.servicesProfessional]: 'pp-zcat_services_professional',
    [CATEGORIES.servicesTeachers]: 'pp-zcat_services_teachers',
    [CATEGORIES.servicesVacancy]: 'pp-zcat_services_vacancy',
    [CATEGORIES.servicesVolunteer]: 'pp-zcat_services_volunteer',
    [CATEGORIES.servicesHouseholdWorks]: 'pp-zcat_services_household-chores',
    [CATEGORIES.animals]: 'pp-zcat_pets',
    [CATEGORIES.cats]: 'pp-zcat_pets_cats',
    [CATEGORIES.dogs]: 'pp-zcat_pets_dogs',
    [CATEGORIES.rodents]: 'pp-zcat_pets_rodents',
    [CATEGORIES.exotic]: 'pp-zcat_pets_exotic',
    [CATEGORIES.fish]: 'pp-zcat_pets_fish',
    [CATEGORIES.birds]: 'pp-zcat_pets_birds',
    [CATEGORIES.veterinary]: 'pp-zcat_pets_veterinary',
    [CATEGORIES.petsCage]: 'pp-zcat_pets_cage',
    [CATEGORIES.petsBed]: 'pp-zcat_pets_bed',
    [CATEGORIES.petsFood]: 'pp-zcat_pets_food',
    [CATEGORIES.petsBowl]: 'pp-zcat_pets_bowl',
    [CATEGORIES.petsCollar]: 'pp-zcat_pets_collar',
    [CATEGORIES.petsHygiene]: 'pp-zcat_pets_hygiene',
    [CATEGORIES.petsPlayground]: 'pp-zcat_pets_playground',
    [CATEGORIES.petsTerrarium]: 'pp-zcat_pets_terrarium',
    [CATEGORIES.petsToilet]: 'pp-zcat_pets_toilet',
    [CATEGORIES.petsToys]: 'pp-zcat_pets_toys',
    [CATEGORIES.petsOther]: 'pp-zcat_pets_other',
    [CATEGORIES.electronic]: 'pp-zcat_electronics',
    [CATEGORIES.garden]: 'pp-zcat_gardening',
    [CATEGORIES.gardeningGrass]: 'pp-zcat_gardening_grass',
    [CATEGORIES.gardeningSaw]: 'pp-zcat_gardening_saw',
    [CATEGORIES.gardeningWatering]: 'pp-zcat_gardening_watering',
    [CATEGORIES.gardeningSnowPlow]: 'pp-zcat_gardening_snow-plow',
    [CATEGORIES.gardeningMechanicalBrooms]: 'pp-zcat_gardening_mechanical-brooms',
    [CATEGORIES.gardeningEngines]: 'pp-zcat_gardening_engines',
    [CATEGORIES.gardeningGenerators]: 'pp-zcat_gardening_generators',
    [CATEGORIES.gardeningClothing]: 'pp-zcat_gardening_clothing',
    [CATEGORIES.gardeningAccessories]: 'pp-zcat_gardening_accessories',
    [CATEGORIES.gardeningFurniture]: 'pp-zcat_gardening_furniture',
    [CATEGORIES.gardeningForChildren]: 'pp-zcat_gardening_for-children',
    [CATEGORIES.gardeningGreenhouse]: 'pp-zcat_gardening_greenhouse',
    [CATEGORIES.gardeningOther]: 'pp-zcat_other',
    [CATEGORIES.other]: 'pp-zcat_other',
    [CATEGORIES.realEstate]: 'pp-zcat_real_estate',
    [CATEGORIES.realEstateApartments]: 'pp-zcat_real_estate_apartments',
    [CATEGORIES.realEstateFarmstead]: 'pp-zcat_real_estate_farmstead',
    [CATEGORIES.realEstateForest]: 'pp-zcat_real_estate_forest',
    [CATEGORIES.homes]: 'pp-zcat_real_estate_houses',
    [CATEGORIES.realEstateLands]: 'pp-zcat_real_estate_lands',
    [CATEGORIES.premises]: 'pp-zcat_real_estate_premises',
    [CATEGORIES.realEstateOffices]: 'pp-zcat_real_estate_offices',
    [CATEGORIES.realEstateGarage]: 'pp-zcat_real_estate_garage',
    [CATEGORIES.realEstateSaunas]: 'pp-zcat_real_estate_saunas',
    [CATEGORIES.realEstateModularHomes]: 'pp-zcat_real_estate_modular-homes',
    [CATEGORIES.electronicsPhones]: 'pp-zcat_electronics_mobile-phones',
    [CATEGORIES.electronicsAppliances]: 'pp-zcat_electronics_appliances',
    [CATEGORIES.electronicsComputers]: 'pp-zcat_electronics_computers',
    [CATEGORIES.electronicsAudioVideo]: 'pp-zcat_electronics_audio-video',
    [CATEGORIES.electronicsBatteries]: 'pp-zcat_electronics_batteries',
    [CATEGORIES.electronicsTV]: 'pp-zcat_electronics_tv',
    [CATEGORIES.electronicsCameras]: 'pp-zcat_electronics_cameras',
    [CATEGORIES.electronicsLamps]: 'pp-zcat_electronics_lamps',
    [CATEGORIES.electronicsGPS]: 'pp-zcat_electronics_gps',
    [CATEGORIES.electronicsSecurityCamera]: 'pp-zcat_electronics_security-camera',
    [CATEGORIES.electronicsOther]: 'pp-zcat_other',
    [CATEGORIES.construction]: 'pp-zcat_construction',
    [CATEGORIES.constructionPlumbing]: 'pp-zcat_construction_plumbing',
    [CATEGORIES.constructionMaterials]: 'pp-zcat_construction_materials',
    [CATEGORIES.constructionWorks]: 'pp-zcat_construction_works',
    [CATEGORIES.constructionTools]: 'pp-zcat_construction_tools',
    [CATEGORIES.constructionToolRent]: 'pp-zcat_construction_tool-rent',
    [CATEGORIES.constructionDesign]: 'pp-zcat_construction_design',
    [CATEGORIES.constructionHeating]: 'pp-zcat_construction_heating',
    [CATEGORIES.constructionDelivery]: 'pp-zcat_construction_delivery',
    [CATEGORIES.gardening]: 'pp-zcat_gardening',
    [CATEGORIES.constructionOther]: 'pp-zcat_other',
    [CATEGORIES.homeStuff]: 'pp-zcat_home_stuff',
    [CATEGORIES.homeFurniture]: 'pp-zcat_home_stuff_furniture',
    [CATEGORIES.homeBeauty]: 'pp-zcat_home_stuff_beauty',
    [CATEGORIES.homeJewelry]: 'pp-zcat_home_stuff_jewelry',
    [CATEGORIES.homeSouvenir]: 'pp-zcat_home_stuff_souvenir',
    [CATEGORIES.homeAntique]: 'pp-zcat_home_stuff_antique',
    [CATEGORIES.homePlant]: 'pp-zcat_home_stuff_home-plant',
    [CATEGORIES.homeLaf]: 'pp-zcat_home_stuff_laf',
    [CATEGORIES.homeDetergents]: 'pp-zcat_home_stuff_detergents',
    [CATEGORIES.homeGardening]: 'pp-zcat_gardening',
    [CATEGORIES.homeFeast]: 'pp-zcat_home_stuff_feast-attributes',
    [CATEGORIES.homeAppliances]: 'pp-zcat_electronics_appliances',
    [CATEGORIES.homeFirewood]: 'pp-zcat_production_firewood',
    [CATEGORIES.homeOther]: 'pp-zcat_other',
    [CATEGORIES.clothes]: 'pp-zcat_clothes',
    [CATEGORIES.clothesWomen]: 'pp-zcat_clothes_women',
    [CATEGORIES.clothesMen]: 'pp-zcat_clothes_men',
    [CATEGORIES.clothesKids]: 'pp-zcat_clothes_kids',
    [CATEGORIES.clothesShoes]: 'pp-zcat_clothes_shoes',
    [CATEGORIES.clothesAccessories]: 'pp-zcat_clothes_accessories',
    [CATEGORIES.clothesOveralls]: 'pp-zcat_clothes_overalls',
    [CATEGORIES.clothesSeamstress]: 'pp-zcat_clothes_seamstress_service',
    [CATEGORIES.clothesOther]: 'pp-zcat_other',
    [CATEGORIES.production]: 'pp-zcat_production',
    [CATEGORIES.productionConveyor]: 'pp-zcat_production_conveyor',
    [CATEGORIES.productionManufacturing]: 'pp-zcat_production_manufacturing',
    [CATEGORIES.productionFood]: 'pp-zcat_production_food',
    [CATEGORIES.productionDistribution]: 'pp-zcat_production_distribution',
    [CATEGORIES.productionPackaging]: 'pp-zcat_production_packaging',
    [CATEGORIES.productionHousehold]: 'pp-zcat_production_household',
    [CATEGORIES.productionFirewood]: 'pp-zcat_production_firewood',
    [CATEGORIES.agriculture]: 'pp-zcat_agriculture',
    [CATEGORIES.agricultureHusbandry]: 'pp-zcat_agriculture_husbandry',
    [CATEGORIES.agricultureAviculture]: 'pp-zcat_agriculture_aviculture',
    [CATEGORIES.agricultureFish]: 'pp-zcat_agriculture_fish-industry',
    [CATEGORIES.agricultureWorks]: 'pp-zcat_agriculture_works',
    [CATEGORIES.agricultureFertilizer]: 'pp-zcat_agriculture_fertilizer',
    [CATEGORIES.agricultureSeeds]: 'pp-zcat_agriculture_seeds',
    [CATEGORIES.agricultureMachinery]: 'pp-zcat_agriculture_machinery',
    [CATEGORIES.agricultureMachinery2]: 'pp-zcat_agriculture_machinery',
    [CATEGORIES.agricultureOther]: 'pp-zcat_other',
    [CATEGORIES.agricultureOther]: 'pp-zcat_other',
    [CATEGORIES.kids]: 'pp-zcat_kids',
    [CATEGORIES.kidsBag]: 'pp-zcat_kids_bag',
    [CATEGORIES.kidsSchool]: 'pp-zcat_kids_school',
    [CATEGORIES.kidsClothes]: 'pp-zcat_clothes_kids',
    [CATEGORIES.kidsSwing]: 'pp-zcat_kids_swing',
    [CATEGORIES.kidsPushchair]: 'pp-zcat_kids_pushchair',
    [CATEGORIES.kidsFurniture]: 'pp-zcat_kids_furniture',
    [CATEGORIES.kidsSeats]: 'pp-zcat_kids_seats',
    [CATEGORIES.kidsFood]: 'pp-zcat_kids_food',
    [CATEGORIES.kidsHobby]: 'pp-zcat_kids_hobby',
    [CATEGORIES.kidsOther]: 'pp-zcat_other',
    [CATEGORIES.hobby]: 'pp-zcat_hobby',
    [CATEGORIES.hobbyPastime]: 'pp-zcat_hobby_pastime',
    [CATEGORIES.hobbyCollecting]: 'pp-zcat_hobby_collecting',
    [CATEGORIES.hobbyConcerts]: 'pp-zcat_hobby_concerts',
    [CATEGORIES.hobbyMusic]: 'pp-zcat_hobby_music',
    [CATEGORIES.hobbyBooks]: 'pp-zcat_hobby_books',
    [CATEGORIES.hobbySports]: 'pp-zcat_hobby_sports',
    [CATEGORIES.hobbyHunting]: 'pp-zcat_hobby_hunting',
    [CATEGORIES.hobbyTourism]: 'pp-zcat_hobby_tourism',
    [CATEGORIES.hobbyDating]: 'pp-zcat_hobby_dating',
    [CATEGORIES.hobbyOther]: 'pp-zcat_other',
    [CATEGORIES.charity]: 'pp-zcat_charity',
    [CATEGORIES.health]: 'pp-zcat_health',
    [CATEGORIES.healthAccessories]: 'pp-zcat_health_accessories',
    [CATEGORIES.healthBeauty]: 'pp-zcat_health_beauty',
    [CATEGORIES.healthDancing]: 'pp-zcat_health_dancing',
    [CATEGORIES.healthFitness]: 'pp-zcat_health_fitness',
    [CATEGORIES.healthHairdresser]: 'pp-zcat_health_hairdresser',
    [CATEGORIES.healthHealth]: 'pp-zcat_health_health',
    [CATEGORIES.healthHealthcare]: 'pp-zcat_health_healthcare',
    [CATEGORIES.healthManicure]: 'pp-zcat_health_manicure',
    [CATEGORIES.healthMassage]: 'pp-zcat_health_massage',
    [CATEGORIES.healthStylist]: 'pp-zcat_health_stylist',
    [CATEGORIES.healthSauna]: 'pp-zcat_health_sauna',
    [CATEGORIES.healthDisabledTransport]: 'pp-zcat_transport_disabled-person',
    [CATEGORIES.adultContent]: 'pp-zcat_adult',
    [CATEGORIES.adultContentClothes]: 'pp-zcat_adult_clothes',
    [CATEGORIES.adultContentGames]: 'pp-zcat_adult_games',
    [CATEGORIES.adultContentMasturbator]: 'pp-zcat_adult_masturbator',
    [CATEGORIES.adultContentOffers]: 'pp-zcat_adult_offers',
    [CATEGORIES.adultContentStrapon]: 'pp-zcat_adult_strapon',
    [CATEGORIES.adultContentToys]: 'pp-zcat_adult_toys',
    [CATEGORIES.adultContentVibrator]: 'pp-zcat_adult_vibrator',
    [CATEGORIES.adultContentPharmacy]: 'pp-zcat_adult_pharmacy',
    [CATEGORIES.christmas]: 'pp-zcat_christmas',
    [CATEGORIES.christmasOffers]: 'pp-zcat_christmas_offers'
};
