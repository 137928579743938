import {
    FILTER_DISPLAY_TYPE,
    MAX_META_DESCRIPTION_LENGTH,
    SHORT_META_DESCRIPTION_LENGTH
} from 'CONST';
import { HOST } from 'CONFIG';
import values from 'lodash/values';
import SeoConfig, { ATTRIBUTE } from 'COMPONENTS/seo_config';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import { checkNested } from 'MODULES/checkNested';
import { language } from 'MODULES/language';
import { defaultMeta } from 'CONFIG/defaultMeta';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';

export const mustShowOffer = (props) => {
    if (!props.pp) {
        return false;
    }
    if (props.pp.adState.visibility !== LOT_VISIBILITY.published.id) {
        return false;
    }
    if (!props.pp.offersAllowed) {
        return false;
    }

    if (props.userOffers && props.userOffers[props.pp.id]) {
        if (props.offersNotifications) {
            return props.offersNotifications.status !== false;
        }
        return false;
    }

    if (checkNested(props.pp, 'owner.id') === checkNested(props.userData, 'id', 0)) {
        return false;
    }

    if (checkNested(props.pp, 'company.id') === checkNested(props.userData, 'company.id', 0)) {
        return false;
    }
    return true;
};

export const getPpMainDescription = (pp, lang, defaultTitle = 'defaultTitle') => {
    if (pp.adTexts && Array.isArray(pp.adTexts)) {
        const mainLanguage = pp.adTexts.filter(text => (text.languageCode === lang));
        const additionalLanguages = pp.adTexts.filter(text => (text.languageCode !== lang));
        const languageTitle = (mainLanguage.length)
            ? mainLanguage[0].text
            : (additionalLanguages.length)
                ? additionalLanguages[0].text
                : checkNested(pp, defaultTitle);
        return languageTitle;
    }

    return checkNested(pp, defaultTitle);
};

export const getPpAdditionalDescriptions = (pp, lang) => {
    if (pp.adTexts && Array.isArray(pp.adTexts)) {
        const mainLanguage = pp.adTexts.filter(text => (text.languageCode === lang));
        const additionalLanguages = pp.adTexts.filter(text => (text.languageCode !== lang));

        if ((mainLanguage.length && additionalLanguages.length) || (!mainLanguage.length && additionalLanguages.length > 1)) {
            if (!mainLanguage.length) {
                additionalLanguages.splice(0, 1);
            }

            if (!additionalLanguages.length) {
                return null;
            }

            return additionalLanguages;
        }
    }

    return null;
};

export const getFilterValue = (values, displayType, filterId) => {
    if (displayType !== FILTER_DISPLAY_TYPE.multi && displayType !== FILTER_DISPLAY_TYPE.geo) {
        return checkNested(values, filterId, '', true);
    }
    return displayType === FILTER_DISPLAY_TYPE.geo;
};


export const createHelmetData = (pp) => {
    const lang = language();

    const imageFiles = checkNested(pp, 'files');
    let description = pp ? SeoConfig(pp, ATTRIBUTE.metaDescription) : '';
    if (description !== '' && description.length < SHORT_META_DESCRIPTION_LENGTH) {
        description = `${description.replace(/(\r\n|\n|\r)/gm, '')} - ${defaultMeta['og:title'].defaultValue}`;
    }
    if (description.length > MAX_META_DESCRIPTION_LENGTH) {
        description = `${description.substring(0, MAX_META_DESCRIPTION_LENGTH)}...`;
    }

    const categoryName = checkNested(pp, 'category.name');
    const parentCategoryName = checkNested(pp, 'category.parent.level') === 2 ? checkNested(pp, 'category.parent.name') : checkNested(pp, 'category.parent.parent.name');
    let categoryTitle = checkNested(pp, 'categoryTitle');
    if (parentCategoryName) {
        categoryTitle = `${parentCategoryName} ${categoryName}`;
    }

    let link = null;
    let title = null;
    let imagesArray = [];
    if (checkNested(imageFiles, 'length') > 0) {
        values(imageFiles).forEach((imageFile) => {
            if (checkNested(imageFile, 'file')) {
                imagesArray = [...imagesArray, { url: buildImgUrl(imageFile.file, 'hd'), file: imageFile }];
            }
        });
    }
    let url = null;
    if (categoryTitle && pp) {
        title = SeoConfig(pp, ATTRIBUTE.metaTitle);
        url = `${HOST}/${lang}/${pp.category.slug[lang]}/!${pp.id}`;
        link = {
            slug: pp.category.slug,
            callee: 'lot',
            ppid: pp.id
        };
    }
    const image = imagesArray.length ? { 'og:image': imagesArray } : {};

    return {
        title: categoryName ? title : defaultMeta['og:title'].defaultValue,
        'og:title': categoryName ? title : defaultMeta['og:title'].defaultValue,
        'twitter:title': categoryName ? title : defaultMeta['twitter:title'].defaultValue,
        'og:description': description,
        description,
        'og:url': url,
        ...image,
        'og:type': 'product',
        link
    };
};
