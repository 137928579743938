import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const onScroll = () => {
        setShowButton(document.documentElement.scrollTop > 300);
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', scrollToTop);
        };
    }, []);
    return (
        showButton && (
            <button onClick={scrollToTop} style={{ display: showButton ? 'inline' : 'none' }} className="scroll-to-top">
                <i className="pp-landing pp-chevron-left" />
            </button>
        )
    );
};

export default ScrollToTop;
