import React from 'react';
import PoweredBy from 'COMPONENTS/powered_by';
import { Link } from 'react-router-dom';
import Logo from 'IMAGES/inlineSVG/pp_logo.svg';
import { PP_URLS } from 'CONFIG/PP_URLS';


const QrHeader = () => (
    <div className="qr-header">
        <Link className="title-main" to={PP_URLS.HOME_LANG()}>
            <Logo className="pp-logo-qr" />
        </Link>
        <PoweredBy className="powered-by d-lg-block" />
    </div>
);
export default QrHeader;
