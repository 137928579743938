import React from 'react';


const EmptyPPList = ({ i18n }) => (
    <div key="empty_list" className="pp-block" id="masonry-list">
        <div className="empty-page">
            <i className="pp-ico pp-info icon-lg" />
            <h2>{i18n.__('Looks like there isn\'t any classified that matches your criteria.')}</h2>
            <p>{i18n.__('Try to expand your criteria selection to see more classifieds.')}</p>
        </div>
    </div>
);


export default EmptyPPList;
