const getMotDateValue = (date) => {
    const d = new Date(date);

    if (!d || isNaN(d.getTime())) {
        return date;
    }



    const month = d.getMonth();

    return `${month < 10 ? `0${month}` : month}/${d.getFullYear()}`;
};

export default getMotDateValue;
