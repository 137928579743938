import get from 'lodash/get';
import isNull from 'lodash/isNull';

export const checkNested = (obj, keys, defaultValue = false, checkNull = false, isEmptyString = false) => {
    // defaultValue return get undefined value
    // checkNull if this true return defaultValue if key exists and value is null
    const value = get(obj, keys, defaultValue);
    if (isEmptyString && value === '') {
        return defaultValue;
    }
    if (checkNull && isNull(value)) {
        return defaultValue;
    }
    return value;
};
