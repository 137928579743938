import {
    ADD_BOOKMARK,
    ADD_TO_FAVORITES,
    DECREMENT_PP_FAVORITE,
    DECREMENT_PP_FAVORITE_IN_LIST,
    DELETE_BOOKMARK,
    INCREMENT_PP_FAVORITE,
    INCREMENT_PP_FAVORITE_IN_LIST,
    REMOVE_FROM_FAVORITES,
    TOGGLE_BOOKMARK
} from 'ACTIONS';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import qs from 'qs';
import { getBookmarkedComparedLotCount } from 'ACTIONS/bookmarks/getBookmarkedComparedLotCount';
import { notification } from 'ACTIONS/notifications/notification';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { postUserWatchPromise } from 'ACTIONS/user/postUserWatchPromise';
import { PP_URLS } from 'CONFIG/PP_URLS';
import Storage from 'HOC/storage';

export const toggleBookmark = (pp, removeBookmark = false, onSuccess, lotsPath, activePage, userData, isLoggedIn, history) => (dispatch) => {
    dispatch(setIsFetching([TOGGLE_BOOKMARK, pp.id], true));
    if (!isLoggedIn) {
        history.push(PP_URLS.SIGNIN);
        Storage.set('addToBookmarkAfterLogin', JSON.stringify({
            pp:   pp.id,
            lotsPath,
            activePage
        }), true);
        dispatch(setIsFetching([TOGGLE_BOOKMARK, pp.id], false));
        if (onSuccess) {
            onSuccess();
        }
        return false;
    }

    let params = '';
    return postUserWatchPromise(dispatch).then((identifier) => {
        if (identifier) {
            params = `?${qs.stringify({ identifier })}`;
        }
        return removeBookmark
            ? dispatch(apiAction(
                {
                    url: `${API_URLS.FAVORITES_BY_ID(pp.id)}${params}`,
                    method: 'DELETE',
                    auth: true,
                    onSuccess: data => () => {
                        dispatch({
                            type: DELETE_BOOKMARK,
                            payload: pp
                        });
                        dispatch(notification(data));
                        if (onSuccess) onSuccess();
                        dispatch(setIsFetching([TOGGLE_BOOKMARK, pp.id], false));
                        dispatch(getBookmarkedComparedLotCount(false));
                        dispatch({ type: DECREMENT_PP_FAVORITE, payload: pp });
                        if (userData) dispatch({ type: REMOVE_FROM_FAVORITES, payload: userData, lotId: pp.id });
                        if (activePage) {
                            dispatch({
                                type: DECREMENT_PP_FAVORITE_IN_LIST,
                                payload: pp,
                                lotsPath,
                                activePage
                            });
                        }
                    },
                    label: DELETE_BOOKMARK
                }
            ))
            : dispatch(apiAction(
                {
                    url: `${API_URLS.FAVORITES}${params}`,
                    withCredentials: isLoggedIn,
                    data: { lot: pp.id.toString() },
                    method: 'POST',
                    auth: true,
                    onSuccess: data => () => {
                        dispatch({
                            type: ADD_BOOKMARK,
                            payload: pp
                        });
                        if (onSuccess) {
                            onSuccess(false);
                        }
                        dispatch(setIsFetching([TOGGLE_BOOKMARK, pp.id], false));
                        dispatch(notification(data));
                        dispatch(getBookmarkedComparedLotCount(false));
                        dispatch({ type: INCREMENT_PP_FAVORITE, payload: pp });
                        if (userData) dispatch({ type: ADD_TO_FAVORITES, payload: userData, lotId: pp.id });
                        if (activePage) {
                            dispatch({
                                type: INCREMENT_PP_FAVORITE_IN_LIST,
                                payload: pp,
                                lotsPath,
                                activePage
                            });
                        }
                    },
                    label: ADD_BOOKMARK
                }
            ));
    });
};
