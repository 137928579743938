import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';

const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
);

export const getBoomarkedClasifieds = createDeepEqualSelector(
    [state => state.bookmarks.bookmarkIds],
    bookmarks => map(bookmarks, i => i.id)
);

export const getBoomarkedClasifiedsState = createDeepEqualSelector(
    [state => state.bookmarks.bookmarkIds],
    bookmarkIds => keyBy(bookmarkIds, 'id')
);

export const getBookmarkedCountState = createSelector(
    state => state.bookmarks.bookmarkCount,
    count => count
);
