import React, { useEffect } from 'react';
import { CropperLoader } from 'COMMON/loader';
import { useDispatch } from 'react-redux';
import { categoryClass } from 'MODULES/categoryClass';
import { getCategoryData } from 'ACTIONS/categories/getCategoryData';

const SearchRegion = ({ option, className, onSelect }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (option.isLoadingCategories) {
            dispatch(getCategoryData());
        }
    }, [0]);
    if (option.isLoadingCategories) {
        return CropperLoader();
    }
    return (
        <div
            id={`category-${option.id}`}
            key={option.id}
            className={`${className}`}
            onMouseDown={() => onSelect(option)}
        >
            <div className="d-flex w-100 align-items-center">
                {option.rootCategoryId && <div className={`pp-category ${categoryClass(option)}`} />}
                <div className="search-content">
                    { option.label}
                </div>
            </div>
        </div>
    );
};

export default SearchRegion;
