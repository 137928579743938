import React from 'react';
import classNames from 'classnames';

const drawBasicLoader = (params = false) => (
    <div
        key={`loader${params ? `-${params}` : ''}`}
        className={classNames('preloader-wrapper', { [params]: params })}
    >
        {params
            ? (
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="48" width="48" viewBox="0 0 48 48" className="loader-md">
                    <g transform="rotate(0 24 24)">
                        <circle className="loader-md__svg bg" cx="24" cy="24" r="21" />
                        <circle className="loader-md__svg animate" cx="24" cy="24" r="21" />
                    </g>
                    <g>
                        <path
                            className="cls-1"
                            d="m16.96,26.79h2.95c2.7,0,4.88-2.27,4.72-4.99-.15-2.51-2.28-4.45-4.8-4.45h-5.71c-.62,0-1.12.5-1.12,1.12v12.19h3.16v-10.15h3.7c.89,0,1.64.73,1.61,1.62-.02.84-.7,1.5-1.57,1.5h-2.95v3.16Z"
                        />
                        <path
                            className="cls-1"
                            d="m29.32,26.79h2.95c2.7,0,4.88-2.27,4.72-4.99-.15-2.51-2.28-4.45-4.8-4.45h-5.71c-.62,0-1.12.5-1.12,1.12v12.19h3.16v-10.15h3.7c.89,0,1.64.73,1.61,1.62-.02.84-.7,1.5-1.57,1.5h-2.95v3.16Z"
                        />
                    </g>
                </svg>
            )
            : (
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="96" width="96" viewBox="0 0 96 96" className="loader-lg">
                    <g transform="rotate(0 48 48)">
                        <circle className="loader-lg__svg bg" cx="48" cy="48" r="45" />
                        <circle className="loader-lg__svg animate" cx="48" cy="48" r="45" />
                    </g>
                    <g>
                        <g>
                            <path
                                className="cls-1"
                                d="m36.25,33.78h-13.23c-.83,0-1.5.67-1.5,1.5v30.47h1.39v-30.46c0-.06.05-.12.12-.12h13.26c4.24,0,7.8,3.37,7.85,7.61.05,4.33-3.35,7.74-7.7,7.74h-7.86v1.39h7.86c4.97,0,9.03-3.99,9.09-8.91.07-5.07-4.21-9.22-9.28-9.22Z"
                            />
                            <path
                                className="cls-1"
                                d="m36.23,30.25h-15.23c-1.65,0-2.99,1.34-2.99,2.99v32.5h1.39v-32.48c0-.89.73-1.62,1.62-1.62h15.22c6.27,0,11.52,5.13,11.43,11.39-.09,6.09-5.09,11.01-11.23,11.01h-7.86v1.39h7.86c7.2,0,13.02-6.04,12.6-13.3-.39-6.71-6.09-11.87-12.8-11.87Z"
                            />
                            <path
                                className="cls-1"
                                d="m36.29,37.3h-10.5c-.41,0-.75.34-.75.75v27.69h1.39v-27.05h9.87c2.37,0,4.37,1.94,4.31,4.31-.07,2.25-1.88,4-4.18,4h-7.86v1.39h7.86c3.26,0,5.87-2.78,5.54-6.07-.29-2.87-2.79-5.01-5.68-5.01Z"
                            />
                        </g>
                        <g>
                            <path
                                className="cls-1"
                                d="m69.2,33.78h-13.23c-.83,0-1.5.67-1.5,1.5v30.47h1.39v-30.46c0-.06.05-.12.12-.12h13.26c4.24,0,7.8,3.37,7.85,7.61s-3.35,7.74-7.7,7.74h-7.86v1.39h7.86c4.97,0,9.03-3.99,9.09-8.91.07-5.07-4.21-9.22-9.28-9.22Z"
                            />
                            <path
                                className="cls-1"
                                d="m69.18,30.25h-15.23c-1.65,0-2.99,1.34-2.99,2.99v32.5h1.39v-32.48c0-.89.73-1.62,1.62-1.62h15.22c6.27,0,11.52,5.13,11.43,11.39-.09,6.09-5.09,11.01-11.23,11.01h-7.86v1.39h7.86c7.2,0,13.02-6.04,12.6-13.3-.39-6.71-6.09-11.87-12.8-11.87Z"
                            />
                            <path
                                className="cls-1"
                                d="m69.24,37.3h-10.5c-.41,0-.75.34-.75.75v27.69h1.39v-27.05h9.87c2.37,0,4.37,1.94,4.31,4.31s-1.88,4-4.18,4h-7.86v1.39h7.86c3.26,0,5.87-2.78,5.54-6.07-.29-2.87-2.79-5.01-5.68-5.01Z"
                            />
                        </g>
                    </g>
                </svg>
            )}
    </div>
);

export const Loader = (drawBasicLoader());
export const AmountLoader = (
    <div key="amount-loader" className="preloader-wrapper amount">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="16" width="16" viewBox="0 0 16 16" className="loader-sm">
            <g transform="rotate(0 8 8)">
                <circle className="loader-sm__svg bg" cx="8" cy="8" r="5" />
                <circle className="loader-sm__svg animate" cx="8" cy="8" r="5" />
            </g>
        </svg>
    </div>
);
export const CropperLoader = (height = 'auto') => drawBasicLoader('cropper', height);
