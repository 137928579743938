import Storage from 'HOC/storage';
import { STORAGE } from 'CONFIG';
import { SEARCH_TYPES, searchPattern } from 'CONST';
import { checkNested } from 'MODULES/checkNested';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';

import { toggleShowAutocomplete } from 'ACTIONS/search/toggleShowAutocomplete';
import lowerCase from 'lodash/lowerCase';

export const setSearchSuggestion = (query = '') => (dispatch) => {
    const storageSuggest = Storage.get(STORAGE.suggestion, true, true);
    if (query !== '' && query.match(searchPattern) && query.match(searchPattern).join('').trim() !== '' && !checkNested(storageSuggest, [lowerCase(query)])) {
        Storage.set(STORAGE.suggestion, JSON.stringify({
            ...Storage.get(STORAGE.suggestion, true, true),
            [query]: query
        }), true);
        dispatch(apiAction({
            url: `${API_URLS.SEARCH}/${SEARCH_TYPES.suggestion}?query=${encodeURIComponent(query.match(searchPattern).join('').trim())}`,
            method: 'POST',
            auth: false
        }));
        dispatch(toggleShowAutocomplete(false));
    }
};
