import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import { PP_TYPE } from 'MODULES/PP_TYPE';

export const LOT_SORT_CONFIG = (PP_TYPE) ? {
    [PP_TYPE.list]: [SORT_OPTIONS.scoreDESC, SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.publishDateASC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsDESC],
    [PP_TYPE.user]: [SORT_OPTIONS.scoreDESC, SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.publishDateASC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsDESC],

    [PP_TYPE.my]: [SORT_OPTIONS.createDateDESC, SORT_OPTIONS.createDateASC, SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.publishDateASC, SORT_OPTIONS.endDateDESC, SORT_OPTIONS.endDateASC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsDESC, SORT_OPTIONS.viewsDayDESC, SORT_OPTIONS.viewsWeekDESC, SORT_OPTIONS.viewsMonthDESC],
    [PP_TYPE.myCompany]: [SORT_OPTIONS.createDateDESC, SORT_OPTIONS.createDateASC, SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.publishDateASC, SORT_OPTIONS.endDateDESC, SORT_OPTIONS.endDateASC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsDESC, SORT_OPTIONS.viewsDayDESC, SORT_OPTIONS.viewsWeekDESC, SORT_OPTIONS.viewsMonthDESC],
    [PP_TYPE.publicCompany]: [SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.publishDateASC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsDESC],
    [PP_TYPE.search]: [SORT_OPTIONS.bestMatch, SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC],
    [PP_TYPE.bookmarks]: [SORT_OPTIONS.createDateDESCBookmarks, SORT_OPTIONS.createDateASCBookmarks],
    [PP_TYPE.filters]: [SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsASC, SORT_OPTIONS.viewsDESC],
    [PP_TYPE.filtersCategory]: [SORT_OPTIONS.publishDateDESC, SORT_OPTIONS.priceASC, SORT_OPTIONS.priceDESC, SORT_OPTIONS.viewsASC, SORT_OPTIONS.viewsDESC]
} : {};
