import { checkNested } from 'MODULES/checkNested';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { MODALS } from 'MODULES/MODALS';

export const pathname = () => decodeURIComponent(window.location.pathname).replace('.', '_');

export const hashState = (state, props) => checkNested(props.location, 'hash');
export const searchState = (state, props) => checkNested(props.location, 'search');
// USER DATA
export const userIdDataState = state => checkNested(state.user.userData, 'id');
export const cookieState = state => state.user.signInStatus.cookie;

// UI
export const modalsState = state => state.ui.modals.active_modal;
export const mSelectState = state => state.ui.mSelect;
export const modalsLoginState = state => checkNested(state.ui.modals.active_modal, 'modal') === MODALS.signin && state.ui.modals.active_modal.status;
export const modalsSignUpState = state => checkNested(state.ui.modals.active_modal, 'modal') === MODALS.signup && state.ui.modals.active_modal.status;
export const modalsRecoveryState = state => checkNested(state.ui.modals.active_modal, 'modal') === MODALS.recovery && state.ui.modals.active_modal.status;

// Filters
export const filtersState = state => checkNested(state.filters, [pathname(state)], {});
export const filtersStringState = (state, props) => (props.ppType === PP_TYPE.mapListPp ? 'default' : checkNested(state.filters, [decodeURIComponent(window.location.pathname).replace('.', '_'), 'filterToString'], 'default'));
export const filtersByCategoryState = (state, props) => state.filters.filtersByCategoryId[props.categoryId || props.category];


// Lots

export const countState = state => state.categories.ppsListCount;
