import get from 'lodash/get';
import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import { GET_CATEGORY_DATA, VERIFY_USER_CONTACT } from 'ACTIONS/index';
import { checkNested } from 'MODULES/checkNested';

export const fetchingSelector = path => (
    createSelector(
        state => state.ui.isFetching,
        fetch => get(fetch, path, null)
    )
);

export const isToggledSelectorState = item => (
    createSelector(
        state => state.ui.isToggled,
        toggled => get(toggled, item, null)
    )
);


export const error404SelectorState = error => (
    createSelector(
        state => state.ui.error404,
        allErrors => get(allErrors, error, null)
    )
);

export const notificationState = path => (
    createSelector(
        state => state.ui.notifications,
        notification => get(notification, path, null)
    )
);

export const fetchBoolSelector = createSelector(
    [
        (state, path) => path,
        fetchingSelector
    ],
    (path, fetching) => fetching[path]
);

export const toggledSelector = path => createSelector(
    state => state.ui.isToggled,
    isToggled => get(isToggled, path)
);

export const notificationSelector = createSelector(
    state => state.ui.notification,
    notification => memoize(
        path => get(notification, path)
    )
);

export const getError404 = createSelector(
    state => state.ui.error404,
    error404 => error404
);


export const getIsMobileState = createSelector(
    state => state.ui.isMobile,
    isMobile => isMobile
);

export const getClassifiedViewType = createSelector(
    state => state.ui.classifiedsView,
    viewType => viewType
);

export const getWatchHistoryState = createSelector(
    state => state.ui.watchHistory,
    watchHistory => watchHistory
);


export const getActiveCoordinates = createSelector(
    state => state.ui.activeCoordinates,
    coordinates => coordinates
);

export const getActiveModalState = createSelector(
    state => state.ui.modals.active_modal,
    modal => checkNested(modal, 'modal')
);
export const getActiveModalStatusState = createSelector(
    state => state.ui.modals.active_modal,
    status => checkNested(status, 'status')
);


export const getModalConfirmationDataState = createSelector(
    state => state.ui.modalConfirmation,
    modalConfirmation => modalConfirmation
);


export const getShowModalLoaderState = createSelector(
    state => state.ui.modals,
    modals => checkNested(modals, 'showLoader')
);

export const getVerifiedError = createSelector(
    state => state.ui.errorMessages[VERIFY_USER_CONTACT],
    verifyError => verifyError
);

export const uiModalHocActiveModalSelector = createSelector(
    state => state.ui.modals.active_modal_hoc.modal,
    modal => modal
);

export const uiModalHocStatusSelector = createSelector(
    state => state.ui.modals.active_modal_hoc.status,
    status => status
);

export const uiErrorServerSelector = createSelector(
    state => state.ui.errorServer,
    errorServer => errorServer
);


export const uiNotFoundCategorySelector = createSelector(
    state => state.ui.error404[GET_CATEGORY_DATA],
    notFound => notFound
);

