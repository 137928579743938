import { CATEGORIES } from 'MODULES/CATEGORIES';

export const catColor = {
    [CATEGORIES.transport]: 'cat-transport',
    [CATEGORIES.realEstate]: 'cat-realEstate',
    [CATEGORIES.clothes]: 'cat-clothes',
    [CATEGORIES.services]: 'cat-services',
    [CATEGORIES.health]: 'cat-health',
    [CATEGORIES.animals]: 'cat-animals',
    [CATEGORIES.garden]: 'cat-garden',
    [CATEGORIES.electronic]: 'cat-electronic',
    [CATEGORIES.construction]: 'cat-construction',
    [CATEGORIES.homeStuff]: 'cat-homeStuff',
    [CATEGORIES.food]: 'cat-food',
    [CATEGORIES.agriculture]: 'cat-agriculture',
    [CATEGORIES.production]: 'cat-production',
    [CATEGORIES.kids]: 'cat-kids',
    [CATEGORIES.hobby]: 'cat-hobby',
    [CATEGORIES.charity]: 'cat-charity',
    [CATEGORIES.adultContent]: 'cat-adultContent'
};
