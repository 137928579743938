import {
    ADD_BOOKMARK,
    DELETE_BOOKMARK,
    GET_BOOKMARKED_TOTAL_COUNT,
    CLEAR_BOOKMARKS_STATE,
    RESET_USER_STATE
} from 'ACTIONS';

const INITIAL_STATE = {
    bookmarkCount: 0,
    bookmarkIds: [],
    isFetching: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BOOKMARKED_TOTAL_COUNT:
            return {
                ...state,
                bookmarkCount: action.payload.count,
                bookmarkIds: action.payload.data
            };
        case ADD_BOOKMARK:
            return {
                ...state,
                bookmarkIds: [
                    ...state.bookmarkIds,
                    { id: action.payload.id }
                ],
                bookmarkCount: state.bookmarkCount + 1

            };
        case DELETE_BOOKMARK:
            return {
                ...state,
                bookmarkCount: state.bookmarkCount - 1,
                bookmarkIds: state.bookmarkIds.filter(bookmark => bookmark.id !== action.payload.id)
            };
        case CLEAR_BOOKMARKS_STATE:
            return INITIAL_STATE;
        case RESET_USER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
