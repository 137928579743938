import Storage from 'HOC/storage';
import { hideContentFromUserAgent } from 'MODULES/hideContentFromUserAgent';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { GET_BOOKMARKED_TOTAL_COUNT, GET_COMPARE_COUNT, GET_WATCH_ID } from 'ACTIONS';
import qs from 'qs';
import { prepareDataPayload } from 'MODULES/api';
import { STORAGE } from 'CONFIG';

export const getBookmarkedComparedLotCount = (fetchCompare = true) => (dispatch) => {
    const identifier = Storage.get('watch');
    const isLoggedIn = Storage.get('login') === '1';
    if (hideContentFromUserAgent()) {
        return;
    }
    const promise = new Promise((res) => {
        if (!identifier && !isLoggedIn) {
            return dispatch(apiAction(
                {
                    url: API_URLS.WATCH_HISTORY_IDENTIFIER,
                    method: 'POST',
                    auth: true,
                    withCredentials: isLoggedIn,
                    onSuccess: data => () => {
                        const watchCookieValue = data.content.identifier;
                        res(watchCookieValue);
                    },
                    label: GET_WATCH_ID
                }
            ));
        }
        return res(identifier);
    });
    promise.then((watchId) => {
        let params = '';
        if (watchId) {
            Storage.set('watch', watchId);
            params = `?${qs.stringify({ identifier: watchId })}`;
        }
        if (fetchCompare) {
            dispatch(apiAction(
                {
                    url: `${API_URLS.COMPARE}${params}`,
                    method: 'GET',
                    auth: true,
                    onSuccess: data => (
                        {
                            type: GET_COMPARE_COUNT,
                            payload: prepareDataPayload(data.content)
                        }
                    ),
                    label: GET_COMPARE_COUNT,
                    onFailure: () => {
                        Storage.remove(STORAGE.watch);
                    }
                }
            ));
        }
        dispatch(apiAction(
            {
                url: `${API_URLS.FAVORITES}${params}`,
                method: 'GET',
                auth: true,
                onSuccess: data => (
                    {
                        type: GET_BOOKMARKED_TOTAL_COUNT,
                        payload: prepareDataPayload(data.content)
                    }
                ),
                label: GET_BOOKMARKED_TOTAL_COUNT,
                onFailure: () => {
                    Storage.remove(STORAGE.watch);
                }
            }
        ));
    });
};
