import { isMobile } from 'react-device-detect';
import {
    IS_FETCHING,
    NOTIFY_MESSAGE,
    ERROR_MESSAGE,
    MODAL_WINDOW,
    CLEAR_UI_STATE,
    NOT_FOUND,
    SERVER_ERROR,
    IS_TOGGLED,
    CLEAR_UI_NOTIFICATIONS,
    SET_UI_HISTORY,
    FILTER_CREATE_FORM,
    VERIFY_USER_CONTACT,
    GET_PPS_LIST,
    SET_UI_MESSAGE,
    REMOVE_ERROR_MESSAGE,
    REMOVE_NOTIFICATION_MESSAGE,
    SET_CONFIRMATION_DATA,
    SEARCH_QUERY,
    MODAL_LOADER,
    MODAL_CLOSE_ACTION,
    SET_TREE_DATA,
    RESIZE_WINDOW,
    SWITCH_CLASSIFIEDS_VIEW,
    CLEAR_NOT_FOUND,
    MULTIPLE_SELECT,
    ACTIVATE_SEARCH_INPUT,
    MULTIPLE_SELECT_EMPTY,
    SET_CLASSIFIED_VISITED,
    SET_WATCH_HISTORY,
    INITIAL_UI_STATE,
    TOGGLE_BOOKMARK,
    ACTIVATE_COORDINATES,
    FETCH_INFINITE_LIST,
    CHAIN_CATEGORY_SELECT_LEVELS, CHAIN_CATEGORY_SELECT_CHAIN_DATA, CHAIN_CATEGORY_SELECT_SELECTED, RESET_FILTER_FORM
} from 'ACTIONS';
import omit from 'lodash/omit';
import { STORAGE } from 'CONFIG';
import Storage from 'HOC/storage';
import { setIn } from 'MODULES/setIn';
import { UI } from 'CONST';

const INITIAL_STATE = {
    isFetching: {
        [GET_PPS_LIST]: {},
        [TOGGLE_BOOKMARK]: {},
        [SEARCH_QUERY]: {},
        [FETCH_INFINITE_LIST]: !isMobile
    },
    isToggled: {
        UI_FILTER_BLOCK: true,
        [UI.FILTER_BLOCK_MOBILE]: false,
        [FILTER_CREATE_FORM]: false,
        [`${VERIFY_USER_CONTACT}_1`]: false,
        [`${VERIFY_USER_CONTACT}_2`]: false

    },
    notifications: {},
    errorMessages: {},
    modals: { active_modal: {}, active_modal_hoc: {} },
    error404: {},
    errorServer: null,
    messages: {},
    modalConfirmation: {},
    treeData: { expanded: {}, selected: {} },
    classifiedsView: {},
    isMobile,
    mSelect: {},
    visitedLots: {},
    activeCoordinates: null,
    watchHistory: {},
    chainCategorySelect: {
        chainCategoryLevels: {},
        chainCategoryChainData: {},
        chainCategorySelected: {}
    }
};

export default function (prevState, action) {
    const state = prevState || INITIAL_STATE;
    switch (action.type) {
        case SET_UI_HISTORY:
            return {
                ...state,
                isToggled: action.payload
            };
        case RESET_FILTER_FORM:
            return {
                ...state,
                resettingFilterForm: action.payload
            };
        case IS_FETCHING:
            return {
                ...state,
                isFetching: { ...setIn(action.id, action.isFetching, state.isFetching) }
            };
        case GET_PPS_LIST:
            return {
                ...state,
                isFetching: { ...setIn(action.fetchingPath, false, state.isFetching) }
            };
        case ACTIVATE_COORDINATES:
            return {
                ...state,
                activeCoordinates: action.payload
            };
        case ACTIVATE_SEARCH_INPUT:
            return {
                ...state,
                activateSearch: action.payload
            };
        case IS_TOGGLED:
            return {
                ...state,
                isToggled: { ...setIn(action.id, typeof action.state === 'boolean' ? action.state : !state.isToggled[action.id], state.isToggled) }
            };
        case NOTIFY_MESSAGE:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [action.id]: {
                        status: action.status,
                        content: action.content
                    }
                }
            };
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessages: {
                    ...state.errorMessages,
                    [action.id]: {
                        status: action.status,
                        content: action.content
                    }
                }
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessages: omit(state.errorMessages, [action.id])
            };
        case REMOVE_NOTIFICATION_MESSAGE:
            return {
                ...state,
                notifications: omit(state.notifications, [action.id])
            };
        case NOT_FOUND:
            return { ...state, error404: { ...state.error404, [action.errorType]: action.payload } };
        case CLEAR_NOT_FOUND:
            return { ...state, error404: {} };
        case SERVER_ERROR:
            return { ...state, errorServer: action.payload };
        case SET_UI_MESSAGE:
            return { ...state, messages: { ...state.messages, [action.payload.type]: action.payload.message } };
        case MODAL_WINDOW:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.modalType || 'active_modal']: { modal: action.id, status: action.isOpened, closeClicked: action.closeClicked }
                }
            };
        case MODAL_LOADER:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showLoader: action.isOpened
                }
            };
        case MODAL_CLOSE_ACTION:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    closeFunction: action.actionFunction
                }
            };
        case CLEAR_UI_NOTIFICATIONS:
            return {
                ...state,
                notifications: {},
                errorMessages: {}
            };
        case CLEAR_UI_STATE:
            return INITIAL_STATE;
        case SET_CONFIRMATION_DATA:
            return { ...state, modalConfirmation: action.payload };
        case SET_TREE_DATA:
            return {
                ...state,
                treeData: {
                    ...state.treeData, ...action.payload
                }
            };
        case RESIZE_WINDOW:
            return {
                ...state,
                isMobile: action.payload
            };
        case SWITCH_CLASSIFIEDS_VIEW:
            return { ...state, classifiedsView: action.payload };
        case MULTIPLE_SELECT:
            return {
                ...state,
                mSelect: action.selected ? omit(state.mSelect, action.payload.id) : { ...state.mSelect, [action.payload.id]:  action.payload }
            };
        case MULTIPLE_SELECT_EMPTY:
            return { ...state, mSelect: [] };
        case SET_CLASSIFIED_VISITED:
            Storage.set(STORAGE.visitedLots, JSON.stringify({ ...state.visitedLots, [action.payload]: true }), true);
            return { ...state, visitedLots: { ...state.visitedLots, [action.payload]: true } };
        case SET_WATCH_HISTORY:
            return { ...state, watchHistory: { ...state.watchHistory, [action.payload]: true } };
        case INITIAL_UI_STATE:
            return { ...state, [action.key]: action.payload };
        case CHAIN_CATEGORY_SELECT_LEVELS:
            return { ...state, chainCategorySelect: { ...state.chainCategorySelect, chainCategoryLevels: action.payload } };
        case CHAIN_CATEGORY_SELECT_CHAIN_DATA:
            return { ...state, chainCategorySelect: { ...state.chainCategorySelect, chainCategoryChainData: action.payload } };
        case CHAIN_CATEGORY_SELECT_SELECTED:
            return { ...state, chainCategorySelect: { ...state.chainCategorySelect, chainCategorySelected: action.payload } };
        default:
            return state;
    }
}
