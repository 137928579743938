import { checkNested } from 'MODULES/checkNested';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import size from 'lodash/size';

export const returnThumbUrlFromResponse = (files) => {
    if (size(files) === 0) return null;

    if (checkNested(files, [0, 'thumbnail'])) {
        return buildImgUrl(files[0].thumbnail, 'thumb');
    }
    if (checkNested(files, [0, 'file', 'outputType']) === 'video') {
        return buildImgUrl(files[0].file, 'preview');
    }

    return buildImgUrl(files[0].file, 'thumb');
};
