import { getParent } from 'MODULES/getParent';

import { CATEGORIES } from 'MODULES/CATEGORIES';
import map from 'lodash/map';

export const buildLastCategories = (categories, lang) => {
    if (categories) {
        const parentList = getParent(categories, true);
        return map(parentList, (p, i) => {
            if (i === (parentList.length - 1) || i === (parentList.length - 2)) {
                if (p.id !== CATEGORIES.realEstate && p.id !== 1 && p.id !== 2) {
                    return i === parentList.length ? p.name[lang] : `${p.name[lang]} `;
                }
            }
            return null;
        });
    }
    return null;
};
