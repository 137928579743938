import {
    CLEAR_FILTER_SUBSCRIPTION_STATE, CLEAR_UNSEEN_FILTER_LOTS,
    DELETE_USER_FILTER,
    GET_UNSEEN_FILTER_LOTS,
    MARK_LOT_AS_SEEN,
    PATCH_FILTER_HASH,
    REMOVE_FILTER_COUNT, REMOVE_LOT_UNSEEN_LIST,
    RESET_USER_STATE,
    SAVE_USER_FILTER,
    SAVED_USER_FILTERS,
    SET_FILTER_SUBSCRIPTIONS_HISTORY,
    SET_UNSEEN_FILTERS,
    UPDATE_USER_FILTER
} from 'ACTIONS';
import omitBy from 'lodash/omitBy';
import size from 'lodash/size';
import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = {
    savedUserFilters: null,
    unseenFilters: null,
    patchFilterHash: null,
    unseenFilterLots: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_FILTER_SUBSCRIPTIONS_HISTORY:
            return action.payload;
        case DELETE_USER_FILTER:
            state.savedUserFilters = state.savedUserFilters.filter(filter => filter.hash !== action.payload);
            return { ...state, savedUserFilters: state.savedUserFilters };
        case SAVE_USER_FILTER:
            return { ...state, savedUserFilters: [...state.savedUserFilters, action.payload] };
        case UPDATE_USER_FILTER:
            return {
                ...state,
                savedUserFilters: state.savedUserFilters.map(filter => (filter.id === action.payload.id ? action.payload : filter))
            };
        case PATCH_FILTER_HASH:
            return { ...state, patchFilterHash: action.payload };
        case SET_UNSEEN_FILTERS:
            return { ...state, unseenFilters: action.payload };
        case REMOVE_FILTER_COUNT:
            return { ...state, unseenFilters: state.unseenFilters - action.payload };
        case SAVED_USER_FILTERS:
            return { ...state, savedUserFilters: action.payload.data };
        case GET_UNSEEN_FILTER_LOTS:
            return {
                ...state,
                unseenFilterLots: {
                    ...state.unseenFilterLots,
                    [action.hash]: {
                        lots: action.payload,
                        count: size(action.payload)
                    },
                    lots: {
                        ...state.unseenFilterLots.list, ...checkNested(action.payload, 'data', {})
                    }
                }
            };
        case REMOVE_LOT_UNSEEN_LIST:
            return {
                ...state,
                unseenFilterLots: {
                    ...state.unseenFilterLots,
                    lots: omitBy(state.unseenFilterLots.lots, { id: action.lotId })
                }
            };
        case MARK_LOT_AS_SEEN:
            return {
                ...state,
                unseenFilterLots: {
                    ...state.unseenFilterLots,
                    [action.hash]: {
                        ...state.unseenFilterLots[action.hash].unseenLots,
                        lots: omitBy(state.unseenFilterLots[action.hash].lots, { id: action.lot }),
                        count: state.unseenFilterLots[action.hash].count - 1
                    }
                }
            };
        case CLEAR_UNSEEN_FILTER_LOTS:
            return {
                ...state,
                unseenFilterLots: {
                    ...state.unseenFilterLots,
                    [action.hash]: {
                        lots: {},
                        count: 0
                    }
                }
            };
        case CLEAR_FILTER_SUBSCRIPTION_STATE:
            return INITIAL_STATE;
        case RESET_USER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
