export const MODALS = {
    smallFilter: 'MODAL_SMALL_FILTER',
    filter: 'MODAL_FILTER',
    action: 'MODAL_ACTION',

    pictureZoom: 'MODAL_PICTURE_ZOOM',
    category: 'MODAL_CATEGORY',
    multiSelect: 'MODAL_MULTI_SELECT',
    contacts: 'CONTACTS',
    social: 'MODAL_SOCIAL',
    signup: 'MODAL_SIGN_UP',
    confirmation: 'MODAL_CONFIRMATION',
    modalAds: 'MODAL_ADVERTISEMENT',
    maps: 'MODAL_MAPS',
    recovery: 'MODAL_RECOVERY',
    mapsearch: 'MODAL_MAP_SEARCH',
    reports: 'MODAL_REPORTS',
    shareLot: 'MODAL_SHARE_LOT',
    scammers: 'MODAL_SCAMMERS',
    loader: 'MODAL_LOADER',
    lotStatistic: 'MODAL_LOT_STATISTIC',
    changeRole: 'MODAL_CHANGE_ROLE',
    createCompany: 'MODAL_CREATE_COMPANY',
    bargain: 'MODAL_BARGAIN',
    subscribe: 'MODAL_SUBSCRIBE',
    adultContent: 'MODAL_ADULT_CONTENT',
    review: 'MODAL_REVIEW',
    loan: 'MODAL_LOAN',
    filtersCreate: 'MODAL_FILTERS_CREATE',
    gdpr: 'MODAL_GDPR',
    discount: 'MODAL_DISCOUNT',
    payment: 'MODAL_PAYMENT',
    paymentFailed: 'MODAL_PAYMENT_FAILED',
    paymentSuccess: 'MODAL_PAYMENT_SUCCESS',
    telegramValidation: 'MODAL_TELEGRAM',
    homeCategory: 'MODAL_HOME_CATEGORY',
    disableCategory: 'MODAL_DISABLE_CATEGORY',
    categoryMapping: 'MODAL_CATEGORY_MAPPING',
    newMessage: 'MODAL_NEW_MESSAGE',
    addCategory: 'MODAL_ADD_CATEGORY',
    boostLot: 'MODAL_BOOST_LOT'
};
