import { PP_TYPE } from 'MODULES/PP_TYPE';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { getPps } from 'ACTIONS/categories/getPps';
import { API_URLS } from 'CONFIG/API_URLS';

export const getMoreOfCategoryById = (id, path, activePage, search, area, type = PP_TYPE.map) => (dispatch) => {
    let categoriesArray = null;
    let paramsFromLink = {};
    if (search) {
        paramsFromLink = getParamsFromSearch(search);
        if (paramsFromLink.multipleCategoryString) {
            categoriesArray = paramsFromLink.multipleCategoryString;
        }
    }
    if (id) {
        return dispatch(getPps(path, API_URLS.CATEGORY_LOTS(categoriesArray || id), type, null, {
            ...paramsFromLink,
            area
        }, activePage, null, false, null, null, null, false, path));
    }
    return null;
};
