import qs from 'qs';
import { stringExistsIn } from 'MODULES/validations';
import { UI_HASH } from 'CONFIG';
import { MODALS } from 'MODULES/MODALS';

export const prepareHash = (hash) => {
    const queryHash = qs.parse(hash);
    const showModals = [];
    const returnHash = [];
    let ui = '';
    let params = {};
    let modalFind = false;
    Object.keys(queryHash).forEach((k) => {
        const replacedKey = k.replace('#', ''); // TODO: CLASF-1106 - use '?' instead of '#'
        if (stringExistsIn(replacedKey, Object.keys(MODALS))) {
            showModals.push(replacedKey);
            modalFind = true;
        } else if (stringExistsIn(replacedKey, UI_HASH) && !queryHash[k]) {
            ui = replacedKey;
        } else if (modalFind) {
            params = { ...params, [k]: queryHash[k] };
        } else {
            returnHash.push(k);
        }
    });
    return {
        hash,
        showModals,
        params,
        ui
    };
};
