import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { checkPermissions, prepareSimplePayload } from 'MODULES/api';
import { USER_COMPANY_PERMISSIONS } from 'CONST';
import { GET_USER_COMPANY, GET_USER_COMPANY_PERMISSIONS } from 'ACTIONS';

export const getUserCompany = () => apiAction(
    {
        url: API_URLS.COMPANY,
        method: 'GET',
        auth: true,
        onSuccess: (data, headers) => (dispatch) => {
            const permissions = checkPermissions(headers, 'PUT')
                ? USER_COMPANY_PERMISSIONS.edit
                : USER_COMPANY_PERMISSIONS.watch;
            dispatch({
                type: GET_USER_COMPANY_PERMISSIONS,
                companyPermissions: permissions
            });
            dispatch({
                type: GET_USER_COMPANY,
                payload: prepareSimplePayload(data.content)
            });
        },
        label: GET_USER_COMPANY
    }
);
