import React from 'react';
import { Link } from 'react-router-dom';

import { CATEGORIES } from 'MODULES/CATEGORIES';

const LotLink = ({ isMap, href, to, onClick, children, target, frontUrl, category }) => {
    if (isMap || (frontUrl && category.id === CATEGORIES.whereToBuy)) {
        return (
            <a
                href={isMap ? href : frontUrl}
                target="_blank"
            >
                {children}
            </a>
        );
    }
    if (onClick) {
        return (
            <a
                href={href}
                onClick={(e) => {
                    if (e.defaultPrevented) {
                        return false;
                    }
                    e.preventDefault();
                    return onClick();
                }}
            >
                {children}
            </a>
        );
    }
    return (
        <Link
            to={to}
            tabIndex={0}
            target={target}
        >
            {children}
        </Link>
    );
};

export default LotLink;
