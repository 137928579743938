// TODO: why doesn't get called?
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MY_WALLET_DATA } from 'ACTIONS';
import { checkNested } from 'MODULES/checkNested';

export const getMyWallet = resolve => dispatch => dispatch(apiAction({
    url: API_URLS.WALLET_DATA,
    method: 'GET',
    auth: true,
    onSuccess: (data) => {
        dispatch({
            type: MY_WALLET_DATA,
            payload: checkNested(data, 'content')
        });
        if (resolve) {
            resolve(checkNested(data, 'content'));
        }
    },
    onFailure: () => {

    },
    label: MY_WALLET_DATA
}));
