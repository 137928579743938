import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import qs from 'qs';
import isArray from 'lodash/isArray';
import { DELETE_PP, GET_FILTER_LOT_COUNT, GET_MY_COMPANY_LOTS_AMOUNT, GET_MY_LOTS_COUNT } from 'ACTIONS';
import { PP_TYPE } from 'MODULES/PP_TYPE';

import { clearCreateData } from 'ACTIONS/create_classified/clearCreateData';
import { notification } from 'ACTIONS/notifications/notification';
import { addMSelectItem } from 'ACTIONS/ui/addMSelectItem';
import { getUserPackages } from 'ACTIONS/user/getUserPackages';
import { getUserLotsCategories } from 'ACTIONS/user/getUserLotsCategories';
import size from 'lodash/size';

export const deletePp = (id, ppType, onSuccess = null, path) => (dispatch, getState) => dispatch(apiAction(
    {
        url: `${API_URLS.LOTS}${qs.stringify({ lots: isArray(id) ? id : [id] }, {
            addQueryPrefix: true,
            arrayFormat: 'brackets'
        })}`,
        method: 'DELETE',
        auth: true,
        onSuccess: (data) => {
            const store = getState();
            let startCount = store.company.companyLotsAmount;
            let type = GET_MY_COMPANY_LOTS_AMOUNT;
            if (ppType === PP_TYPE.my || ppType === PP_TYPE.bookmarks) {
                startCount = store.user.userLots;
                type = GET_MY_LOTS_COUNT;
            }
            if (!isArray(id)) {
                dispatch({
                    type,
                    payload: startCount - 1
                });
                dispatch(
                    {
                        type: GET_FILTER_LOT_COUNT,
                        payload: startCount - 1

                    }
                );
            }

            if (isArray(id)) {
                dispatch({
                    type,
                    payload: startCount - size(id)
                });
            }
            if (onSuccess) {
                onSuccess();
            }
            if (path) {
                data.content.forEach((lot) => {
                    dispatch({
                        type: DELETE_PP,
                        payload: lot.id,
                        lotsPath: path
                    });
                    dispatch(addMSelectItem(lot.id, true));
                });
            } else {
                data.content.forEach((lot) => {
                    dispatch(addMSelectItem(lot.id, true));
                });
            }
            if (ppType === PP_TYPE.my || ppType === PP_TYPE.myCompany) {
                dispatch(getUserLotsCategories());
            }
            dispatch(notification(data));
            dispatch(getUserPackages());
            dispatch(clearCreateData());
        },
        label: DELETE_PP
    }
));
