import {
    GET_FORM_CONFIG,
    GET_FORM_CHANGE
} from 'ACTIONS';

import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = {
    formConfig: {},
    formChanged: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_FORM_CONFIG:
            return {
                ...state,
                formConfig: { ...state.formConfig, [action.form]: action.payload.content.properties }
            };
        case GET_FORM_CHANGE: {
            return {
                ...state,
                formChanged: checkNested(action, 'status', true)
            };
        }
        default:
            return state;
    }
}
