import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { ADD_LOT_COMPARE, DELETE_LOT_COMPARE, GET_COMPARE_COUNT } from 'ACTIONS';
import Storage from 'HOC/storage';
import { postUserWatchPromise } from 'ACTIONS/user/postUserWatchPromise';
import qs from 'qs';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { getBookmarkedComparedLotCount } from 'ACTIONS/bookmarks/getBookmarkedComparedLotCount';
import { notify } from 'ACTIONS/notifications/notify';
import { NOTIFICATION_OK, NOTIFICATION_POSITION } from 'CONFIG';

export const toggleCompare = (pp, remove = false, i18n, askCount = true) => (dispatch) => {
    dispatch(setIsFetching([GET_COMPARE_COUNT, pp.id], true));
    const isLoggedIn = Storage.get('login') === '1';
    let params = '';
    return postUserWatchPromise(dispatch).then((identifier) => {
        if (identifier && !isLoggedIn) {
            params = `?${qs.stringify({ identifier })}`;
        }
        return remove
            ? dispatch(apiAction(
                {
                    url: `${API_URLS.COMPARE_LOT_BY_ID(pp.id)}${params}`,
                    method: 'DELETE',
                    auth: true,
                    onSuccess: () => () => {
                        dispatch({
                            type: DELETE_LOT_COMPARE,
                            payload: pp
                        });
                        dispatch(notify({
                            message: i18n.__('Lot removed from comparison'),
                            status: NOTIFICATION_OK,
                            position: NOTIFICATION_POSITION
                        }));
                        dispatch(setIsFetching([GET_COMPARE_COUNT, pp.id], false));
                        if (askCount) dispatch(getBookmarkedComparedLotCount());
                    },
                    label: DELETE_LOT_COMPARE
                }
            ))
            : dispatch(apiAction(
                {
                    url: `${API_URLS.COMPARE_LOT_BY_ID(pp.id)}${params}`,
                    withCredentials: isLoggedIn,
                    data: { lot: pp.id.toString() },
                    method: 'POST',
                    auth: true,
                    onSuccess: () => () => {
                        dispatch({
                            type: ADD_LOT_COMPARE,
                            payload: pp
                        });
                        dispatch(setIsFetching([GET_COMPARE_COUNT, pp.id], false));
                        dispatch(notify({
                            message: i18n.__('Lot added from comparison'),
                            status: NOTIFICATION_OK,
                            position: NOTIFICATION_POSITION
                        }));
                        dispatch(getBookmarkedComparedLotCount());
                    },
                    label: ADD_LOT_COMPARE
                }
            ));
    });
};
