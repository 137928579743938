import _values from 'lodash/values';

export const createNotificationmessage = (message, values) => {
    if (!values || _values(values)[0] === 'null') {
        return message;
    }
    if (typeof values === 'object' && message.search('%s') === -1) {
        return message.concat('\n', Object.values(values).join('. '), '.');
    }
    return message.replace('%s', values.join());
};
