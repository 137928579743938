import React, { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import omit from 'lodash/omit';
import { connect } from 'react-redux';
import qs from 'qs';
import { pathname } from 'MODULES/reselect/state';
import { checkNested } from 'MODULES/checkNested';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { LOT_DEFAULT_SORT } from 'CONFIG/LOT_DEFAULT_SORT';
import { SORT_OPTIONS } from 'MODULES/SORT_OPTIONS';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { getBookmarkedLots } from 'ACTIONS/bookmarks/getBookmarkedLots';
import { getMoreOfCategoryById } from 'ACTIONS/categories/getMoreOfCategoryById';
import { getRecentlyAddedClassifieds } from 'ACTIONS/categories/getRecentlyAddedClassifieds';
import { getCompanyLots } from 'ACTIONS/company/getCompanyLots';
import { sortBy } from 'ACTIONS/filters/sortBy';
import { getSubscriptionLots } from 'ACTIONS/filterSubscriptions/getSubscriptionLots';
import { getSubscriptionCategoryLots } from 'ACTIONS/filterSubscriptions/getSubscriptionCategoryLots';
import { getExpiredLots } from 'ACTIONS/payments/getExpiredLots';
import { getDigestLots } from 'ACTIONS/user/getDigestLots';
import { getUserLots } from 'ACTIONS/user/getUserLots';
import { getLotsByOwner } from 'ACTIONS/user/getLotsByOwner';

const getMoreOfData = (DecoratedComponent) => {
    class GetMoreOfData extends PureComponent {
        componentDidMount() {
            const { props } = this;
            const sortSearchParameter = checkNested(getParamsFromSearch(checkNested(props.location, 'search')), 'sort');
            if (!props.disableInitialGet) {
                if (props.flushFilters && props.history.action === 'PUSH' && !checkNested(props.location.state, 'isBackBtn') && !checkNested(props.location.state, 'currentCategory')) {
                    props.flushFilters();
                }
                if (props.ppType === PP_TYPE.map || props.ppType === PP_TYPE.similar || props.ppType === PP_TYPE.list || props.ppType === PP_TYPE.publicCompany || props.ppType === PP_TYPE.user) {
                    props.sortBy(SORT_OPTIONS[sortSearchParameter] ? SORT_OPTIONS[sortSearchParameter].name : LOT_DEFAULT_SORT[props.ppType], omit({ ...getParamsFromSearch(checkNested(props.location, 'search')) }), props.filterValues, true, props.ppType, '');
                } else if (props.ppType === PP_TYPE.map || props.ppType === PP_TYPE.filters || props.ppType === PP_TYPE.filtersCategory) {
                    props.sortBy(SORT_OPTIONS.publishDateDESC.name, props.filterValues);
                } else if (props.ppType === PP_TYPE.bookmarks || props.ppType === PP_TYPE.bookmarksMy) {
                    props.sortBy(SORT_OPTIONS.createDateDESCBookmarks.name, props.filterValues);
                } else if (props.ppType === PP_TYPE.recently_added) {
                    props.sortBy(SORT_OPTIONS.publishDateDESC.name, props.filterValues);
                } else {
                    props.sortBy(SORT_OPTIONS[sortSearchParameter] ? SORT_OPTIONS[sortSearchParameter].name : LOT_DEFAULT_SORT[props.ppType], props.filterValues, true, props.ppType);
                }
                if (props.ppType === PP_TYPE.bookmarks && checkNested(props.location, 'state.category')) {
                    props.filterOut(props.ppType, { category: checkNested(props.location, 'state.category') });
                }
                let params = { page: 1 };
                if (checkNested(props.location, 'search')) {
                    params = qs.parse(props.location.search.slice(1));
                }
                this.getMoreOfData(params.page || 1);
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.lang !== prevProps.lang) {
                this.initialFilers(this.props);
                if (this.props.ppType === PP_TYPE.publicCompany) {
                    this.getMoreOfData(1);
                }
            }
            if (this.props.location !== prevProps.location && !checkNested(this.props.location, 'search', '').indexOf('sort') === -1) {
                this.initialFilers(this.props);
            }
            if (this.props.category !== prevProps.category) {
                this.getMoreOfData(1);
            }

            if (this.props.category === prevProps.category && qs.parse(checkNested(this.props.location, 'search')).region !== qs.parse(checkNested(prevProps.location, 'search')).region) {
                this.getMoreOfData(1);
            }

            if (this.props.filterValues !== prevProps.filterValues && this.props.location !== prevProps.location) {
                this.getMoreOfData(1);
            }
        }


        getMoreOfData = (activePage, dispatcher = decodeURIComponent(window.location.pathname), lotsPath = false, categoryId = false) => {
            let func = null;
            const { area, user, regionSelected, planId, ppType, currentCategory, id, category, location, similarItems, params } = this.props;
            switch (ppType) {
                case PP_TYPE.list:
                    func = this.props.getMoreOfCategoryById(checkNested(currentCategory, 'id', categoryId), lotsPath, activePage, checkNested(location, 'search', `?page=${activePage}`), area, PP_TYPE.list);
                    break;
                case PP_TYPE.map:
                    func = this.props.getMoreOfCategoryById(checkNested(currentCategory, 'id'), dispatcher, activePage, location.search, area, PP_TYPE.map);
                    break;
                case PP_TYPE.similar:
                    if (isEmpty(similarItems)) {
                        func = this.props.getSimilarLots(id, category);
                    }
                    break;
                case PP_TYPE.user:
                    func = this.props.getLotsByOwner(user, activePage, this.props.location.search);
                    break;
                case PP_TYPE.my:
                    func = this.props.getUserLots(false, activePage, lotsPath);
                    break;
                case PP_TYPE.digest:
                    func = this.props.getDigestLots(dispatcher);
                    break;
                case PP_TYPE.myCompany:
                case PP_TYPE.publicCompany:
                    func = this.props.getCompanyLots(id, false, activePage, this.props.category);
                    break;
                case PP_TYPE.bookmarks:
                    this.props.getBookmarkedLots(false, activePage);
                    break;
                case PP_TYPE.recently_added:
                    this.props.getRecentlyAddedClassifieds(dispatcher, category, regionSelected);
                    break;
                case PP_TYPE.filtersCategory:
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    func = this.props.getSubscriptionCategoryLots(category, true, activePage, params);
                    break;
                case PP_TYPE.filters:
                    func = this.props.getSubscriptionLots(false, activePage);
                    break;
                case PP_TYPE.expired:
                    func = this.props.getExpiredLots(planId, window.location.pathname, activePage);
                    break;
                default:
                    break;
            }
            return func;
        };

        initialFilers = (props) => {
            if (props.ppType === PP_TYPE.map || props.ppType === PP_TYPE.list || props.ppType === PP_TYPE.publicCompany) {
                props.sortBy(SORT_OPTIONS.scoreDESC.name, props.filterValues);
            } else if (props.ppType === PP_TYPE.filters || props.ppType === PP_TYPE.filtersCategory) {
                props.sortBy(SORT_OPTIONS.publishDateDESC.name, props.filterValues);
            } else if (props.ppType === PP_TYPE.bookmarks || props.ppType === PP_TYPE.bookmarksMy) {
                props.sortBy(SORT_OPTIONS.createDateDESCBookmarks.name, props.filterValues);
            } else {
                props.sortBy(SORT_OPTIONS.createDateDESC.name, props.filterValues);
            }
            if (props.ppType === PP_TYPE.bookmarks && checkNested(props.location, 'state.category')) {
                props.filterOut(props.ppType, { category: checkNested(props.location, 'state.category') });
            }
        };


        render() {
            const { filterInit } = this.props;
            if (filterInit === 0) {
                return null;
            }
            return <DecoratedComponent getMoreOfData={this.getMoreOfData} {...this.props} />;
        }
    }

    return connect((state, props) => ({
        filterValues: state.filters.filterValues,
        filterToString: state.filters.filterToString,
        filterInit: checkNested(state.filters, [pathname(state)], 0),
        similarItems: values(state.categories.similarItems[props.id])
    }), {
        getMoreOfCategoryById,
        getRecentlyAddedClassifieds,
        getUserLots,
        getLotsByOwner,
        getCompanyLots,
        getBookmarkedLots,
        getSubscriptionLots,
        getExpiredLots,
        getSubscriptionCategoryLots,
        sortBy,
        getDigestLots
    })(GetMoreOfData);
};

export default getMoreOfData;

