import React from 'react';

import { PP_URLS } from 'CONFIG/PP_URLS';
import { TIK_TOK_CARS, TIK_TOK_FLATS } from 'CONST';

const FollowOnSocial = ({ i18n }) => (
    <div className="d-flex social-follow">
        <a rel="noopener" href={PP_URLS.FACEBOOK} target="_blank" className="social-link" aria-label={i18n.__('Follow us on Facebook')}>
            <i className="pp-landing pp-social-facebook" />
        </a>
        <a rel="noopener" href={PP_URLS.INSTAGRAM} target="_blank" className="social-link" aria-label={i18n.__('Follow us on Instagram')}>
            <i className="pp-landing pp-social-instagram" />
        </a>
        <a rel="noopener" href={TIK_TOK_CARS} target="_blank" className="social-link pe-4" aria-label={i18n.__('Follow us on Tiktok')}>
            <i className="pp-landing pp-social-tiktok-auto" />
        </a>
        <a rel="noopener" href={TIK_TOK_FLATS} target="_blank" className="social-link pe-4" aria-label={i18n.__('Follow us on Tiktok')}>
            <i className="pp-landing pp-social-tiktok-flats" />
        </a>
    </div>
);
export default FollowOnSocial;
