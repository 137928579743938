import { checkNested } from 'MODULES/checkNested';
import forEach from 'lodash/forEach';
import shuffle from 'lodash/shuffle';
import groupBy from 'lodash/groupBy';

export const shuffleFilesIfFirstImageDuplicates = (data) => {
    const grouped = groupBy(data, pp => checkNested(pp.files, ['0', 'file', 'id']));
    forEach(grouped, group => {
        if (group.length > 1) {
            forEach(group, pp => pp.files = shuffle(pp.files));
        }
    });

    return data;
};
