import { SWITCH_CLASSIFIEDS_VIEW } from 'ACTIONS';
import Storage from 'HOC/storage';
import { STORAGE } from 'CONFIG';
import { USER_SETTINGS } from 'CONST';
import { patchUserPreference } from 'ACTIONS/user/patchUserPreference';

export const switchView = view => (dispatch) => {
    dispatch({
        type: SWITCH_CLASSIFIEDS_VIEW,
        payload: view
    });
    const mappedView = view === 0 ? 'grid' : 'list';
    const isLoggedIn = Storage.get(STORAGE.login) === '1';
    if (isLoggedIn) {
        dispatch(patchUserPreference({ prefKind: USER_SETTINGS.lots_view_type, value: mappedView }));
    }
};
