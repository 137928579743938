import { PP_TYPE } from 'MODULES/PP_TYPE';
import { GET_PPS_LIST } from 'ACTIONS';
import { prepareDataPayload } from 'MODULES/api';

export const getPpsList = (content = null, listType = PP_TYPE.list, filterType = 'default', location, page, categoryId, activePath) => ({
    type: GET_PPS_LIST,
    payload: prepareDataPayload(content),
    page,
    location: window.location.pathname,
    listType,
    filterType,
    categoryId,
    activePath
});
