import { getAddressTextFromGeo } from 'MODULES/getAddressTextFromGeo';
import { CATEGORIES } from 'MODULES/CATEGORIES';

export const setPpLocState = (activeLocation, searchValue, isModal) => ({
    searchValue,
    coordinateX: activeLocation.coordinateX,
    coordinateY: activeLocation.coordinateY,
    selectOptions: isModal ? [{ item: activeLocation, name: getAddressTextFromGeo(activeLocation), id: searchValue }] : null
});


export const showSecondLevelRegion = (category) => {
    if (category === CATEGORIES.barCafe) {
        return true;
    }

    if (category === CATEGORIES.hotels) {
        return true;
    }

    if (category === CATEGORIES.restaurants) {
        return true;
    }

    if (category === CATEGORIES.fishingPlaces) {
        return true;
    }

    if (category === CATEGORIES.bath) {
        return true;
    }

    if (category === CATEGORIES.camps) {
        return true;
    }

    if (category === CATEGORIES.hotelsHostels) {
        return true;
    }
    if (category === CATEGORIES.guestHouses) {
        return true;
    }
    if (category === CATEGORIES.countrysideTourism) {
        return true;
    }
    if (category === CATEGORIES.realEstateSaunas) {
        return true;
    }
    if (category === CATEGORIES.realEstateGarage) {
        return true;
    }


    return false;
};


export const showFullCategoryPicke = (category) => {
    if (category === CATEGORIES.flats) {
        return true;
    }
    if (category === CATEGORIES.homes) {
        return true;
    }
    if (category === CATEGORIES.realEstateLands) {
        return true;
    }
    if (category === CATEGORIES.realEstateFarmstead) {
        return true;
    }
    if (category === CATEGORIES.realEstate) {
        return true;
    }
    if (category === CATEGORIES.realEstateForest) {
        return true;
    }
    if (category === CATEGORIES.realEstateOffices) {
        return true;
    }
    if (category === CATEGORIES.premises) {
        return true;
    }
    if (category === CATEGORIES.realEstateSaunas) {
        return true;
    }
    if (category === CATEGORIES.realEstateGarage) {
        return true;
    }

    return false;
};


export const drawRealEstateBoxLabel = (value) => {
    const { customCoordinateX, customCoordinateY, region } = value;
    const activeLocation = value;
    let searchValue = '';
    if (!region && (customCoordinateX || customCoordinateY)) {
        return `${customCoordinateX}, ${customCoordinateY}`;
    }
    if (activeLocation.region) {
        searchValue = getAddressTextFromGeo(value);
    } else if (activeLocation.customCoordinateX && activeLocation.customCoordinateY) {
        searchValue = `${activeLocation.customCoordinateX}, ${activeLocation.customCoordinateY}`;
    }
    return searchValue;
};
