import {
    SAVE_ROUTE_TO_CACHE
} from 'ACTIONS';

const INITIAL_STATE = {
    success: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SAVE_ROUTE_TO_CACHE:
            return { ...state, success: { ...state.success, [action.payload.route]: action.payload } };
        default:
            return state;
    }
}
