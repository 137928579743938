import React from 'react';
import classNames from 'classnames';

import { gtm } from 'MODULES/gtm';

const ButtonShare = ({ id, socialName, ico, shareUrl, ppUrl, title, titleDraugiem, image, titlePrefix, dropdown, gtmEventName, publish, email, body }) => (
    (
        <button
            onClick={() => {
                email ? window.location.href = `mailto:?subject=${title}&body=${title} - ${body || ''} ${ppUrl}` : window.open(shareUrl + (titleDraugiem ? `&title=${titleDraugiem}&link=` : '') + ppUrl + (title ? `&text=${title}` : '') + (image ? `&media=${image}` : '') + (titlePrefix ? `&titlePrefix=${titlePrefix}` : ''), '', 'location=1,status=1,scrollbars=0,resizable=0,width=530,height=400');
                gtm(gtmEventName);
            }}
            className={classNames('d-flex align-items-center share-type mb-1', {
                'btn btn-sm': dropdown
            })}
            id={id}
            aria-label={socialName}
        >
            <i
                className={classNames('social-ico me-2', {
                    [ico]: ico
                })}
            />
            <span
                className={classNames({
                    'd-none d-md-inline': publish
                })}
            >
                {socialName}
            </span>
        </button>
    ));

export default ButtonShare;
