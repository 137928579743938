import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesGetPpByIdSelector } from 'MODULES/reselect/selectors/categories';
import { useHistory, useLocation } from 'react-router';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { AmountLoader } from 'COMMON/loader';
import first from 'lodash/first';
import last from 'lodash/last';
import { pickFilterValues } from 'MODULES/filters';
import { ppListQueryStringSelector } from 'MODULES/reselect/selectors/pp_list';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { getPpList } from 'ACTIONS/pp_list/getPpList';

const PrevNextBtn = ({ i18n, ppId, prev, disabled, navigateLot }) => {
    const [showLoader, setShowLoader] = useState();
    const lang = useSelector(getSelectedLanguage);
    const location = useLocation();
    const dispatch = useDispatch();
    const ppFromState = useSelector(state => categoriesGetPpByIdSelector(state, ppId));
    const pp = ppFromState || navigateLot;
    const history = useHistory();
    const { pathname, ppType, listApiUrl, search, queryParam, regionId } = location.state;
    const searchParamsOld = pickFilterValues(getParamsFromSearch(search));
    const searchParams = useSelector(state => ppListQueryStringSelector(state, ppType, pathname, search, queryParam, regionId, Number(searchParamsOld.currentPage || 1) + (prev ? -1 : 1)));

    const onFinishListGet = (list) => {
        const newPp = prev ? last(list) : first(list);
        setShowLoader(false);
        history.push({
            pathname: PP_URLS.PP(newPp.id, newPp.category.slug[lang]),
            state: { ...location.state, search: `?${searchParams}`, pp: newPp }
        });
    };
    const openLot = () => {
        if (!ppId) {
            setShowLoader(true);
            dispatch(getPpList(listApiUrl, searchParams, pathname, ppType, onFinishListGet));
            return false;
        }
        history.push({
            pathname: PP_URLS.PP(pp.id, pp.category.slug[lang]),
            state: { ...location.state, pp }
        });
    };

    return (
        <button
            disabled={disabled || showLoader}
            className="btn btn-action btn-back ms-1"
            id={prev ? 'button-prev' : 'button-next'}
            onClick={openLot}
            aria-label={prev ? i18n.__('Previous') : i18n.__('Next')}
        >
            {prev
                && (
                    <i className="pp-landing pp-angle-left pe-0" />
                )}
            <span className="d-none d-md-inline">
                {prev ? i18n.__('Previous') : i18n.__('Next')}
                {showLoader && AmountLoader}
            </span>
            {!prev
                && (
                    <i className="pp-landing pp-angle-right pe-0" />
                )}
        </button>
    );
};

export default PrevNextBtn;
