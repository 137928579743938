import React, { memo } from 'react';
import PoweredBy from 'COMPONENTS/powered_by';
import { Link } from 'react-router-dom';

import { PP_URLS } from 'CONFIG/PP_URLS';
import FollowOnSocial from 'COMPONENTS/follow_on_social';

const MobileFooter = ({ year, i18n }) => (
    <div className="d-print-none container-fluid footer-wrapper" data-nosnippet="true">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                <div className="d-md-flex w-100 w-md-50 w-lg-50 w-xl-50 order-md-1">
                    <div className="d-flex w-100">
                        <div>
                            <div>
                                <div className="bottom-buffer-menu">
                                    <Link to={PP_URLS.USER_DATA_POLICY()} className="d-flex">
                                        <i className="pp-landing pp-hdd-o" />
                                        <div className="pt-1">{i18n.__('User data policy')}</div>
                                    </Link>
                                </div>
                                <div className="bottom-buffer-menu">
                                    <Link to={PP_URLS.RULES()} className="d-flex">
                                        <i className="pp-landing pp-file-text-o" />
                                        <div className="pt-1">{i18n.__('Terms And Conditions')}</div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className="bottom-buffer-menu">
                                    <Link to={PP_URLS.INFO()} className="d-flex">
                                        <i className="pp-landing pp-question-circle-o" />
                                        <div className="pt-1">{i18n.__('Frequently Asked Questions')}</div>
                                    </Link>
                                </div>
                                <div className="bottom-buffer-menu">
                                    <Link to={PP_URLS.CONTACTS()} className="d-flex">
                                        <i className="pp-landing pp-envelope-o" />
                                        <div className="pt-1">{i18n.__('Contact Administrators')}</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <FollowOnSocial i18n={i18n} key={1} header />
                    </div>
                </div>
                <div className="text-center pt-2">
                    <PoweredBy />
                    <div className="mt-1 text-nowrap fs-075">
                        ©
                        {year}
                        {' '}
                        {i18n.__('LLC Inbokss')}
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default memo(MobileFooter);
