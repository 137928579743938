import React from 'react';
import size from 'lodash/size';
import map from 'lodash/map';
import FormattedPrice from 'COMPONENTS/classified/price_formatter';
import SearchRegion from 'COMPONENTS/header/search_region';
import { SEARCH_TYPES } from 'CONST';
import { useSelector } from 'react-redux';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import { categoryClass } from 'MODULES/categoryClass';
import { checkNested } from 'MODULES/checkNested';
import { buildLastCategories } from 'MODULES/buildLastCategories';

const Option = ({ option, onSelect }) => {
    const lang = useSelector(getSelectedLanguage);
    const categories = buildLastCategories(option.category, lang);

    if (option.type === 'region') {
        return <SearchRegion className="selected-category Select-option" option={option} onSelect={onSelect} />;
    }
    return (
        (option.preview || option.preview === '')
            ? (
                <div
                    id={`ad-${option.id}`}
                    onMouseDown={() => onSelect(option)}
                    key={`ad-${option.id}`}
                    className="selected-category Select-option classified"
                >
                    <div className="d-flex align-items-center w-100">
                        <div className="img-wrapper">
                            {checkNested(option, 'thumbnail') ? (
                                <img
                                    src={buildImgUrl(option.thumbnail, 'thumb')}
                                />
                            ) : <div className="image-placeholder" />}
                        </div>
                        <div>
                            <span>
                                <b>{categories.map((c) => c || '')}</b>
                            </span>
                            <span>{option.preview}</span>
                        </div>
                        <span className="price">
                            {size(option.prices) > 0 && (
                                <FormattedPrice
                                    prices={option.prices}
                                    showFirst
                                />
                            )}
                            {size(option.prices) === 0 && option.action && option.action.name}
                        </span>
                    </div>
                </div>
            )
            : (
                <div
                    id={`category-${option.id || 'suggest'}`}
                    key={`category-${option.id}`}
                    className="selected-category Select-option"
                    role="button"
                    tabIndex="0"
                    onMouseDown={() => onSelect(option)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            onSelect(option);
                        }
                    }}
                >
                    <div className="d-flex w-100 align-items-center">
                        {option.rootCategoryId && <div className={categoryClass(option, 0)} />}
                        <nav className="search-content" aria-label="breadcrumb">
                            {checkNested(option, 'type') === SEARCH_TYPES.suggestion && <span>{option.name}</span>}
                            {map(option.path, (category, i) => ((i !== 0 || option.path.length === 1)
                            && (
                                <span key={category.id} aria-label={category.name} role="button" tabIndex="0">
                                    {category.name}
                                    {option.path.length !== (i + 1) && <span className="pipe" />}
                                </span>
                            )
                            ))}
                        </nav>
                        <div className="count">{option.adCount}</div>
                    </div>
                </div>
            )
    );
};

export default Option;
