import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { PATCH_USER_PREFERENCE } from 'ACTIONS';
import { prepareDataPayload } from 'MODULES/api';
import { notification } from 'ACTIONS/notifications/notification';

export const patchUserPreference = (preference, resolve) => dispatch => dispatch(apiAction(
    {
        url: API_URLS.PREFERENCE(preference.prefKind),
        method: 'PATCH',
        auth: true,
        data: { value: preference.value },
        onSuccess: (data) => {
            dispatch({
                type: PATCH_USER_PREFERENCE,
                payload: prepareDataPayload(data.content)
            });
            if (resolve) resolve();
            dispatch(notification(data));
        },
        label: PATCH_USER_PREFERENCE
    }
));
